<template>
  <!-- 운임 >> 나의 운임 -->
  <div>
    <form id="frm2">
      <win-layer-pop class="my_surcharge_pop">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="surChargeParams"
        />
      </win-layer-pop>
      <div class="content_box beforetab">
        <table class="tbl_search">
          <colgroup>
            <col :width="[serviceCtrCd === 'KR' ? '60px' : '80px']">
            <col width="150px">
            <col width="324px">
            <col width="50px">
            <col width="150px">
            <col width="150px">
            <col width="80px">
            <col width="120px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.FARE020T010.001') }}</th><!-- 출발 -->
              <td colspan="2">
                <e-auto-complete-place
                  id="polPol"
                  @change="changePol"
                  :ctr-cd="params.porCtrCd"
                  :plc-cd="params.porPlcCd"
                  :is-char="true"
                  :is-char-alert="true"
                />
              </td>
              <th>{{ $t('msg.FARE020T010.002') }}</th><!-- 도착 -->
              <td colspan="4">
                <e-auto-complete-place
                  id="podPod"
                  @change="changePod"
                  :ctr-cd="params.dlyCtrCd"
                  :plc-cd="params.dlyPlcCd"
                  :is-char="true"
                  :is-char-alert="true"
                />
              </td>
              <td class="text_right">
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.SPEC030.043') }}</th><!-- 조회 -->
              <td>
                <select
                  v-model="params.kind"
                  class="wid150"
                  @change="changeSearchGb"
                >
                  <option value="E">{{ $t('msg.FARE020T010.004') }}</option><!-- Expiry Date -->
                  <option value="R">{{ $t('msg.FARE020T010.005') }}</option><!-- Request Date -->
                  <option value="S">{{ $t('msg.FARE020T010.006') }}</option><!-- Shipment Date -->
                  <option value="N">{{ $t('msg.FARE020T010.031') }}</option><!-- S/C No. -->
                </select>
              </td>
              <td colspan="4" v-if="params.kind !== 'N'">
                <e-date-range-picker
                  id="date_picker"
                  :sdate="params.fromDt"
                  :edate="params.toDt"
                  :is-debug="true"
                  @change="changeDateRange"
                />
              </td>
              <td colspan="4" v-if="params.kind === 'N'" style="padding-left: 20px; padding-right: 500px;">
                <input type="text" v-model="params.scNo">
              </td>
              <th>{{ $t('msg.FARE020T010.035') }}</th><!-- 프로모션 -->
              <td>
                <select
                  v-model="params.hotDealYn"
                  class="wid100"
                >
                  <option value="A">{{ $t('ALL') }}</option><!-- ALL -->
                  <option value="Y">{{ $t('Y') }}</option><!-- Y -->
                  <option value="N">{{ $t('N') }}</option><!-- N -->
                </select>
              </td>
              <td class="text_right">
                <a
                  class="button blue sh"
                  href="#"
                  @click.prevent="searchMyOceanFreight(true)"
                >{{ $t('msg.FARE020T010.013') }}</a><!-- 검색 -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content_box mt10">
        <div class="flex_box">
          <span class="tot_num">{{ $t('msg.FARE020T010.014') }} : <span class="num">{{ myOceanFreightList.length }}</span></span>
          <span class="ml_auto cal_btn"><a class="button sm" href="#" @click.prevent="exlDownload()">{{ $t('msg.MYIN020T010.016') }}</a></span> <!-- 엑셀 다운로드 -->
        </div>
        <div id="realgrid" :style="`width: 100%; height: ${gridHeight}px;`" />
      </div>
    </form>
  </div>
</template>

<script>

import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import schedule from '@/api/rest/schedule/schedule'

import { rootComputed } from '@/store/helpers'

import { GridView, LocalDataProvider } from 'realgrid'
import JSZip from 'jszip'
import moment from 'moment'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'frtAppNo', dataType: 'text' }, // R/A No.
  { fieldName: 'porPlcCd', dataType: 'text' }, // POL
  { fieldName: 'dlyPlcCd', dataType: 'text' }, // POD
  { fieldName: 'rteCd', dataType: 'text' }, // 항로
  { fieldName: 'vslVoy', dataType: 'text' }, // 선명/항차
  { fieldName: 'strDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' }, // Effective Date
  { fieldName: 'endDt' }, // Expiry Date
  { fieldName: 'frtPncNm', dataType: 'text' }, // Payment Term
  { fieldName: 'actShprCstEnm', dataType: 'text' }, // B/L shipper
  { fieldName: 'cntrTypCd', dataType: 'text' }, // Container Type
  { fieldName: 'cgoTypNm', dataType: 'text' }, // Cargo Type
  { fieldName: 'frtExtsStsCd', dataType: 'text' },
  { fieldName: 'frtEstnYn', dataType: 'text' },
  { fieldName: 'cstBidgMgrNo', dataType: 'text' },
  { fieldName: 'porPlcNm', dataType: 'text' },
  { fieldName: 'porCtrCd', dataType: 'text' },
  { fieldName: 'dlyPlcNm', dataType: 'text' },
  { fieldName: 'dlyCtrCd', dataType: 'text' },
  { fieldName: 'hotDealYn', dataType: 'text' },
  { fieldName: 'frtKnd', dataType: 'text' } // KMTC ON 운임, 할인 운임, 일반 운임 구분
]

export const columns = [
  {
    name: 'frtAppNo',
    fieldName: 'frtAppNo',
    type: 'text',
    width: '7',
    header: {
        text: 'S/C No.'
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        return `<span><a style="text-decoration:underline;cursor:pointer;">${cell.value}</a></span>`
      }
    }
  },
  {
    name: 'porPlcCd',
    fieldName: 'porPlcCd',
    type: 'text',
    width: '9',
    header: {
        text: 'POL'
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        return `<p>${provider.getValue(cell.index.dataRow, 'porPlcNm')}</p><p>${provider.getValue(cell.index.dataRow, 'porCtrCd')}</p>`
      }
    }
  },
  {
    name: 'dlyPlcCd',
    fieldName: 'dlyPlcCd',
    type: 'text',
    width: '9',
    header: {
        text: 'POD'
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        return `<p>${provider.getValue(cell.index.dataRow, 'dlyPlcNm')}</p><p>${provider.getValue(cell.index.dataRow, 'dlyCtrCd')}</p>`
      }
    }
  },
  {
    name: 'rteCd',
    fieldName: 'rteCd',
    type: 'text',
    width: '5',
    header: {
        text: '항로'
    }
  },
  {
    name: 'vslVoy',
    fieldName: 'vslVoy',
    type: 'text',
    width: '15',
    header: {
        text: '선명/항차'
    }
  },
  {
    name: 'strDt',
    fieldName: 'strDt',
    type: 'text',
    width: '8',
    header: {
        text: 'Effective Date'
    },
    datetimeFormat: 'yyyy-MM-dd'
  },
  {
    name: 'endDt',
    fieldName: 'endDt',
    type: 'text',
    width: '8',
    header: {
        text: 'Expiry Date'
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        let str = ''
        let expiryFlag = false
        // Expiry Date 값
        const value = cell.value

        const expDate = app.$ekmtcCommon.getStrToDate(value)
        const today = new Date()
        // const twoWeekAgo = new Date().setDate(today.getDate() - 14)
        const twoWeekAfter = new Date().setDate(today.getDate() + 14)
        // const strDt = provider.getValue(cell.index.dataRow, 'strDt')
        const frtEstnYn = provider.getValue(cell.index.dataRow, 'frtEstnYn')
        const frtExtsStsCd = provider.getValue(cell.index.dataRow, 'frtExtsStsCd')
        const cstBidgMgrNo = provider.getValue(cell.index.dataRow, 'cstBidgMgrNo')

        // Expiry Date 값 check (운임 유효기간 만료된 건은 날짜 빨간색 표기)
        if (expDate < today) {
          expiryFlag = true
        }

        str += '<span' + (expiryFlag ? ' style="color:red"' : '') + '>'
        str += app.$ekmtcCommon.changeDatePattern(value, '-')
        str += '</span>'
        // 1) 운임 유효기한 만료 임박(2주전~금일) => 조건이 잘못됨.
        //    => (금일~2주후 사이)
        // 2) 유효기한 만료된 건은
        // ==> ‘연장요청’ 버튼 노출
        if (frtEstnYn !== 'I') {
          if (frtExtsStsCd === '01') {
            if (frtEstnYn === 'T') {
              str += '<p>' + app.$t('msg.FARE020T010.033') + '</p>' // 연장요청 불가
            } else {
              str += '<p>' + app.$t('msg.FARE020T010.032') + '</p>' // 연장요청 완료
            }
          } else if (frtExtsStsCd === '03') {
            str += '<p>' + app.$t('tit.CSBL250.006') + '</p>' // 연장완료
          } else if (frtExtsStsCd === '04') {
            str += '<p>' + app.$t('tit.CSBL250.007') + '</p>' // 연장거절
          } else if ((today <= expDate && expDate <= twoWeekAfter) && frtExtsStsCd === '00' && app.$ekmtcCommon.isEmpty(cstBidgMgrNo)) {
            str += '<p><a class="button sm spc5">' + app.$t('tit.CSBL250.004') + '</a></p>' // 연장요청
          } else if (today > expDate && frtExtsStsCd === '00' && app.$ekmtcCommon.isEmpty(cstBidgMgrNo)) {
            str += '<p><a class="button sm spc5">' + app.$t('tit.CSBL250.004') + '</a></p>' // 연장요청
          }
        }

        if (app.$ekmtcCommon.isNotEmpty(cstBidgMgrNo)) {
          str += '<p>' + app.$t('msg.FARE020T010.033') + '</p>' // 연장요청 불가
        }

        return str
      }
    }
  },
  {
    name: 'frtPncNm',
    fieldName: 'frtPncNm',
    type: 'text',
    width: '7',
    header: {
        text: 'Payment Term'
    }
  },
  {
    name: 'actShprCstEnm',
    fieldName: 'actShprCstEnm',
    type: 'text',
    width: '22',
    header: {
        text: 'B/L shipper'
    }
  },
  {
    name: 'cntrTypCd',
    fieldName: 'cntrTypCd',
    type: 'text',
    width: '8',
    header: {
        text: 'Container Type'
    }
  },
  {
    name: 'cgoTypNm',
    fieldName: 'cgoTypNm',
    type: 'text',
    width: '6',
    header: {
        text: 'Cargo Type'
    }
  },
  {
    name: 'hotDealYn',
    fieldName: 'hotDealYn',
    type: 'text',
    width: '6',
    header: {
      text: '프로모션'
    }
  },
  {
    name: 'frtExtsStsCd',
    fieldName: 'frtExtsStsCd',
    type: 'text',
    width: '8',
    visible: false,
    header: {
        text: 'frtExtsStsCd'
    }
  },
  {
    name: 'cstBidgMgrNo',
    fieldName: 'cstBidgMgrNo',
    type: 'text',
    width: '8',
    visible: false,
    header: {
        text: 'cstBidgMgrNo'
    }
  },
  {
    name: 'frtKnd',
    fieldName: 'frtKnd',
    type: 'text',
    width: '10',
    visible: true,
    header: {
      text: '운임구분'
    }
  }
]

export default {
  name: 'ShippingCostMy',
  components: {
    EAutoCompletePlace,
    'e-date-range-picker': () => import('@/components/common/EDateRangePicker'),
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'fre-surcharge-pop': () => import('@/pages/schedule/popup/FreSurchargePop'),
    'request-quote-pop': () => import('@/pages/schedule/popup/RequestQuotePop')
  },
  data () {
    return {
      gridHeight: 300,
      customComponent: null,
      params: {
        porCtrCd: '',
        porPlcCd: '',
        dlyCtrCd: '',
        dlyPlcCd: '',
        kind: 'E',
        fromDt: '',
        toDt: '',
        hotDealYn: 'A',
        scNo: ''
      },
      isSearch: false,
      format: 'YYYY.MM.DD',
      myOceanFreightList: [],
      // 운임 팝업을 띄우기 위해 필요한 데이터
      surChargeParams: {},
      dtChk: true,
      serviceCtrCd: '',
      kmtcSpotUserYn: 'N',
      relYn: true // JamSin
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.init()
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  mounted () {
    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip
    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    provider.setFields(fields)
    gridView.setColumns(columns)

    this.setMessageProperty()
    gridView.displayOptions.fitStyle = 'evenFill'

    // [s] 그리드 크기 자동조절을 위한 설정
    gridView.header.height = 50
    gridView.displayOptions.rowHeight = 60
    // [e] 그리드 크기 자동조절을 위한 설정

    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    //인디케이터 없애기
    gridView.setRowIndicator({
      visible: false
    })
    //상태바 없애기
    gridView.setStateBar({
      visible: false
    })
    //체크바 없애기
    gridView.setCheckBar({
      visible: false
    })
    gridView.setFooter({
      visible: false
    })
    //JamSin 속도 문제로 막음
    //this.searchMyOceanFreight()

    //Grid 메소드
    this.gridEvents()

    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })

    // JamSin 날짜 초기값 셋팅 -1w ~ +1w
    const arrMonthInfo = this.$ekmtcCommon.getDefaultStEnDt({ startCode: 'W', startGap: 1, endCode: 'W', endGap: 1 })
    this.params.fromDt = arrMonthInfo.stDt
    this.params.toDt = arrMonthInfo.enDt
  },
  methods: {
    init () {
      this.gridHeight = $('body').height() - 400
      if (this.gridHeight < 300) {
        this.gridHeight = 300
      }

      this.serviceCtrCd = this.$ekmtcCommon.isNotEmpty(localStorage.getItem('service_ctrcd')) ? localStorage.getItem('service_ctrcd') : localStorage.getItem('service_lang')

      // KOR -> KR
      if (this.serviceCtrCd === 'KOR') {
        this.serviceCtrCd = 'KR'
      }

      // 로그인한 경우 KMTC SPOT 대상 조회
      if (this.auth.login) {
        this.getKmtcSpotTarget()
      }
    },
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter') {
          this.searchMyOceanFreight(true)
        }
      }
    },
    changePol (vo) {
      this.params.porCtrCd = vo.ctrCd
      this.params.porPlcCd = vo.plcCd
      const frm = document.querySelector('#frm2')
      const elem = frm.querySelector('#polPol')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod (vo) {
      this.params.dlyCtrCd = vo.ctrCd
      this.params.dlyPlcCd = vo.plcCd
      const frm = document.querySelector('#frm2')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changeSearchGb () {
      this.params.scNo = ''
    },
    async searchMyOceanFreight (searchBtnClick) {
      if (this.fnValidationAll()) {
        // '검색'버튼을 누른 경우
        if (searchBtnClick !== undefined && searchBtnClick) {
          this.isSearch = true
        }

        const formData = JSON.parse(JSON.stringify(this.params))

        if (this.$ekmtcCommon.isNotEmpty(formData.fromDt)) {
          formData.fromDt = formData.fromDt.replaceAll('.', '')
        }
        if (this.$ekmtcCommon.isNotEmpty(formData.toDt)) {
          formData.toDt = formData.toDt.replaceAll('.', '')
        }

        if (!this.isSearch || (this.$ekmtcCommon.isEmpty(formData.fromDt) && this.$ekmtcCommon.isEmpty(formData.toDt))) {
          formData.kind = 'D'
        }

        this.myOceanFreightList = []

        await schedule.myOceanFreight(formData).then((response) => {
          if (response.status === 200) {
            const respOceanFreightList = response.data.myOceanFreightList

            if (respOceanFreightList === undefined || respOceanFreightList.length === 0) {
              this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
            }

            if (respOceanFreightList.length > 0) {
              let imsiFrtAppNo = null
              let imsiFrtJson = {}

              // 연속 데이터 가공 loop
              respOceanFreightList.forEach((respOceanFreight, index) => {
                if (imsiFrtAppNo === respOceanFreight.frtAppNo) {
                  // 이전 FrtAppNo와 현재 FrtAppNo가 같을 때는 cntrTypCd 값과 cgoTypNm 값만
                  // 배열에 저장한다. (나중에 중복 제거하고 ','로 join할 예정)
                  if (this.$ekmtcCommon.isNotEmpty(respOceanFreight.cntrTypCd)) {
                    imsiFrtJson.cntrTypCd.push(respOceanFreight.cntrTypCd)
                  }
                  if (this.$ekmtcCommon.isNotEmpty(respOceanFreight.cgoTypNm)) {
                    imsiFrtJson.cgoTypNm.push(respOceanFreight.cgoTypNm)
                  }
                } else {
                  // 이전 FrtAppNo와 현재 FrtAppNo가 같지 않을 때는
                  // 실제 그리드에서 쓸 데이터 변수에 임시 변수 값을 넣어준다.
                  if (this.$ekmtcCommon.isNotEmpty(imsiFrtAppNo)) {
                    this.myOceanFreightList.push(imsiFrtJson)
                  }

                  imsiFrtAppNo = respOceanFreight.frtAppNo
                  imsiFrtJson = JSON.parse(JSON.stringify(respOceanFreight))

                  imsiFrtJson.cntrTypCd = []
                  if (this.$ekmtcCommon.isNotEmpty(respOceanFreight.cntrTypCd)) {
                    imsiFrtJson.cntrTypCd.push(respOceanFreight.cntrTypCd)
                  }
                  imsiFrtJson.cgoTypNm = []
                  if (this.$ekmtcCommon.isNotEmpty(respOceanFreight.cgoTypNm)) {
                    imsiFrtJson.cgoTypNm.push(respOceanFreight.cgoTypNm)
                  }
                }

                if (index === (respOceanFreightList.length - 1)) {
                  this.myOceanFreightList.push(imsiFrtJson)
                }
              })

              this.myOceanFreightList.forEach(myOceanFreight => {
                myOceanFreight.cntrTypCd = myOceanFreight.cntrTypCd.length > 0 ? [...new Set(myOceanFreight.cntrTypCd)].sort().join(',') : ''
                myOceanFreight.cgoTypNm = myOceanFreight.cgoTypNm.length > 0 ? [...new Set(myOceanFreight.cgoTypNm)].sort().join(',') : ''
              })
            }

            // //그리드 데이터 셋팅
            provider.setRows(this.myOceanFreightList)
          }
        })
      }
    },
    //다국어 적용
    setMessageProperty () {
      const header1 = gridView.getColumnProperty('frtAppNo', 'header')
      const header2 = gridView.getColumnProperty('porPlcCd', 'header')
      const header3 = gridView.getColumnProperty('dlyPlcCd', 'header')
      const header4 = gridView.getColumnProperty('rteCd', 'header')
      const header5 = gridView.getColumnProperty('vslVoy', 'header')
      const header6 = gridView.getColumnProperty('strDt', 'header')
      const header7 = gridView.getColumnProperty('endDt', 'header')
      const header8 = gridView.getColumnProperty('frtPncNm', 'header')
      const header9 = gridView.getColumnProperty('actShprCstEnm', 'header')
      const header10 = gridView.getColumnProperty('cntrTypCd', 'header')
      const header11 = gridView.getColumnProperty('cgoTypNm', 'header')
      const header12 = gridView.getColumnProperty('hotDealYn', 'header')
      const header13 = gridView.getColumnProperty('frtKnd', 'header')

      header1.text = this.$t('msg.FARE020T010.031') // S/C No.
      header2.text = this.$t('msg.FARE020T010.016') // POL
      header3.text = this.$t('msg.FARE020T010.017') // POD
      header4.text = this.$t('msg.FARE020T010.018') // 항로
      header5.text = this.$t('msg.FARE020T010.019') // 선명/항차
      header6.text = this.$t('msg.FARE020T010.020') // Effective Date
      header7.text = this.$t('msg.FARE020T010.021') // Expiry Date
      header8.text = this.$t('msg.FARE020T010.022') // Payment Term
      header9.text = this.$t('msg.FARE020T010.023') // B/L shipper
      header10.text = this.$t('msg.FARE020T010.024') // Container Type
      header11.text = this.$t('msg.FARE020T010.025') // Cargo Type
      header12.text = this.$t('msg.FARE020T010.035') // 프로모션
      header13.text = this.$t('msg.FARE020T010.036') // 프로모션

      gridView.setColumnProperty('frtAppNo', 'header', header1)
      gridView.setColumnProperty('porPlcCd', 'header', header2)
      gridView.setColumnProperty('dlyPlcCd', 'header', header3)
      gridView.setColumnProperty('rteCd', 'header', header4)
      gridView.setColumnProperty('vslVoy', 'header', header5)
      gridView.setColumnProperty('strDt', 'header', header6)
      gridView.setColumnProperty('endDt', 'header', header7)
      gridView.setColumnProperty('frtPncNm', 'header', header8)
      gridView.setColumnProperty('actShprCstEnm', 'header', header9)
      gridView.setColumnProperty('cntrTypCd', 'header', header10)
      gridView.setColumnProperty('cgoTypNm', 'header', header11)
      gridView.setColumnProperty('hotDealYn', 'header', header12)
      gridView.setColumnProperty('frtKnd', 'header', header13)
    },
    gridEvents () {
      const THIS = this
      gridView.onCellItemClicked = function (grid, index, clickData) {
        const current = gridView.getCurrent()
        const fieldName = current.fieldName

        // 선택 버튼 제어
        if (fieldName === 'frtAppNo') {
          const selectedRowData = THIS.myOceanFreightList[current.dataRow]

          THIS.surChargeParams = {
            porCtrCd: selectedRowData.porCtrCd,
            porPlcCd: selectedRowData.porPlcCd,
            dlyCtrCd: selectedRowData.dlyCtrCd,
            dlyPlcCd: selectedRowData.dlyPlcCd,
            frtAppNo: selectedRowData.frtAppNo,
            frtResult: 'Y',
            polNm: selectedRowData.porPlcNm,
            podNm: selectedRowData.dlyPlcNm,
            startDt: selectedRowData.strDt,
            endDt: selectedRowData.endDt,
            etd: selectedRowData.endDt,
            frtEstnYn: selectedRowData.frtEstnYn,
            frtExtsStsCd: selectedRowData.frtExtsStsCd,
            cstBidgMgrNo: selectedRowData.cstBidgMgrNo,
            cntrTypCd: (app.$ekmtcCommon.isEmpty(selectedRowData.cntrTypCd) ? '' : selectedRowData.cntrTypCd.split(',')[0]),
            hotDealYn: selectedRowData.hotDealYn,
            kmtcSpotUserYn: THIS.kmtcSpotUserYn,
            workingPrg: 'Y'
          }

          // 운임확인 팝업
          THIS.openPopup('fre-surcharge-pop')
        } else if (fieldName === 'endDt' && $(clickData.target).hasClass('button')) {
          const frtEstnYn = provider.getValue(current.dataRow, 'frtEstnYn')

          if (frtEstnYn !== 'I') {
            const frtExtsStsCd = provider.getValue(current.dataRow, 'frtExtsStsCd')
            const cstBidgMgrNo = provider.getValue(current.dataRow, 'cstBidgMgrNo')
            const endDt = provider.getValue(current.dataRow, 'endDt')

            const expDate = app.$ekmtcCommon.getStrToDate(endDt)
            const today = new Date()
            const twoWeekAfter = new Date().setDate(today.getDate() + 14)

            // 연장요청 가능한 상태일때만
            if (((today <= expDate && expDate <= twoWeekAfter) && frtExtsStsCd === '00' && THIS.$ekmtcCommon.isEmpty(cstBidgMgrNo)) ||
            (today > expDate && frtExtsStsCd === '00' && app.$ekmtcCommon.isEmpty(cstBidgMgrNo))
            ) {
              // 연장요청
              schedule.validityRequest({ frtAppNo: provider.getValue(current.dataRow, 'frtAppNo') })
                .then((response) => {
                  const alertOpt = {
                    alertType: 'simple',
                    customCloseBtnText: app.$t('msg.FARE020T010.027') // 확인
                  }

                  if (response.headers.respcode === 'C0000') {
                    app.$ekmtcCommon.alert({
                      ...alertOpt,
                      message: app.$t('msg.FARE020T010.034'), // 연장요청이 완료되었습니다. 연장요청이 승인되면 '나의 운임' 메뉴에서 새로운 S/C No. 를 확인하실 수 있습니다.
                      onClose: () => { THIS.searchMyOceanFreight() }
                    })
                  } else {
                    app.$ekmtcCommon.alert({
                      ...alertOpt,
                      message: app.$t('msg.FARE020T010.029') // 처리 중 오류가 발생하였습니다. 시스템 관리자에게 문의 부탁드립니다.
                    })
                  }
                })
            }
          }
        }
      }
    },
    openPopup (compNm) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.my_surcharge_pop')
      }

      this.customComponent = compNm

      this.$ekmtcCommon.layerOpen('.my_surcharge_pop')
    },
    closePopup (obj) {
      if (obj !== undefined && obj.negoYN === 'Y') {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.my_surcharge_pop')
        this.customComponent = 'request-quote-pop'
        this.$ekmtcCommon.layerOpen('.my_surcharge_pop')
      } else {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.my_surcharge_pop')
      }
    },
    // '검색'버튼 클릭시 검색조건 전체 validation check
    fnValidationAll () {
      const frm = document.querySelector('#frm2')
      let isAllOk = true

      // 출발지 validation check
      if (!this.fnValidationPor(frm)) {
        isAllOk = false
      }
      // 도착지 validation check
      if (!this.fnValidationDly(frm)) {
        isAllOk = false
      }

      return isAllOk && this.dtChk
    },
    // 출발지 validation check
    fnValidationPor (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#polPol')
      const inputElem = elem.querySelector('input')
      const inputValue = inputElem.value
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isNotEmpty(inputValue) && (this.$ekmtcCommon.isEmpty(this.params.porCtrCd) || this.$ekmtcCommon.isEmpty(this.params.porPlcCd))) {
        msg = this.$t('msg.FARE010T010.013') // 출발지를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 도착지 validation check
    fnValidationDly (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#podPod')
      const inputElem = elem.querySelector('input')
      const inputValue = inputElem.value
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isNotEmpty(inputValue) && (this.$ekmtcCommon.isEmpty(this.params.dlyCtrCd) || this.$ekmtcCommon.isEmpty(this.params.dlyPlcCd))) {
        msg = this.$t('msg.FARE010T010.014') // 도착지를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 시작일 validation check
    fnValidationFromDt (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#date_picker')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.fromDt)) {
        msg = '시작일을 선택해 주세요.' // 시작일을 선택해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 종료일 validation check
    fnValidationToDt (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#date_picker')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.toDt)) {
        msg = '종료일을 선택해 주세요.' // 종료일을 선택해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    changeDateRange (sdt, edt) {
      const frm = document.querySelector('#frm2')
      const selector = frm.querySelector('#date_picker')

      this.params.fromDt = sdt
      this.params.toDt = edt

      if (parseInt(this.params.fromDt, 10) > parseInt(this.params.toDt, 10)) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
        this.dtChk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(selector)
        this.dtChk = true
      }
    },
    getKmtcSpotTarget () {
      //menuCD 01은 로그를 남기지 않음. kmtc on 대상 여부만 조회
      schedule.getKmtcSpotTarget('01').then((response) => {
        this.kmtcSpotUserYn = response.data[0].kmtcSpotUserYn
      })
    },
    exlDownload () {
      const curDt = moment().format('YYYYMMDD')
      gridView.exportGrid({
        type: 'excel',
        target: 'local',
        documentTitle: { //제목
          message: this.$t('menu.MENU05.000') + '_' + this.$t('menu.MENU05.020'), // 업무 대행정보 리스트
          visible: true,
          spaceTop: 1,
          spaceBottom: 1,
          height: 60,
          styleName: 'excel-export-title-style'
        },
        applyDynamicStyles: true,
        exportTemplate: true,
        //allColumns: true,
        pagingAllItems: true,
        fileName: `${this.$t('menu.MENU05.000') + '_' + this.$t('menu.MENU05.020')}_${curDt}.xlsx`,
        showProgress: false,
        progressMessage: this.$t('msg.ADD20220219.012'), // 엑셀 Export중입니다.
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          console.log('엑셀 다운로드 완료')
        }
      })
    } /*
    exlDownload () { // 그리드 엑셀 다운로드
      let title = `${this.$t('menu.MENU05.000')}_${this.$t('menu.MENU05.020')}` // 운임_나의운임
      this.$ekmtcCommon.gridExportExcel(gridView, title)
    } */
  }
}
</script>
