<template>
  <div class="popup_wrap" style="width:1200px;">
    <!-- popup_wrap -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.ADD20220219.035') }}</h1> <!-- 정산목록확인 -->
      <div class="content_box">
        <p>
          <span class="mr10">{{ $t('msg.ADD20220219.036') }}</span> <!-- 아래 선택하신 목록과 합계가 귀사의 정산내역과 일치합니까? -->
          <input type="radio" id="com-settle-check-yes" value="Y" v-model="comSettleCheck" @change="btnDisplay">
          <label for="com-settle-check-yes" class="mr20"><span></span>{{ $t('msg.SETT060G010.020') }}</label>
          <input type="radio" id="com-settle-check-no" value="N" v-model="comSettleCheck" @change="btnDisplay">
          <label for="com-settle-check-no"><span></span>{{ $t('msg.SETT060G010.021') }}</label>
        </p>
        <p class="txt_desc">{{ $t('msg.ADD20220219.037') }}</p> <!-- 순서와 상관없이 복사/붙여넣기를 한 후 매칭하기를 누르면 비교 결과를 확인 할 수 있습니다 -->
      </div>

      <div class="content_box cradit-transaction-settle">
        <div class="tbl_form">
          <div class="dv col_4">
            <h3 class="mb-1">
              KMTC ({{ selectData.length }}{{ $t('msg.SETT060G010.008') }}, {{ $ekmtcCommon.changeNumberFormat(selectTotal, { isComma: true }) }})
            </h3>
            <div id="settle-realgrid" style="width: 100%; height: 450px"></div>
          </div>
          <span class="dv span-arrow text_center valign_middle wid100 pr5">
            <button class="tbl_icon arrow" @click="copyData">arrow</button>
          </span>
          <div class="dv col_2">
            <div class="row">
              <div class="col-6">
                <h3 class="mb-1">{{ $t('rad.VOSD100.021') }}</h3> <!-- 화주 -->
              </div>
              <div class="col-6 text-right">
                <button
                  type="button"
                  class="button sm"
                  @click="userDataAdd"
                >
                  +
                </button>
              </div>
            </div>
            <div id="user-realgrid" style="width: 100%; height: 450px" @paste="onPaste"></div>
          </div>
          <span class="dv span-arrow text_center valign_middle wid100 pr5">

          </span>
          <div class="dv col_3">
            <h3 class="mb-1">{{ $t('msg.CMBA100.00276') }}</h3> <!-- 결과 -->
            <div id="match-realgrid" style="width: 100%; height: 450px"></div>
          </div>
        </div>

        <div class="tbl_form">
          <div class="dv col_4">
          </div>
          <span class="dv text_center valign_middle wid100 pr5">
          </span>
          <div class="dv col_2">
            <div class="mt10 text_right">
              <button
                type="button"
                class="button sm"
                @click="matching"
                :disabled="!(comSettleCheck === 'N')"
              >
                {{ $t('msg.ADD20220219.039') }} <!-- 매칭하기 -->
              </button>
            </div>
          </div>
          <span class="dv text_center valign_middle wid100 pr5">
          </span>
          <div class="dv col_3">
            <span style="font-size: 0.8rem;">※ {{ $t('msg.ADD20220219.038') }}</span> <!-- KMTC 영역에서 확인되지 않는 건들은 그대로 제출해주시면 폐사 영업담당자가 확인 예정입니다. -->
          </div>
        </div>
        <!-- <pre>{{ jsonText }}</pre> -->
      </div>
      <div class="mt10 text_right">
        <b-button
          v-show="btn.id1"
          variant="primary"
          class="ml-2"
          :disabled="btnDisabled.id1"
          @click="submitVerificationMaterials"
        >
          {{ $t('msg.ONEX070G090.029') }} <!-- 제출 -->
        </b-button>
        <b-button
          v-show="btn.id2"
          variant="primary"
          class="ml-2"
          :disabled="btnDisabled.id2"
          @click="confirmationAndIssuance"
        >
          {{ $t('msg.ADD20220219.040') }} <!-- 확정 및 세금계산서 발행 요청 -->
        </b-button>
        <b-button
          v-show="btn.id3"
          variant="secondary"
          class="ml-2"
          @click="confirmCancel"
        >
          {{ $t('msg.ONEX050P190.037') }} <!-- 취소 -->
        </b-button>
      </div>
    </div>
    <!-- <textarea style="display:;" @paste="onPaste"></textarea> -->
  </div>
</template>

<style lang="scss">
  .cradit-transaction-settle .multi-line-css {
    white-space: pre;
    text-align: center !important;
  }
  .cradit-transaction-settle .right-column {
    text-align: right !important;
  }
  .cradit-transaction-settle .total-amt {
    text-align: right;
    font-weight: 700 !important;
  }
  .cradit-transaction-settle .red-total-amt {
    color: red;
    font-weight: 700;
    text-align: right !important;
  }
  .cradit-transaction-settle .footer-title {
    text-align: center !important;
    font-weight: 700;
  }
</style>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import CreditApi from '@/api/rest/settle/credit'

let gridView = GridView
let provider = LocalDataProvider

let userGridView = GridView
let userProvider = LocalDataProvider

let matchGridView = GridView
let matchProvider = LocalDataProvider

export const fields = [
  { fieldName: 'bascDt', dataType: 'text' },
  { fieldName: 'vslNm', dataType: 'text' },
  { fieldName: 'voyNo', dataType: 'text' },
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'bizNo', dataType: 'text' },
  { fieldName: 'cstNm', dataType: 'text' },
  { fieldName: 'cstNml', dataType: 'text' },
  { fieldName: 'porPlcCd', dataType: 'text' },
  { fieldName: 'dlyPlcCd', dataType: 'text' },
  { fieldName: 'ofUsdAmt', dataType: 'number' },
  { fieldName: 'exrt', dataType: 'number' },
  { fieldName: 'ofLocAmt', dataType: 'number' },
  { fieldName: 'thcLocAmt', dataType: 'number' },
  { fieldName: 'dcfLocAmt', dataType: 'number' },
  { fieldName: 'wfgLocAmt', dataType: 'number' },
  { fieldName: 'pfsLocAmt', dataType: 'number' },
  { fieldName: 'afsLocAmt', dataType: 'number' },
  { fieldName: 'othLocAmt', dataType: 'number' },
  { fieldName: 'totalAmt', dataType: 'number' },
  { fieldName: 'blSort', dataType: 'number' },
  { fieldName: 'isExist', dataType: 'number' },
  { fieldName: 'delVal', dataType: 'text' }
]
export const userFields = [
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'totalAmt', dataType: 'number' },
  { fieldName: 'sort', dataType: 'number' },
  { fieldName: 'isExist', dataType: 'number' }
]
export const matchFields = [
  { fieldName: 'isExist', dataType: 'number' },
  { fieldName: 'baseTotal', dataType: 'number' },
  { fieldName: 'userTotal', dataType: 'number' },
  { fieldName: 'diffAmt', dataType: 'number' },
  { fieldName: 'sort', dataType: 'number' },
  { fieldName: 'remark', dataType: 'text' },
  { fieldName: 'blNo', dataType: 'text' }
]

export const columns = [
  {
    name: 'bascDt',
    fieldName: 'bascDt',
    width: '60',
    header: { text: 'Date', styleName: 'header-title' },
    footer: { text: '합계금액', styleName: 'footer-title' },
    editable: false
  },
  {
    name: 'vslNm',
    fieldName: 'vslNm',
    width: '140',
    header: { text: 'VSL/VOY' },
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const vslNm = grid.getValue(idx, 'vslNm')
        const voyNo = grid.getValue(idx, 'voyNo')
        console.log('## voyNo >>', voyNo)

        const txt = `${vslNm}<br/>${voyNo}`
        return txt
      }
    },
    editable: false
  },
  { name: 'voyNo', fieldName: 'voyNo', header: { text: 'voyNo' }, editable: false },
  {
    name: 'blNo',
    fieldName: 'blNo',
    header: { text: 'B/L No.', styleName: 'header-title' },
    footer: { text: 'B/L Count', styleName: 'footer-title' },
    editable: false
  },
  { name: 'bizNo', fieldName: 'bizNo', header: { text: 'bizNo' }, editable: false },
  { name: 'cstNm', fieldName: 'cstNm', width: '200', header: { text: 'Shipper\n/Consignee', styleName: 'cradit-transaction-settle multi-line-css' }, editable: false },
  { name: 'cstNml', fieldName: 'cstNml', width: '150', header: { text: 'Shipper\n/Consignee', styleName: 'cradit-transaction-settle multi-line-css' }, editable: false },
  {
    name: 'porPlcCd',
    fieldName: 'porPlcCd',
    width: '80',
    header: { text: 'POR' },
    footer: { expression: 'count', numberFormat: '#,##0', styleName: 'cradit-transaction-settle total-amt' },
    editable: false
  },
  {
    name: 'dlyPlcCd',
    fieldName: 'dlyPlcCd',
    width: '80',
    header: { text: 'DLY' },
    editable: false
  },
  {
    name: 'ofUsdAmt',
    fieldName: 'ofUsdAmt',
    width: '80',
    numberFormat: '#,##0.00',
    styleName: 'right-column',
    header: { text: 'O/F\n(USD)', styleName: 'cradit-transaction-settle multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0.00', styleName: 'cradit-transaction-settle total-amt' },
    editable: false
  },
  {
    name: 'exrt',
    fieldName: 'exrt',
    width: '80',
    numberFormat: '#,##0.0000',
    styleName: 'right-column',
    header: { text: 'EX.\nRATE', styleName: 'cradit-transaction-settle multi-line-css' },
    editable: false
  },
  {
    name: 'ofLocAmt',
    fieldName: 'ofLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'O/F\n(KRW)', styleName: 'cradit-transaction-settle multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'cradit-transaction-settle total-amt' },
    editable: false
  },
  {
    name: 'thcLocAmt',
    fieldName: 'thcLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'THC' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'cradit-transaction-settle total-amt' },
    editable: false
  },
  {
    name: 'dcfLocAmt',
    fieldName: 'dcfLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'DCF' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'cradit-transaction-settle total-amt' },
    editable: false
  },
  {
    name: 'wfgLocAmt',
    fieldName: 'wfgLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'WFG' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'cradit-transaction-settle total-amt' },
    editable: false
  },
  {
    name: 'pfsLocAmt',
    fieldName: 'pfsLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'PFS' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'cradit-transaction-settle total-amt' },
    editable: false
  },
  {
    name: 'afsLocAmt',
    fieldName: 'afsLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'AFA' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'cradit-transaction-settle total-amt' },
    editable: false
  },
  {
    name: 'othLocAmt',
    fieldName: 'othLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'OTH' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'cradit-transaction-settle total-amt' },
    editable: false
  },
  {
    name: 'totalAmt',
    fieldName: 'totalAmt',
    width: '100',
    numberFormat: '#,##0',
    styleName: 'cradit-transaction-settle total-amt',
    header: { text: 'Amount (a)' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'cradit-transaction-settle total-amt' },
    editable: false
  },
  {
    name: 'delVal',
    fieldName: 'delVal',
    type: 'text',
    width: '40',
    header: { text: 'Del' },
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow

        const txt = `<div style="display: block; text-align: center;"><span style="display: block; text-align: center;" title="${cell.value}" alt="${cell.value}">x</span></div>`
        return txt
      }
    },
    editable: false
  }
]
export const userColumns = [
  {
    name: 'blNo',
    fieldName: 'blNo',
    width: '100',
    header: { text: 'B/L No.' },
    footer: { text: app.$t('msg.ONEX010T010.118'), styleName: 'footer-title' }, //'금액'
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const isExist = grid.getValue(idx, 'isExist')

        let txt = `<span>${cell.value}</span>`
        if (isExist === 1) {
          txt = `<span style="color: #dd3333;">${cell.value}</span>`
        } else if (isExist === 2) {
          txt = `<span style="color: #dd3333;">${cell.value}</span>`
        } else if (isExist === 3) {
          txt = `<span style="color: #dd3333;">${cell.value}</span>`
        }
        return txt
      }
    },
    editable: true
  },
  {
    name: 'totalAmt',
    fieldName: 'totalAmt',
    width: '119',
    numberFormat: '#,##0',
    styleName: 'cradit-transaction-settle total-amt',
    header: { text: 'Amount (b)', styleName: 'cradit-transaction-settle multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'cradit-transaction-settle total-amt' },
    editable: true
  }
]
export const matchColumns = [
  {
    name: 'blNo',
    fieldName: 'blNo',
    width: '100',
    header: { text: 'B/L No.' },
    editable: false
  },
  {
    name: 'diffAmt',
    fieldName: 'diffAmt',
    width: '100',
    numberFormat: '#,##0',
    // styleName: 'cradit-transaction-settle red-total-amt',
    header: { text: 'Amount (a-b)' },
    styleCallback: function (grid, dataCell) {
      const data = dataCell.value
      const numberData = new Intl.NumberFormat().format(data)
      let returnStyle = {}
      returnStyle.editable = false

      if (data !== 0) {
        returnStyle.styleName = 'cradit-transaction-settle red-total-amt'
      } else {
        returnStyle.styleName = 'cradit-transaction-settle total-amt'
      }

      return returnStyle
    },
    footer: {
      expression: 'sum',
      numberFormat: '#,##0',
      // styleName: 'cradit-transaction-settle red-total-amt',
      styleCallback: function (grid, dataCell) {
        const data = dataCell.value
        const numberData = new Intl.NumberFormat().format(data)
        let returnStyle = {}
        returnStyle.editable = false

        if (data !== 0) {
          returnStyle.styleName = 'cradit-transaction-settle red-total-amt'
        } else {
          returnStyle.styleName = 'cradit-transaction-settle total-amt'
        }

        return returnStyle
      }
    },
    editable: false
  },
  {
    name: 'remark',
    fieldName: 'remark',
    width: '130',
    header: { text: 'Remark' },
    editable: true
  }
]

let layout = [
  'bascDt',
  'vslNm',
  'blNo',
  'cstNm',
  'porPlcCd',
  'dlyPlcCd',
  'ofUsdAmt',
  'exrt',
  'ofLocAmt',
  'thcLocAmt',
  'dcfLocAmt',
  'wfgLocAmt',
  'pfsLocAmt',
  'afsLocAmt',
  'othLocAmt',
  'totalAmt',
  'delVal'
]
let layoutHGroup = [
  {
    name: 'blInfo',
    direction: 'horizontal',
    items: [
      {
        column: 'blNo',
        height: 30
      },
      'cstNm',
      'porPlcCd',
      'dlyPlcCd',
      'ofUsdAmt',
      'exrt',
      'ofLocAmt',
      'thcLocAmt',
      'dcfLocAmt',
      'wfgLocAmt',
      'pfsLocAmt',
      'afsLocAmt',
      'othLocAmt'
    ],
    header: {
      text: '회사명',
      styleName: '',
      height: 50
    }
  },
  'totalAmt',
  'delVal'

]
let layoutFooter = [
  {
    name: 'group1',
    header: { visible: false },
    direction: 'horizontal',
    items: [
      { column: 'blNo', footerUserSpans: [{ rowspan: 1, colspan: 2 }] },
      'cstNm'
    ]
  },
  'porPlcCd',
  'dlyPlcCd',
  'ofUsdAmt',
  'exrt',
  'ofLocAmt',
  'thcLocAmt',
  'dcfLocAmt',
  'wfgLocAmt',
  'pfsLocAmt',
  'afsLocAmt',
  'othLocAmt',
  'totalAmt',
  'delVal'
]

let userLayout = [
  'blNo',
  'totalAmt'
]

let userLayoutFooter = [
  'blNo',
  'totalAmt'
]
let matchLayout = [
  'blNo',
  'diffAmt',
  'remark'
]

export default {
  name: 'CreditTransactionSettlePop',
  props: {
    settleData: {
      type: Array,
      default: () => {
        return []
      }
    },
    sDate: {
      type: String,
      default: ''
    },
    eDate: {
      type: String,
      default: ''
    },
    eiCatCd: {
      type: String,
      default: 'O' //수출
    },
    poParamiters: {
      type: Object,
      default: () => {
        return undefined
      }
    }
  },
  data () {
    return {
      jsonText: '',
      clipboardData: [],
      userData: [],
      matchData: [],
      selectData: [],
      selectTotal: 0,
      comSettleCheck: 'Y',
      asEiCatCd: 'O',
      btn: {
        id1: true,
        id2: true,
        id3: true
      },
      btnDisabled: {
        id1: false,
        id2: false,
        id3: false
      },
      saveData: {
        info: {},
        detailReqList: []
      },
      refNo: '',
      searchDate: {
        sDt: '',
        eDt: ''
      }
    }
  },
  watch: {
    settleData (nV, oV) {
      console.log('Data >>', nV)
      if (!Array.isArray(nV)) {
        nV = []
      }
      this.selectData = nV
      this.setData()
    },
    selectData (nV, oV) {
      this.jsonText = JSON.stringify(nV)
    },
    sDate (nV, oV) {
      this.searchDate.sDt = nV
    },
    eDate (nV, oV) {
      this.searchDate.eDt = nV
    },
    eiCatCd (nV, oV) {
      console.log('data > ', nV)
      this.asEiCatCd = nV
    }
  },
  created () {},
  mounted () {
    if (Array.isArray(this.settleData)) {
      this.selectData = this.settleData
    }
    this.asEiCatCd = this.eiCatCd
    this.searchDate.sDt = this.sDate
    this.searchDate.eDt = this.eDate

    provider = new LocalDataProvider(true)
    gridView = new GridView('settle-realgrid')

    provider.setFields(fields)

    // 그리고 헤더 높이 조정
    gridView.header.heights = 48
    gridView.displayOptions.rowHeight = 36
    gridView.defaultColumnWidth = 48
    gridView.displayOptions.fitStyle = 'even'
    gridView.displayOptions.selectionStyle = 'block'

    gridView.setStateBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    gridView.setCheckBar({
      visible: false,
      exclusive: false
    })
    gridView.setPaging(false, 10)

    gridView.setColumns(columns)
    gridView.setColumnLayout(layoutFooter)
    gridView.setDataSource(provider)

    gridView.setFixedOptions({
      // colCount: 4,
      rightCount: 2
    })
    const $vm = this
    gridView.onCellClicked = function (grid, info) {
      if (info.cellType === 'data') {
        if (info.column === 'delVal') {
          $vm.delSettleData(info.dataRow)
        }
      }
    }

    // 사용자 데이터영역
    userProvider = new LocalDataProvider(true)
    userGridView = new GridView('user-realgrid')
    userProvider.setFields(userFields)

    // 그리고 헤더 높이 조정
    userGridView.header.heights = 48
    userGridView.displayOptions.rowHeight = 36
    userGridView.defaultColumnWidth = 48
    userGridView.displayOptions.fitStyle = 'none'
    userGridView.displayOptions.selectionStyle = 'none'

    userGridView.setStateBar({
      visible: false
    })
    userGridView.setRowIndicator({
      visible: false
    })
    userGridView.setCheckBar({
      visible: false,
      exclusive: false
    })
    userGridView.setPaging(false, 10)

    userGridView.setColumns(userColumns)
    userGridView.setColumnLayout(userLayout)
    userGridView.setDataSource(userProvider)
    userGridView.setColumnLayout(userLayoutFooter)

    // userGridView.onPasted = function (grid, index, event) {
    //   userGridView.cancelEditor()
    //   grid.commit()
    //   console.log('# 붙여넣기')
    //   let cpData = grid.getSelectionData()
    //   console.log(JSON.stringify(cpData))

    //   this.userData = []
    //   this.userData = cpData

    //   userProvider.setRows(this.userData)
    // }

    // 사용자 데이터영역
    matchProvider = new LocalDataProvider(true)
    matchGridView = new GridView('match-realgrid')
    matchProvider.setFields(matchFields)

    // 그리고 헤더 높이 조정
    matchGridView.header.heights = 48
    matchGridView.displayOptions.rowHeight = 36
    matchGridView.displayOptions.fitStyle = 'none'
    matchGridView.displayOptions.selectionStyle = 'none'

    matchGridView.setStateBar({
      visible: false
    })
    matchGridView.setRowIndicator({
      visible: false
    })
    matchGridView.setCheckBar({
      visible: false,
      exclusive: false
    })
    matchGridView.setPaging(false, 10)

    matchGridView.setColumns(matchColumns)
    matchGridView.setColumnLayout(matchLayout)
    matchGridView.setDataSource(matchProvider)

    gridView.onTopIndexChanged = function (grid, itemIndex) {
      $vm.setGridTopItemIndex(grid, itemIndex)
    }

    userGridView.onTopIndexChanged = function (grid, itemIndex) {
      $vm.setGridTopItemIndex(grid, itemIndex)
    }

    matchGridView.onTopIndexChanged = function (grid, itemIndex) {
      $vm.setGridTopItemIndex(grid, itemIndex)
    }

    this.setData()
    this.init()
  },
  methods: {
    btnDisplay () {
      if (this.comSettleCheck === 'Y') {
        this.btn.id1 = false
        this.btn.id2 = true
        this.btn.id3 = true
        userProvider.setRows([])
        matchProvider.setRows([])
      } else {
        this.btn.id1 = false
        this.btn.id2 = false
        this.btn.id3 = true
        this.userDataInit()
      }
    },
    init () {
      this.btnDisplay()
    },
    setData () {
      // console.log('eiCatCd > ', this.asEiCatCd)
      if (!this.$_.has(this.selectData, 'delVal')) {
        this.selectData = this.selectData.map((v, inx) => {
          return { ...v, delVal: v.blNo, isExist: 0, blSort: inx }
        })
        // console.log('tt >', tt)
        // this.selectData = tt
      }
      // console.log('this.selectData >', this.selectData)
      provider.setRows(this.selectData)
      let tot = 0
      this.selectData.forEach(v => {
        tot += v.totalAmt
      })
      this.selectTotal = tot
    },
    delSettleData (inx) {
      if (inx > -1) {
        const tmp = provider.getJsonRows(inx, inx)
        if (tmp.length > 0) {
          const vo = tmp[0]
          const tmpArr = this.selectData.filter(v => v.blNo !== vo.blNo)
          this.selectData = tmpArr
          if (this.selectData.length === 0) {
            this.$emit('close')
            return
          }
          this.setData()
        }
      }
    },
    userDataAdd () {
      if (this.comSettleCheck === 'Y') {
        return
      }

      const tmpData = {
        blNo: '',
        totalAmt: 0,
        sort: 0,
        isExist: 0
      }
      this.userData.push(tmpData)
      userProvider.setRows(this.userData)
    },
    copyData () {
      if (this.comSettleCheck === 'Y') {
        return
      }

      let tmp = provider.getJsonRows(0, -1)
      if (tmp.length === 0) {
        this.openAlert(this.$t('msg.ADD20220219.041')) // 정산 데이터가 없습니다.
        return
      }
      this.userData = []
      tmp.forEach(vo => {
        const tmpData = {
          blNo: vo.blNo,
          totalAmt: vo.totalAmt,
          sort: 0,
          isExist: 0
        }
        this.userData.push(tmpData)
      })
      userProvider.setRows(this.userData)
    },
    userDataInit () {
      this.userData = []
      const tmpData = {
        blNo: '',
        totalAmt: 0,
        sort: 0,
        isExist: 0
      }
      this.selectData.forEach(v => {
        this.userData.push(tmpData)
      })

      userProvider.setRows(this.userData)
    },
    async matching () {
      if (this.comSettleCheck === 'Y') {
        return
      }

      this.btnDisabled.id1 = false
      this.btnDisabled.id2 = false

      let isItemEditing = userGridView.isItemEditing()

      if (isItemEditing) {
        console.log('@@@@@@@@@@@@@ 편집 중 입니다.')
        userGridView.commit()
        return
      }
      let tmpMatchData = []
      let tmp = userProvider.getJsonRows(0, -1)
      if (tmp.length === 0) {
        this.openAlert(this.$t('msg.ADD20220219.042')) //'화주측 데이터가 없습니다.'
        return
      }
      const ttmp = tmp.filter(v => this.$_.trim(v.blNo) === '')
      console.log('검증 데이터 >> ', ttmp)
      if (ttmp.length > 0) {
        this.openAlert(this.$t('msg.ADD20220219.043')) //'화주측 BL 정보를 입력하시오'
        return
      }

      let blNoLst = []
      tmp.forEach(vo => {
        blNoLst.push(vo.blNo)
      })
      const param = {
        arrBlNo: blNoLst,
        eiCatCd: this.asEiCatCd,
        userCtrCd: ''
      }

      let oldBlList = []
      let chResult = []
      console.info(JSON.stringify(param))
      await CreditApi.getArrangeIsBlExist(param)
      .then(async response => {
        console.log('>> api result >', response)
        if (response.data) {
          oldBlList = response.data.bList
          chResult = response.data.result
        }
      })
      .catch((err) => {
        console.log(err)
      })

      console.log('>>> getIsBlExist >', oldBlList)
      console.log('>>> chResult >', chResult)

      // oldBlList.forEach(ov => {
      //   const inx = tmp.findIndex(uv => ov.blNo === uv.blNo)
      // })
      console.log(tmp)
      const userData = tmp.map(vo => {
        // 정상 BL 유무
        const inxIsExist = chResult.findIndex(o => o.blNo === vo.blNo && o.isExist === 1)
        // Invoice 발행 유무
        const inxIsInvoiceExist = chResult.findIndex(o => o.blNo === vo.blNo && o.isInvoiceExist === 1)
        // 기 정산대사 제출 유무
        const inxIsPublishExist = chResult.findIndex(o => o.blNo === vo.blNo && o.isPublishExist === 1)

        if (inxIsExist !== -1) {
          // 정상 BL
          vo.isExist = 0
          if (inxIsInvoiceExist !== -1) {
            vo.isExist = 1
          }
          if (inxIsPublishExist !== -1) {
            vo.isExist = 2
          }
        } else {
          // B/L Master에 존재하지 않음
          vo.isExist = 3
        }

        vo.sort = 99999
        const tindex = this.selectData.findIndex(u => vo.blNo === u.blNo)
        if (tindex !== -1) {
          console.log('this.selectData[tindex].blSort >', this.selectData[tindex])
          vo.sort = this.selectData[tindex].blSort
        }
        console.log(vo)

        return {
          ...vo
        }
      })
      this.userData = this.$_.sortBy(userData, ['isExist', 'sort'])

      console.log('#2 >> ', this.userData)
      userProvider.setRows(this.userData)

      // tmp = userProvider.getJsonRows(0, -1)
      /*
        선택한 KMC 데이터 : A데이터
        화주가 붙여넣은 데이터 : B데이터
        매치결과로 생긴 데이터 : C데이터
      */
      this.userData.forEach(v => {
        if (v.isExist === 0) {
          const tindex = this.selectData.findIndex(u => v.blNo === u.blNo)
          console.log(tindex)
          if (tindex > -1) {
            this.selectData[tindex].isExist = -1
            const vo = this.selectData[tindex]
            const tmpMatchObj = {
              sort: tindex,
              baseTotal: 0,
              userTotal: 0,
              diffAmt: 0,
              remark: '',
              blNo: ''
            }
            tmpMatchObj.baseTotal = vo.totalAmt
            tmpMatchObj.userTotal = v.totalAmt
            tmpMatchObj.diffAmt = vo.totalAmt - v.totalAmt
            tmpMatchObj.remark = tmpMatchObj.diffAmt === 0 ? this.$t('msg.ADD20220219.044') : this.$t('msg.ADD20220219.045') //'일치합니다' : '금액 상이합니다.'
            tmpMatchObj.blNo = v.blNo
            tmpMatchData.push(tmpMatchObj)
          } else {
            const tmpMatchObj = {
              sort: 99999,
              baseTotal: 0,
              userTotal: 0,
              diffAmt: 0,
              remark: '',
              blNo: ''
            }
            tmpMatchObj.baseTotal = 0
            tmpMatchObj.userTotal = v.totalAmt
            tmpMatchObj.diffAmt = v.totalAmt * -1
            tmpMatchObj.remark = this.$t('msg.ADD20220219.045') //'금액 상이합니다.'
            tmpMatchObj.blNo = v.blNo
            tmpMatchData.push(tmpMatchObj)
          }
        }
      })

      tmpMatchData = this.$_.sortBy(tmpMatchData, ['sort'])
      this.selectData = this.$_.sortBy(this.selectData, ['isExist', 'sort'])
      provider.setRows(this.selectData)

      console.log('>>> 매칭 데이터 >', tmpMatchData)
      matchProvider.setRows(tmpMatchData)

      this.matchDataCheck()
      if (this.userData.findIndex(u => u.isExist > 0) !== -1) {
        this.openAlert(this.$t('msg.ADD20220219.046')) // '빨간색으로 표시된 건은 정산완료/ 타 정산목록에 포함 / 잘못된 번호인 건으로, 최종 목록에서 제외 됩니다.'
      }
    },

    matchDataCheck () {
      this.saveData = {
        info: {},
        detailReqList: []
      }

      let baseData = provider.getJsonRows(0, -1)
      let userData = userProvider.getJsonRows(0, -1)
      let matchResult = matchProvider.getJsonRows(0, -1)

      let baseTotal = 0
      let baseUsdTotal = 0
      let userTotal = 0
      let gapTotal = 0

      let result = baseData.map(v => {
        let shprBlAmt = 0
        userData.filter(u => { return v.blNo === u.blNo })
        .forEach(uf => {
          userTotal += uf.totalAmt
          shprBlAmt = uf.totalAmt
        })

        let tmp = matchResult.filter(m => {
          return m.blNo === v.blNo
        })

        let rmkText = ''
        if (tmp.length > 0) {
          rmkText = tmp[0].remark
        }

        let gapAmt = v.totalAmt - shprBlAmt
        gapTotal += gapAmt
        baseTotal += v.totalAmt
        baseUsdTotal += v.ofUsdAmt

        return { ...v, shprBlAmt, gapAmt, rmkText }
      })

      let detailList = []
      matchResult.forEach((v, inx) => {
        const tplDetail = {
              refNo: '',
              seq: inx + 1,
              blNo: v.blNo,
              blAmt: v.baseTotal,
              shprBlAmt: v.userTotal,
              gapAmt: v.diffAmt,
              rmk: v.remark,
              dtlStsYn: v.diffAmt === 0 ? 'Y' : 'N',
              fstEntDtm: '',
              fstEntUno: '',
              lstUptDtm: '',
              lstUptUno: ''
            }
        detailList.push(tplDetail)
      })
      console.log('>>> 매칭 데이터 >', detailList)

      let stsCdList = detailList.filter(v => { return v.dtlStsYn === 'N' })

      const info = {
            refNo: '',
            invNo: '',
            dclrYn: 'N',
            dclrDtm: null,
            strObrdDt: this.searchDate.sDt,
            endObrdDt: this.searchDate.eDt,
            usdAmt: baseUsdTotal,
            krwAmt: baseTotal,
            appvMeth: '01',
            stsCd: stsCdList.length > 0 ? '01' : '02',
            rmk: stsCdList.length > 0 ? this.$t('msg.ADD20220219.047') : this.$t('msg.ADD20220219.044'), //'일치하지 않습니다.' : '일치합니다.',
            picNo: '',
            fstEntDtm: '',
            fstEntUno: '',
            lstUptDtm: '',
            lstUptUno: '',
            eiCatCd: this.asEiCatCd
      }

      this.saveData.info = { ...info }
      this.saveData.detailReqList = detailList

      console.log(this.saveData)

      if (detailList.length > 0) {
        this.btn.id1 = true
        if (stsCdList.length > 0) {
          this.btn.id2 = false
        } else {
          this.btn.id2 = true
        }
      } else {
        this.btn.id1 = false
        this.btn.id2 = false
      }
    },

    async getIsBlExist () {
      // 기 처리된 B/L의 존재 여부 확인
      let blNoLst = []
      const userData = userProvider.getJsonRows(0, -1)
      userData.forEach(vo => {
        blNoLst.push(vo.blNo)
      })
      console.info(JSON.stringify(blNoLst))
      await CreditApi.getArrangeIsBlExist(blNoLst)
      .then(async response => {
        console.log(response)
        if (response.data) {
          return response.data.bList
        }
      })
      .catch((err) => {
        console.log(err)
      })
      return []
    },

    // '네' 입력 시 처리
    async matchDataCheckOk () {
      let baseData = provider.getJsonRows(0, -1)

      let blNoLst = []
      baseData.forEach(vo => {
        blNoLst.push(vo.blNo)
      })
      const param = {
        arrBlNo: blNoLst,
        eiCatCd: this.asEiCatCd,
        userCtrCd: ''
      }
      let chResult = []
      console.info(JSON.stringify(param))
      await CreditApi.getArrangeIsBlExist(param)
      .then(async response => {
        console.log('>> api result >', response)
        if (response.data) {
          chResult = response.data.result
        }
      })
      .catch((err) => {
        console.log(err)
      })

      let inxIsNotExistList = []
      let inxIsInvoiceExistList = []
      let inxIsPublishExistList = []
      let inxIsInvoiceExistMap = {}
      let inxIsPublishExistMap = {}

      const userData = baseData.map(vo => {
        // 정상 BL 유무
        const inxIsExist = chResult.findIndex(o => o.blNo === vo.blNo && o.isExist === 1)
        // Invoice 발행 유무
        const inxIsInvoiceExist = chResult.findIndex(o => o.blNo === vo.blNo && o.isInvoiceExist === 1)
        // 기 정산대사 제출 유무
        const inxIsPublishExist = chResult.findIndex(o => o.blNo === vo.blNo && o.isPublishExist === 1)

        if (inxIsExist !== -1) {
          // 정상 BL
          vo.isExist = 0
          if (inxIsInvoiceExist !== -1) {
            vo.isExist = 1
            // inxIsInvoiceExistList.push(vo.blNo)
            inxIsInvoiceExistList.push({ blNo: vo.blNo, invIssNoList: chResult[inxIsInvoiceExist].invIssNoList })
          }
          if (inxIsPublishExist !== -1) {
            vo.isExist = 2
            // inxIsPublishExistList.push(vo.blNo)
            inxIsPublishExistList.push({ blNo: vo.blNo, refNoList: chResult[inxIsPublishExist].refNoList })
          }
        } else {
          // B/L Master에 존재하지 않음
          vo.isExist = 3
          inxIsNotExistList.push(vo.blNo)
        }

        return {
          ...vo
        }
      })

      let detailList = []

      let baseTotal = 0
      let baseUsdTotal = 0
      userData.forEach((v, inx) => {
        if (v.isExist === 0) {
          const tplDetail = {
                refNo: '',
                seq: inx + 1,
                blNo: v.blNo,
                blAmt: v.totalAmt,
                shprBlAmt: v.totalAmt,
                gapAmt: 0,
                rmk: this.$t('msg.ADD20220219.044'), // '일치합니다',
                dtlStsYn: 'Y',
                fstEntDtm: '',
                fstEntUno: '',
                lstUptDtm: '',
                lstUptUno: ''
              }
          baseTotal += v.totalAmt
          baseUsdTotal += v.ofUsdAmt
          detailList.push(tplDetail)
        }
      })

      const info = {
            refNo: '',
            invNo: '',
            dclrYn: 'Y',
            dclrDtm: null,
            strObrdDt: this.searchDate.sDt,
            endObrdDt: this.searchDate.eDt,
            usdAmt: baseUsdTotal,
            krwAmt: baseTotal,
            appvMeth: '01',
            stsCd: '01',
            rmk: '확정',
            picNo: '',
            fstEntDtm: '',
            fstEntUno: '',
            lstUptDtm: '',
            lstUptUno: '',
            eiCatCd: this.asEiCatCd
      }

      this.saveData.info = { ...info }
      this.saveData.detailReqList = detailList

      // if (detailList.length < 1) {
      //   let msg = inxIsInvoiceExistList.join(',') + inxIsPublishExistList.join(',') + inxIsNotExistList.join(',') + '데이터 이상'

      //   this.$ekmtcCommon.alertCallback(msg, () => {
      //     return false
      //   })
      // } else {
      //   return true
      // }

      let msg = ''

      if (inxIsNotExistList.length > 0 || inxIsInvoiceExistList.length > 0 || inxIsPublishExistList.length > 0) {
        const notExistBlNoHeaderMsg = '* ' + this.$t('msg.SETT060G010.062') // 잘못된 B/L번호입니다.
        const publishExistBlNoHeaderMsg = '* ' + this.$t('msg.SETT060G010.063') // 해당B/L로 정산진행중인 건이 있습니다. 확인이 필요합니다.
        const invoiceExistBlNoHeaderMsg = '* ' + this.$t('msg.SETT060G010.064') // 해당B/L의 인보이스가 이미 발행되었습니다. 확인이 필요합니다.
        let notExistBlNoBodyMsg = ''
        let publishExistBlNoBodyMsg = ''
        let invoiceExistBlNoBodyMsg = ''

        if (inxIsNotExistList.length > 0) {
          let keyMsg = ''

          for (let i = 0; i < inxIsNotExistList.length; i++) {
            if (inxIsNotExistList[i] !== null && inxIsNotExistList[i] !== '') {
              if (keyMsg.length > 0) {
                keyMsg += ' / '
                notExistBlNoBodyMsg += ' / '
              }

              keyMsg += inxIsNotExistList[i]
              notExistBlNoBodyMsg += inxIsNotExistList[i]
            }
          }
        }

        if (notExistBlNoBodyMsg !== '') {
          msg += notExistBlNoHeaderMsg
          msg += '\n'
          msg += notExistBlNoBodyMsg
        }

        if (inxIsPublishExistList.length > 0) {
          let keyMsg = ''

          for (let i = 0; i < inxIsPublishExistList.length; i++) {
            if (inxIsPublishExistList[i] !== null && inxIsPublishExistList[i].blNo !== null && inxIsPublishExistList[i].blNo !== '' && inxIsPublishExistList[i].invIssNoList !== null && inxIsPublishExistList[i].refNoList.length > 0) {
              let valueMsg = ''

              if (keyMsg.length > 0) {
                keyMsg += ' / '
                publishExistBlNoBodyMsg += ' / '
              }

              keyMsg += inxIsPublishExistList[i].blNo
              publishExistBlNoBodyMsg += inxIsPublishExistList[i].blNo

              keyMsg += ' : '

              for (let j = 0; j < inxIsPublishExistList[i].refNoList.length; j++) {
                if (inxIsPublishExistList[i].refNoList[j] !== null && inxIsPublishExistList[i].refNoList[j] !== '') {
                  if (valueMsg.length > 0) {
                    valueMsg += ', '
                    publishExistBlNoBodyMsg += ', '
                  }

                  valueMsg += inxIsPublishExistList[i].refNoList[j]
                  publishExistBlNoBodyMsg += inxIsPublishExistList[i].refNoList[j]
                }
              }
            }
          }
        }

        if (publishExistBlNoBodyMsg !== '') {
          if (notExistBlNoBodyMsg !== '') {
            msg += '\n'
            msg += '\n'
          }

          msg += publishExistBlNoHeaderMsg
          msg += '\n'
          msg += publishExistBlNoBodyMsg
        }

        if (inxIsInvoiceExistList.length > 0) {
          let keyMsg = ''

          for (let i = 0; i < inxIsInvoiceExistList.length; i++) {
            if (inxIsInvoiceExistList[i] !== null && inxIsInvoiceExistList[i].blNo !== null && inxIsInvoiceExistList[i].blNo !== '' && inxIsInvoiceExistList[i].invIssNoList !== null && inxIsInvoiceExistList[i].invIssNoList.length > 0) {
              let valueMsg = ''

              if (keyMsg.length > 0) {
                keyMsg += ' / '
                invoiceExistBlNoBodyMsg += ' / '
              }

              keyMsg += inxIsInvoiceExistList[i].blNo
              invoiceExistBlNoBodyMsg += inxIsInvoiceExistList[i].blNo

              keyMsg += ' : '
              invoiceExistBlNoBodyMsg += ' : '

              for (let j = 0; j < inxIsInvoiceExistList[i].invIssNoList.length; j++) {
                if (inxIsInvoiceExistList[i].invIssNoList[j] !== null && inxIsInvoiceExistList[i].invIssNoList[j] !== '') {
                  if (valueMsg.length > 0) {
                    valueMsg += ', '
                    invoiceExistBlNoBodyMsg += ', '
                  }

                  valueMsg += inxIsInvoiceExistList[i].invIssNoList[j]
                  invoiceExistBlNoBodyMsg += inxIsInvoiceExistList[i].invIssNoList[j]
                }
              }
            }
          }
        }

        if (invoiceExistBlNoBodyMsg !== '') {
          if (notExistBlNoBodyMsg !== '' || publishExistBlNoBodyMsg !== '') {
            msg += '\n'
            msg += '\n'
          }

          msg += invoiceExistBlNoHeaderMsg
          msg += '\n'
          msg += invoiceExistBlNoBodyMsg
        }
      }

      if (detailList.length < 1) {
        if (msg !== '') {
          await this.$ekmtcCommon.alertCallback(msg, () => {
            return false
          })
        } else {
          return false
        }
      } else {
        if (msg !== '') {
          await this.$ekmtcCommon.alertCallback(msg, () => {
            return true
          })
        } else {
          return true
        }
      }
    },

    // 저장
    async dataSave () {
      this.refNo = ''
      if (this.comSettleCheck === 'Y') {
        this.saveData.info.stsCd = '02'
      }
      console.info(JSON.stringify(this.saveData))
      await CreditApi.dataAr301iSave(this.saveData)
      .then(response => {
        console.log(response)
        if (response.data) {
          if (this.$_.has(response.data, 'refNo')) {
            this.refNo = response.data.refNo
          }
        }
      })
    },

    // 인보이스 발행
    async dataInvoiceIssue () {
      let params = {}
      await CreditApi.dataInvoiceIssue(this.refNo, params)
        .then(invRes => {
          console.log(invRes)
          if (invRes.data) {
            //
          }
        })
    },

    // 확정 및 Invoice 발행
    async confirmationAndIssuance () {
      this.btnDisabled.id2 = true
      if (this.comSettleCheck === 'Y') {
        console.log('네 선택 후 정장')
        // '네' 선택 시
        let isValid = await this.matchDataCheckOk()
        if (isValid) {
          await this.dataSave()
          // await this.dataInvoiceIssue()
          await this.$emit('close', { nextComName: 'AccountBillHistPop', refNo: this.refNo })
        } else {
          this.$emit('close', { msgYn: 'N' })
        }
      } else {
        console.log('매치 후 저장')
        this.saveData.info.dclrYn = 'Y'
        await this.dataSave()
        // await this.dataInvoiceIssue()
        this.$emit('close', { nextComName: 'AccountBillHistPop', refNo: this.refNo })
      }
    },

    // 제출(확인 자료 제출)
    async submitVerificationMaterials () {
      this.btnDisabled.id1 = true
      this.matchDataCheck()
      await this.dataSave()
      console.log('자료를 제출 합니다.')
      this.$emit('close', { nextComName: 'CreditTransactionViewPop', refNo: this.refNo })
    },

    confirmCancel () {
      const msg = this.$t('msg.ADD20220219.048') // '작업 취소 전 현재까지 진행하던 작업을 제출하시겠습니까?'

      this.confirmAlert(msg, () => {
          console.log('제출 전 검증 처리')

          // 제출전 데이터 검증
          // let baseData = provider.getJsonRows(0, -1)
          let saveCheck = 0
          const userData = userProvider.getJsonRows(0, -1)
          const matchResult = matchProvider.getJsonRows(0, -1)
          userData.forEach(v => {
            console.log('v.blNo >> ', this.$_.trim(v.blNo).length)
            if (this.$_.trim(v.blNo).length !== 0) {
              saveCheck = 1
              return false
            }
          })
          matchResult.forEach(v => {
            if (v.diffAmt >= 0) {
              saveCheck = 2
              return false
            }
          })

          console.log('saveCheck > ', saveCheck)
          if (saveCheck === 1) {
            // 매칭후 저장 처리
            console.log('검증 매칭 전')
            this.$emit('close', { msgYn: 'N' })
          } else if (saveCheck === 2) {
            console.log('검증 성공')
            this.submitVerificationMaterials()
          } else {
            console.log('검증 실패')
            this.$emit('close', { msgYn: 'N' })
          }
        },
        () => {
          this.$emit('close', { msgYn: 'N' })
        })
    },

    confirmAlert (msg, onConfirm, onClose) {
      let obj = {
        alertType: 'simple',
        customCloseBtnText: this.$t('msg.SETT060G010.021'), // 아니오
        customConfirmBtnText: this.$t('msg.SETT060G010.020'), //예(Y)',
        useConfirmBtn: true,
        message: msg,
        onClose: onClose,
        onConfirm: onConfirm
      }
      this.$ekmtcCommon.alert(obj)
    },

    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },

    // 사용자 데이터 붙여넣기 시 데이터 정재하여 처리
    onPaste (e) {
      userGridView.commit()
      userGridView.cancelEditor()

      console.log('onPaste >> ', e)

      if (this.comSettleCheck === 'Y') {
        return
      }

      let pasteTxt = (e.clipboardData || window.clipboardData).getData('text')
      pasteTxt = this.$_.trim(pasteTxt)
      if (pasteTxt !== '') {
        const splitTxt = '@|@'
        // console.log('Clipboard Data >>', pasteTxt)
        const tabRe = new RegExp('\\t{1,}', 'gm')
        const removeRe = new RegExp('\\r', 'gi')
        pasteTxt = pasteTxt.replaceAll(removeRe, '')
        pasteTxt = pasteTxt.replaceAll(tabRe, splitTxt)

        const lineRe = new RegExp('\\n', 'gi')
        const textLine = pasteTxt.split(lineRe)
        // console.log('count > ', textLine)
        let blList = []

        // BL_NO \t AMOUNT 형식 Tab으로 분리
        textLine.forEach((txt, inx) => {
          txt = this.$_.trim(txt)

          const i = txt.indexOf(splitTxt)
          // console.log(`${inx} : ${txt} : ${i}`)

          if (i !== -1) {
            let blInfo = txt.split(splitTxt)
            console.log('excel > ', blInfo)

            if (blInfo.length === 2) {
              const reNumber = new RegExp('[^0-9]', 'gi')
              blInfo[1] = blInfo[1].replaceAll(reNumber, '')
              // console.log('---> money > ', this.$_.parseInt(blInfo[1]))
              const amt = this.$_.parseInt(blInfo[1])
              if (!isNaN(amt)) {
                const blObj = {
                    blNo: this.$_.toUpper(blInfo[0]),
                    totalAmt: this.$_.toNumber(blInfo[1]),
                    sort: 0,
                    isExist: 0
                  }
                blList.push(blObj)
              }
            }
          }
        })
        if (blList.length > 0) {
          this.userData = []
          this.userData = blList

          userProvider.setRows(this.userData)
          // return
        }
        // BL_NO만 붙여넣기
        // blList = []
        // textLine.forEach(txt => {
        //   const blRe = new RegExp('[\\s]+', 'gi')
        //   txt = this.$_.trim(txt)
        //   if (!blRe.test(txt)) {
        //     blList.push(txt)
        //   }
        // })
        // if (blList.length > 0) {
        //   this.userData = []
        //   blList.forEach(blNo => {
        //     const blObj = {
        //       blNo: this.$_.toUpper(blNo),
        //       totalAmt: 0,
        //       sort: 0,
        //       isExist: 0
        //     }
        //     this.userData.push(blObj)
        //   })

        //   userProvider.setRows(this.userData)
        //   return
        // }
        // 금액만 붙여넣기
        // let amtList = []
        // textLine.forEach(txt => {
        //   const replaceNumberRe = new RegExp('[,]', 'gi')
        //   const blRe = new RegExp('([0-9.-]+)', 'gi')
        //   txt = this.$_.trim(txt)
        //   txt = txt.replaceAll(replaceNumberRe, '')
        //   console.log(txt)
        //   if (blRe.test(txt)) {
        //     console.log('number > ', txt)
        //     amtList.push(txt)
        //   }
        // })
        // console.log('Amount > ', amtList)
        // if (amtList.length > 0) {
        //   let userData = userProvider.getJsonRows(0, -1)
        //   this.userData = userData.map((vo, inx) => {
        //     if (inx + 1 <= amtList.length) {
        //       vo.totalAmt = amtList[inx]
        //     }
        //     return {
        //       ...vo
        //     }
        //   })

        //   userProvider.setRows(this.userData)
        // }
      }
    },
    setGridTopItemIndex (grid, itemIndex) {
      let searchValues = [grid.getValue(itemIndex, 'blNo')]
      let searchFields = ['blNo']
      // let startSearchFieldIndex = searchFields.indexOf(grid.getCurrent().fieldName) + 1
      let startSearchFieldIndex = 0
      let options = {
        fields: searchFields,
        values: searchValues,
        startIndex: 0,
        startFieldIndex: startSearchFieldIndex,
        partialMatch: false
      }

      let searchIndex = provider.searchDataRow(options)
      let searchUserIndex = userProvider.searchDataRow(options)
      let searchMatchIndex = matchProvider.searchDataRow(options)

      if (searchIndex > -1) {
        gridView.setTopItem(searchIndex)
      }
      if (searchUserIndex > -1) {
        userGridView.setTopItem(searchUserIndex)
      }
      if (searchMatchIndex > -1) {
        matchGridView.setTopItem(searchMatchIndex)
      }
    }
  }
}
</script>
