<template>
  <div>
    <div class="content_box beforetab">
      <table class="tbl_search">
        <colgroup>
          <col width="180px"><col width="160px"><col><col><col><col width="190px">
        </colgroup>
        <tbody>
          <tr>
            <td>
              <input :disabled="etDmdCatCd !== 'M'" type="radio" id="tab02-ex1" name="tab02-exim" v-model="selectInout.selectedValue" :value="'O'" @change="changeCalcEiCatCd()">
              <label for="tab02-ex1"><span></span>{{ $t('msg.SETT010T010.001') }}</label>
              <!-- {{ selectInout.selectedValue }} -->
              <input :disabled="imDmdCatCd !== 'M'" type="radio" id="tab02-im1" name="tab02-exim" v-model="selectInout.selectedValue" :value="'I'" @change="changeCalcEiCatCd()">
              <label for="tab02-im1"><span></span>{{ $t('msg.SETT010T010.002') }}</label>
            </td>
            <td>{{ $t('msg.SETT010T010.155') }}</td>
            <td colspan="3">
              <e-date-range-picker-for-settle
                :term-type="dateTermType"
                :show-term-type="['01', '02', '03', '04']"
                @change="changeDateRange"
              />
            </td>
            <td class="text_right">
              <a class="button blue sh" @click="search">{{ $t('msg.MYIN020T010.014') }}</a> <!-- 검색 -->
              <b-button class="button sh" size="sm" variant="primary" @click="reSet">{{ $t('msg.CSCT060G040.013') }}</b-button> <!-- 초기화 -->

              <!-- <a class="button blue sh" @click="search">검색</a>
              <b-button class="button sh ml5" size="sm" variant="primary" @click="reSet">초기화</b-button> -->
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.CMBA380.021') }}</th> <!-- 구간 -->
            <td>
              <div class="tbl_form">
                <span class="dv wid45">
                  {{ $t('msg.ONEX010P040.003') }} <!-- 출발지 -->
                </span>
                <span class="dv">
                  <!-- <input type="text" placeholder="도착 항구 입력(영어)"> -->
                  <e-auto-complete-place
                    id="polPol"
                    :placeholder="$t('msg.ADD20220219.032')"
                    @change="changePol"
                    :ctr-cd="pol.ctrCd"
                    :plc-cd="pol.plcCd"
                  />
                </span>
              </div>
            </td>
            <td>
              <div class="tbl_form">
                <span class="dv wid80">
                  {{ $t('art.CMATK009') }} <!-- 도착지 -->
                </span>
                <span class="dv">
                  <!-- <input type="text" placeholder="항구 입력(영어)"> -->
                  <e-auto-complete-place
                    id="podPod"
                    :placeholder="$t('msg.ADD20220219.032')"
                    @change="changePod"
                    :ctr-cd="pod.ctrCd"
                    :plc-cd="pod.plcCd"
                  />
                </span>
              </div>
            </td>
            <td>
              <div class="tbl_form">
                <span class="dv wid80">
                  {{ $t('msg.CSBK100.011') }} <!-- 실화주 -->
                </span>
                <span class="dv">
                  <e-auto-complete-cst-enm
                    v-if="lang === 'KOR'"
                    :ctr-cd="usrCtrCd"
                    :cd-nm="actShprCstEnm"
                    class="auto-area position_relative"
                    id="actShprCstEnm"
                    @change="changeActShprCstENm"
                  />
                  <input
                    v-if="lang !== 'KOR'"
                    id="actShprCstEnm"
                    v-model="actShprCstEnm"
                    type="text"
                    class=""
                    maxlength="30"
                  >
                  <!-- <input type="text" v-model="actShprCstEnm" placeholder="실화주명 입력"> -->
                </span>
              </div>
            </td>
            <td colspan="2">
              <!-- <div class="tbl_form">
                <span class="dv wid70">
                  부킹담당자
                </span>
                <span class="dv">
                  <input type="text" v-model="bkgShprPicNm" placeholder="부킹담당자명 입력">
                </span>
              </div> -->
            </td>
          </tr>
          <!-- <tr>
            <th>운임</th>
            <td>
              <input type="radio" id="tab02-ex1" name="tab02-exim" v-model="selectInout.selectedValue" :value="'O'">
              <label for="tab02-ex1"><span></span>{{ $t('msg.SETT010T010.001') }}</label>

              <input type="radio" id="tab02-im1" name="tab02-exim" v-model="selectInout.selectedValue" :value="'I'">
              <label for="tab02-im1"><span></span>{{ $t('msg.SETT010T010.002') }}</label>
            </td>
            <td colspan="3">
              <div v-for="chk in checkFrtCds.codes" :key="chk.value" style="display: inline-block">
                <input type="checkbox" :id="chk.id" v-model="checkFrtCds.checkedList" :value="chk.value" @change="checkChange">
                <label :for="chk.id" class="mr10"><span></span>{{ chk.text }}</label>
              </div>
            </td>
          </tr> -->
        </tbody>
      </table>
      <!-- <div>
        <b-button-group size="sm">
          <b-button @click="test('02')">일주일</b-button>
          <b-button @click="test('03')">한달</b-button>
          <b-button @click="test('04')">월간</b-button>
        </b-button-group>
      </div> -->
    </div>

    <!-- Content Box -->
    <div class="content_box mt10">
      <div class="flex_box">
        <span class="tot_num">Total : <span class="num">{{ totalCount }}</span></span>
        <span v-if="relYn" class="input_box" >
          <select class="wid100" v-model="selPo">
            <option value="POR">Departure</option>
            <option value="DLY">Arrival</option>
          </select>
        </span>
        <span v-if="relYn" class="input_box" >
          <select class="wid200" v-model="selCtr" @change="checkSelectCtr()">
            <option value="">{{ $t('msg.ONEX010T010.004') }}<!-- 선택 --></option>
            <option v-for="ctrInfo in ctrCdList" :key="ctrInfo.ctrCd" :value="ctrInfo.ctrCd">{{ ctrInfo.ctrEnm }}</option>
          </select>
        </span>
        <span class="ml_auto cal_btn"><a class="button sm" href="#" @click="excelDownload">
          {{ $t('msg.CSCT060G040.005') }}</a></span>  <!-- 엑셀 다운로드 -->
      </div>
      <div id="credit-realgrid" style="width: 100%; height: 450px"></div>
    </div>

    <!-- 하단 버튼 그룹 -->
    <div class="flex_box mt10">
      <a class="button lg" href="#" @click="putCart">{{ $t('msg.SETT010T010.192') }}</a> <!-- 정산목록에 담기 -->

      <!-- <button
        type="button"
        class="button blue lg ml_auto" href="#"
        @click="testData({ nextComName: 'AccountBillHistPop', refNo: '202112150009' })"
      >
        거래처별 청구내역
      </button>

      <button
        type="button"
        class="button blue lg ml_auto" href="#"
        @click="dataInvoiceIssue('202112150001')"
      >
        인보이스발행
      </button> -->

      <button
        type="button"
        class="button blue lg ml_auto" href="#"
        @click="openPopup('CreditTransactionSettlePop')"
      >
        {{ $t('msg.SETT010T010.193') }}{{ selectData.length === 0 ? '' : `(${selectData.length})` }}
      </button> <!-- 정산목록확인 -->
    </div>

    <!-- 팝업  -->
    <win-layer-pop class="sample_popup_list">
      <component
        v-if="customComponent"
        :is="customComponent"
        :settle-data="selectData"
        :s-date="params.asFromDt"
        :e-date="params.asToDt"
        :ei-cat-cd="selectInout.selectedValue"
        :po-paramiters="poParamiters"
        @close="closePopup"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import JSZip from 'jszip'
import moment from 'moment'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import CreditApi from '@/api/rest/settle/credit'
import EDateRangePickerForSettle from '@/components/common/EDateRangePickerForSettle'
import CreditTransactionSettlePop from '@/pages/settle/popup/CreditTransactionSettlePop'
import CreditTransactionViewPop from '@/pages/settle/popup/CreditTransactionViewPop'
import AccountBillHistPop from '@/pages/settle/popup/AccountBillHistPop'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import { rootComputed } from '@/store/helpers'
import store from '@/store/index'
import commons from '@/api/services/commons'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'bascDt', dataType: 'text' },
  { fieldName: 'obrdDt', dataType: 'text' },
  { fieldName: 'vslNm', dataType: 'text' },
  { fieldName: 'voyNo', dataType: 'text' },
  { fieldName: 'cstVslVoy', dataType: 'text' },
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'bizNo', dataType: 'text' },
  { fieldName: 'cstNm', dataType: 'text' },
  { fieldName: 'cstNml', dataType: 'text' },
  { fieldName: 'porCtrCd', dataType: 'text' },
  { fieldName: 'porPlcCd', dataType: 'text' },
  { fieldName: 'porPlcNm', dataType: 'text' },
  { fieldName: 'dlyCtrCd', dataType: 'text' },
  { fieldName: 'dlyPlcCd', dataType: 'text' },
  { fieldName: 'dlyPlcNm', dataType: 'text' },
  { fieldName: 'cstPorPlcNm', dataType: 'text' },
  { fieldName: 'cstDlyPlcNm', dataType: 'text' },
  { fieldName: 'ofUsdAmt', dataType: 'number' },
  { fieldName: 'exrt', dataType: 'number' },
  { fieldName: 'ofLocAmt', dataType: 'number' },
  { fieldName: 'lssUsdAmt', dataType: 'number' },
  { fieldName: 'lssexrt', dataType: 'number' },
  { fieldName: 'lssLocAmt', dataType: 'number' },
  { fieldName: 'thcLocAmt', dataType: 'number' },
//  { fieldName: 'dcfLocAmt', dataType: 'number' },
  { fieldName: 'wfgLocAmt', dataType: 'number' },
  { fieldName: 'pfsLocAmt', dataType: 'number' },
  { fieldName: 'afsLocAmt', dataType: 'number' },
  { fieldName: 'othLocAmt', dataType: 'number' },
  { fieldName: 'totalAmt', dataType: 'number' }
]

export const columns = [
  {
    name: 'bascDt',
    fieldName: 'bascDt',
    type: 'data',
    width: '80',
    header: { text: 'Date' },
    footer: { text: app.$t('msg.ONEX010P050.004'), styleName: 'footer-title' }, // '합계금액'
    editable: false
  },
  {
    name: 'obrdDt',
    fieldName: 'obrdDt',
    width: '80',
    header: { text: 'On Board\nDate', styleName: 'multi-line-css' },
    footer: { text: app.$t('msg.ONEX010P050.004'), styleName: 'footer-title' }, // '합계금액'
    editable: false
  },
  {
    name: 'vslNm',
    fieldName: 'vslNm',
    type: 'data',
    width: '100',
    header: { text: 'VSL/VOY' },
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const vslNm = grid.getValue(idx, 'vslNm')
        const voyNo = grid.getValue(idx, 'voyNo')

        const txt = `${vslNm}<br/>${voyNo}`
        return txt
      }
    },
    editable: false
  },
  { name: 'voyNo', fieldName: 'voyNo', header: { text: 'voyNo' }, editable: false },
  {
    name: 'cstVslVoy',
    fieldName: 'cstVslVoy',
    type: 'data',
    width: '100',
    // styleName: 'rg-text-multi-line',
    header: { text: 'VSL/VOY' },
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const vslNm = grid.getValue(idx, 'vslNm')
        const voyNo = grid.getValue(idx, 'voyNo')

        const txt = `<div style="line-height: 1.0rem !important">${vslNm}<br/>${voyNo}</div>`
        return txt
      }
    },
    editable: false
  },
  {
    name: 'blNo',
    type: 'data',
    width: '100',
    fieldName: 'blNo',
    header: { text: 'B/L No.' },
    footer: { text: 'B/L Count', styleName: 'footer-title' },
    editable: false
  },
  { name: 'bizNo', fieldName: 'bizNo', type: 'data', header: { text: 'bizNo' }, editable: false },
  {
    name: 'cstNm',
    fieldName: 'cstNm',
    type: 'data',
    width: '150',
    header: {
      text: 'Shipper\n/Consignee',
      styleName: 'multi-line-css'
    },
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const txt = `<span alt="${cell.value}" title="${cell.value}" >${cell.value}</span>`
        return txt
      }
    }
  },
  {
    name: 'cstNml',
    fieldName: 'cstNml',
    type: 'data',
    width: '150',
    header: {
      text: 'Shipper\n/Consignee',
      styleName: 'multi-line-css'
    },
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const txt = `<span alt="${cell.value}" title="${cell.value}" >${cell.value}</span>`
        return txt
      }
    }
  },
  {
    name: 'porPlcCd',
    fieldName: 'porPlcCd',
    type: 'data',
    width: '80',
    header: { text: 'POR' },
    footer: { expression: 'count', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'porPlcNm',
    fieldName: 'porPlcNm',
    type: 'data',
    width: '100',
    header: { text: 'POR' },
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const porPlcNm = grid.getValue(idx, 'porPlcNm')
        const porCtrCd = grid.getValue(idx, 'porCtrCd')

        const txt = `${porPlcNm}, ${porCtrCd}`
        return txt
      }
    },
    footer: { expression: 'count', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'dlyPlcCd',
    fieldName: 'dlyPlcCd',
    type: 'data',
    width: '80',
    header: { text: 'DLY' },
    editable: false
  },
  {
    name: 'dlyPlcNm',
    fieldName: 'dlyPlcNm',
    type: 'data',
    width: '100',
    header: { text: 'DLY' },
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const dlyPlcNm = grid.getValue(idx, 'dlyPlcNm')
        const dlyCtrCd = grid.getValue(idx, 'dlyCtrCd')

        const txt = `${dlyPlcNm}, ${dlyCtrCd}`
        return txt
      }
    },
    editable: false
  },
  {
    name: 'cstPorPlcNm',
    fieldName: 'cstPorPlcNm',
    type: 'data',
    width: '90',
    styleName: 'rg-text-align-left',
    header: { text: 'POR' },
    footer: { expression: 'count', numberFormat: '#,##0', styleName: 'total-amt' },
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const txt = `<span alt="${cell.value}" title="${cell.value}" >${cell.value}</span>`
        return txt
      }
    },
    editable: false
  },
  {
    name: 'cstDlyPlcNm',
    fieldName: 'cstDlyPlcNm',
    type: 'data',
    width: '90',
    styleName: 'rg-text-align-left',
    header: { text: 'DLY' },
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const txt = `<span alt="${cell.value}" title="${cell.value}" >${cell.value}</span>`
        return txt
      }
    },
    editable: false
  },
  {
    name: 'ofUsdAmt',
    fieldName: 'ofUsdAmt',
    type: 'data',
    width: '80',
    numberFormat: '#,##0.00',
    styleName: 'num-column',
    header: { text: 'O/F\n(USD)', styleName: 'multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0.00', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'exrt',
    fieldName: 'exrt',
    type: 'data',
    width: '80',
    numberFormat: '###0.0',
    styleName: 'num-column',
    header: { text: 'EX.\nRATE', styleName: 'multi-line-css' },
    editable: false
  },
  {
    name: 'ofLocAmt',
    fieldName: 'ofLocAmt',
    type: 'data',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'O/F\n(KRW)', styleName: 'multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'lssUsdAmt',
    fieldName: 'lssUsdAmt',
    type: 'data',
    width: '80',
    numberFormat: '#,##0.00',
    styleName: 'num-column',
    header: { text: 'LSS\n(USD)', styleName: 'multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0.00', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'lssexrt',
    fieldName: 'lssexrt',
    type: 'data',
    width: '80',
    numberFormat: '###0.0',
    styleName: 'num-column',
    header: { text: 'EX.\nRATE', styleName: 'multi-line-css' },
    editable: false
  },
  {
    name: 'lssLocAmt',
    fieldName: 'lssLocAmt',
    type: 'data',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'LSS\n(KRW)', styleName: 'multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'thcLocAmt',
    fieldName: 'thcLocAmt',
    type: 'data',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'THC' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  /*
  {
    name: 'dcfLocAmt',
    fieldName: 'dcfLocAmt',
    type: 'data',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'DCF' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  */
  {
    name: 'wfgLocAmt',
    fieldName: 'wfgLocAmt',
    type: 'data',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'WFG' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'pfsLocAmt',
    fieldName: 'pfsLocAmt',
    type: 'data',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'PFS' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'afsLocAmt',
    fieldName: 'afsLocAmt',
    type: 'data',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'AFA' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'othLocAmt',
    fieldName: 'othLocAmt',
    type: 'data',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'OTH' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'totalAmt',
    fieldName: 'totalAmt',
    type: 'data',
    width: '100',
    numberFormat: '#,##0',
    styleName: 'total-amt',
    header: { text: 'TOTAL\nAmount', styleName: 'multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  }
]

let layoutFooter = [
  {
    name: 'group0',
    header: { visible: false },
    direction: 'horizontal',
    items: [
      { column: 'obrdDt', footerUserSpans: [{ rowspan: 1, colspan: 2 }] },
      'cstVslVoy'
    ]
  },
  {
    name: 'group1',
    header: { visible: false },
    direction: 'horizontal',
    items: [
      { column: 'blNo', footerUserSpans: [{ rowspan: 1, colspan: 2 }] },
      'cstNm'
    ]
  },
  'cstPorPlcNm',
  'cstDlyPlcNm',
  'ofUsdAmt',
  'exrt',
  'ofLocAmt',
  'lssUsdAmt',
  'lssexrt',
  'lssLocAmt',
  'thcLocAmt',
//  'dcfLocAmt',
  'wfgLocAmt',
  'pfsLocAmt',
  'afsLocAmt',
  'othLocAmt',
  'totalAmt'
]

export default {
  name: 'CreditTransactionInqList',
  components: {
    WinLayerPop,
    EDateRangePickerForSettle,
    EAutoCompletePlace,
    CreditTransactionSettlePop,
    CreditTransactionViewPop,
    AccountBillHistPop,
    'e-auto-complete-cst-enm': () => import('@/components/common/EAutoCompleteCstEnmV2')
 },
  data () {
    return {
      parentInfo: {},
      customComponent: null,
      poParamiters: undefined,
      actShprCstEnm: '', //실화주
      bkgShprPicNm: '', //booking담당자
      usrCtrCd: 'KR',
      lang: 'KOR',
      params: {
        asCstNo: 'KMT001',
        asSalUno: '%',
        asFromDt: '20210101',
        asToDt: '20211030',
        asInout: 'O',
        asCstNm: 'KMTC AIR-SEA SERVICE LTD.',
        asFrtCd1: 'O/F',
        asFrtCd2: 'THC',
        asFrtCd3: 'WFG',
        asFrtCd4: 'CTX',
        asFrtCd5: 'OTH',
        asFrtCd6: 'PFS',
        asFrtCd7: 'LSS',
        asPorCtrCd: '%',
        asPorPlcCd: '%',
        asPolCtrCd: '%',
        asPolPortCd: '%',
        asPodCtrCd: '%',
        asPodPortCd: '%',
        asDlyCtrCd: '%',
        asDlyPlcCd: '%',
        asCurCd: '%',
        asOrgCatCd: '%',
        asActShprCstNo: '%',
        asActShprCstEnm: '%', // 실화주
        asBkgShprPicNm: '%' //booking담당자
      },
      selectInout: {
        options: [
          { value: 'I', text: '수입' },
          { value: 'O', text: '수출' }
        ],
        selectedValue: 'O'
      },
      checkFrtCds: {
        codes: [
          { id: 'frt-cd-of', value: 'O/F', text: 'O/F' },
          { id: 'frt-cd-thc', value: 'THC', text: 'THC' },
          { id: 'frt-cd-wfg', value: 'WFG', text: 'WFG' },
          { id: 'frt-cd-ctx', value: 'CTX', text: 'CTX' },
          { id: 'frt-cd-oth', value: 'OTH', text: 'OTH' },
          { id: 'frt-cd-pfs', value: 'PFS', text: 'PFS' },
          { id: 'frt-cd-pfs', value: 'LSS', text: 'LSS' }
        ],
        checkedList: ['O/F', 'THC', 'WFG', 'CTX', 'OTH', 'PFS', 'LSS']
      },
      dataList: [],
      selectData: [],
      totalCount: 0,
      templateStr: '',
      userInfo: [],
      dateTermType: '02', //달력기간옵션(01:직접입력, 02:일주일, 03:한달, 04:월간)
      pol: {
        ctrCd: '',
        ctrEnm: '',
        plcCd: '',
        plcEnm: '',
        plcNm: ''
      },
      pod: {
        ctrCd: '',
        ctrEnm: '',
        plcCd: '',
        plcEnm: '',
        plcNm: ''
      },
      imDmdCatCd: '',
      etDmdCatCd: '',
      userDmdCatCd: '',
      ctrCdList: [],
      selCtr: '',
      selPo: 'DLY',
      relYn: true //process.env.VUE_APP_MODE !== 'PRD' // JamSin
    }
  },
  computed: {
    ...rootComputed,
    selectedProfile () {
      return this.$store.getters['auth/selectedProfile']
    },
    calcEiCatCd () {
      return this.$store.getters['auth/getCalcEiCatCd']
    }
  },
  watch: {
    '$store.state.settle.userDmdCatCd' () {
      this.userDmdCatCd = this.$store.state.settle.userDmdCatCd
      this.imDmdCatCd = this.$store.state.settle.imDmdCatCd
      this.etDmdCatCd = this.$store.state.settle.etDmdCatCd
    },
    calcEiCatCd () {
      if (this.imDmdCatCd === 'M' && this.etDmdCatCd === 'M') {
        this.selectInout.selectedValue = this.calcEiCatCd
      }
    }
  },
  created () {
    this.init()
  },
  mounted: function () {
    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip

    provider = new LocalDataProvider(true)
    gridView = new GridView('credit-realgrid')

    provider.setFields(fields)

    // 그리고 헤더 높이 조정
    gridView.header.heights = 50
    gridView.footer.height = 32
    gridView.displayOptions.rowHeight = 40
    gridView.defaultColumnWidth = 60
    gridView.displayOptions.fitStyle = 'even'
    gridView.displayOptions.selectionStyle = 'block'

    gridView.setCopyOptions({
      enabled: true
    })
    gridView.setStateBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })

    gridView.setColumns(columns)
    gridView.setDataSource(provider)
    gridView.setColumnLayout(layoutFooter)

    gridView.setPaging(false, 10)

    gridView.setFixedOptions({
      colCount: 4,
      rightCount: 1
    })
  },
  methods: {
    test (tp) {
      console.log('달력변경 요청')
      this.dateTermType = tp
    },
    checkChange () {
      console.log(`Checked Value : ${this.checkFrtCds.checkedList}`)
    },

    init () {
      // 달력기간옵션 (01:직접입력, 02:일주일, 03:한달, 04:월간)
      this.dateTermType = '02'

      // 수출,수입에 따라 화주구분
      // 화주 구분값(etDmdCatCd: 수출, imDmdCatCd: 수입) dmdCatCd: 'R', //화주구분 : 월말화주(M), 청구화주(C), 영수화주(R)
      this.userDmdCatCd = this.$store.state.settle.userDmdCatCd
      this.imDmdCatCd = this.$store.state.settle.imDmdCatCd
      this.etDmdCatCd = this.$store.state.settle.etDmdCatCd

      if (this.imDmdCatCd === 'M' && this.etDmdCatCd !== 'M') {
        this.selectInout.selectedValue = 'I'
      } else if (this.etDmdCatCd === 'M' && this.imDmdCatCd !== 'M') {
        this.selectInout.selectedValue = 'O'
      } else if (this.imDmdCatCd === 'M' && this.imDmdCatCd === 'M') {
        // 프로필에서 설정한 수출/수입 값으로 세팅
        this.initEiCatCd()
      }

      commons.getCommonsCodesCountry().then(res => {
        this.ctrCdList = res
      })
    },
    initEiCatCd () {
      if (this.calcEiCatCd !== '') {
        this.selectInout.selectedValue = this.calcEiCatCd
      } else {
        if (this.selectedProfile.eiCatCd === 'A') {
          // 프로필 값이 수출/수입이면 '수출'
          this.selectInout.selectedValue = 'O'
        } else {
          // 프로필에서 설정한 수출/수입 값으로 세팅
          this.selectInout.selectedValue = this.selectedProfile.eiCatCd
        }
      }
    },
    changeDateRange (s, d) {
      console.log(`## 검색기간 : ${s} ~ ${d}`)
      this.params.asFromDt = s
      this.params.asToDt = d
    },

    reSet () {
      this.searchInit()
      this.setInit()
    },

    //초기화
    searchInit () {
      this.dataList = []
      provider.setRows(this.dataList)
      this.totalCount = 0
    },

    search () {
      this.searchInit()
      this.searchData()
    },
    // 신용거래건 조회
    async searchData () {
      this.params.asCstNo = this.memberDetail.cstCd
      this.params.asCstNm = ''

      // 수입수출
      this.params.asInout = this.selectInout.selectedValue

      // 출발지 항구 정보
      if (this.pol.ctrCd !== '') {
        this.params.asPolCtrCd = this.pol.ctrCd
        this.params.asPolPortCd = this.pol.plcCd
      } else {
        this.params.asPolCtrCd = '%'
        this.params.asPolPortCd = '%'
      }

      // 도착지항구
      if (this.pod.ctrCd !== '') {
        this.params.asPodCtrCd = this.pod.ctrCd
        this.params.asPodPortCd = this.pod.plcCd
      } else {
        this.params.asPodCtrCd = '%'
        this.params.asPodPortCd = '%'
      }

      if (this.$_.trim(this.params.asActShprCstNo) === '') {
        this.params.asActShprCstNo = '%'
      }

      if (this.$_.trim(this.params.asActShprCstEnm) === '') {
        this.params.asActShprCstEnm = '%'
      }

      let tmpStr = '' + this.bkgShprPicNm
      if (tmpStr.trim().length !== 0) {
        this.params.asBkgShprPicNm = tmpStr
      } else {
        this.params.asBkgShprPicNm = '%'
      }

      // 2022.02.28 지불대리점코드 쿼리 조건 수정, bkgPlcCd 추가
      if (this.$ekmtcCommon.isNotEmpty(this.memberDetail.bkgPlcCd)) {
        this.params.bkgPlcCd = this.memberDetail.bkgPlcCd
      }
      console.log(JSON.stringify(this.params))
      console.log('# Parameter : ', this.params)

      await CreditApi.getCreditTransactionInqSumList(this.params)
      .then(response => {
        console.log(response)
        if (response.data) {
          this.dataList = response.data.map(vo => {
            vo.obrdDt = moment(vo.obrdDt, 'YYYYMMDD').format('YYYY.MM.DD')
            const cstPorPlcNm = `${vo.porPlcNm}, ${vo.porCtrCd}`
            const cstDlyPlcNm = `${vo.dlyPlcNm}, ${vo.dlyCtrCd}`
            const cstVslVoy = `${vo.vslNm} ${vo.voyNo}`
            return {
              ...vo, cstVslVoy: cstVslVoy, cstPorPlcNm: cstPorPlcNm, cstDlyPlcNm: cstDlyPlcNm
            }
          })

          provider.setRows(this.dataList)
          this.totalCount = this.dataList.length
          gridView.setAllCheck(false)

          if (this.totalCount === 0) {
            this.openAlert(this.$t('msg.SETT010T010.129')) // '검색된 데이터가 없습니다.')
          }
        }
      })
    },

    excelDownload () {
      const curDt = moment().format('YYYYMMDD')
      gridView.exportGrid({
        type: 'excel',
        target: 'local',
        documentTitle: { //제목
          message: this.$t('menu.MENU06.030'), //'신용거래건 정리',
          visible: true,
          spaceTop: 1,
          spaceBottom: 1,
          height: 60,
          styleName: 'excel-export-title-style'
        },
        fileName: this.$t('menu.MENU06.030') + `_${curDt}.xlsx`,
        showProgress: false,
        allColumns: true,
        allItems: true,
        progressMessage: this.$t('msg.ADD20220219.012'), // 엑셀 Export중입니다.
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          console.log('엑셀 다운로드 완료')
        }
      })
    },

    setInit () {
      this.selectData = []
    },

    putCart () {
      let rows = gridView.getCheckedRows(true)
      if (rows.length === 0) {
        this.openAlert(this.$t('msg.ADD20220219.033')) //'정산목록확인 대상에 추가할 B/L을 선택해주세요.'
        return
      }
      rows.forEach(inx => {
        console.log(inx)
        const tmp = provider.getJsonRows(inx, inx)
        if (tmp.length > 0) {
          // 중복제거 로직
          const tindex = this.selectData.findIndex(s => tmp[0].blNo === s.blNo)
          if (tindex === -1) {
            this.selectData.push(tmp[0])
          }
        }
      })
      console.log(this.selectData)
    },

    testData (obj) {
      if (this.$_.has(obj, 'nextComName')) {
        this.poParamiters = { ...obj }
        this.customComponent = obj.nextComName
        this.$ekmtcCommon.layerOpen('.sample_popup_list')
      }
    },

    dataInvoiceIssue (refNo) {
      let params = {}
      CreditApi.dataInvoiceIssue(refNo, params)
        .then(invRes => {
          console.log(invRes)
          if (invRes.data) {
            //
          }
        })
    },

    openPopup (compNm) {
      if (this.selectData.length === 0) {
        this.openAlert(this.$t('msg.ADD20220219.033')) //'정산목록확인 대상에 추가할 B/L을 선택해주세요.'
        return
      }
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose()
      }
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.sample_popup_list')
    },

    closePopup (obj) {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.sample_popup_list')

      if (this.$_.has(obj, 'nextComName')) {
        this.poParamiters = { ...obj }
        this.openPopup(obj.nextComName)
      } else if (this.$_.has(obj, 'msgYn')) {
        this.setInit()
        this.searchData()
        if (obj.msgYn === 'Y') {
          this.openAlert(this.$t('msg.ADD20220219.034')) // '제출 완료되었습니다. 고려해운 정산 담당자가 확인 후 처리드리겠습니다. 진행현황 및 제출 내역은 [내역조회] 메뉴에서 확인가능합니다'
        }
      } else {
        this.setInit()
        this.searchData()
      }
    },

    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },

    changePol (vo) {
      console.log('Change Pol >', vo)
      this.pol = vo
      this.params.asPorCtrCd = this.pol.ctrCd
      this.params.asPorPlcCd = this.pol.plcCd

      // const frm = document.querySelector('#frm')
      // const elem = frm.querySelector('#polPol')
      // this.$ekmtcCommon.hideErrorTooltip(elem)
    },

    changePod (vo) {
      console.log('Change Pod >', vo)
      this.pod = vo

      this.params.asDlyCtrCd = this.pod.ctrCd
      this.params.asDlyPlcCd = this.pod.plcCd
      // const frm = document.querySelector('#frm')
      // const elem = frm.querySelector('#polPol')
      // this.$ekmtcCommon.hideErrorTooltip(elem)
    },

    changeActShprCstENm (vo) {
      console.log('실화주 자동 완성 >', vo)
      this.params.asActShprCstNo = vo.cd
      this.params.asActShprCstEnm = vo.cdNm
    },
    changeCalcEiCatCd () {
      if (this.imDmdCatCd === 'M' && this.etDmdCatCd === 'M') {
        this.$store.commit('auth/updateCalcEiCatCd', this.selectInout.selectedValue)
      }
    },
    checkSelectCtr () {
      if (this.selCtr !== '') {
        if (this.selPo === 'POR') {
          this.gridList = this.dataList.filter(vo => vo.porCtrCd === this.selCtr)
        } else if (this.selPo === 'DLY') {
          this.gridList = this.dataList.filter(vo => vo.dlyCtrCd === this.selCtr)
        }
        this.totalCount = this.gridList.length
        provider.setRows(this.gridList)
      } else {
        this.totalCount = this.dataList.length
        provider.setRows(this.dataList)
      }
    }
  }
}
</script>

<style>
  #credit-realgrid .rg-table input[type="radio"],
  #credit-realgrid .rg-table input[type="checkbox"] {
    padding: 0;
    margin: 0;
    line-height: 1.5rem !important;
  }
  #credit-realgrid .rg-root .rg-table,
  #credit-realgrid .rg-root .rg-table td {
    line-height: 1.5rem;
  }
  #credit-realgrid .multi-line-css {
    white-space: pre !important;
    text-align: center;
  }
  #credit-realgrid .num-column {
    text-align: right !important;
  }
  #credit-realgrid .total-amt {
    text-align: right !important;
    font-weight: 700;
  }
  #credit-realgrid .footer-title {
    text-align: center !important;
    font-weight: 700;
  }
</style>
