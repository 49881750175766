import Send from '@/api/settle.client.js'

const baseUrl = ''

export default {
  getCreditTransactionInqList (formData) {
    return new Promise((resolve, reject) => {
      Send({
            url: '/settle/credit/transactions',
            method: 'POST',
            data: formData
        }).then(res => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
  },
  getCreditTransactionInqSumList (formData) {
    return new Promise((resolve, reject) => {
      Send({
            url: '/settle/credit/list',
            method: 'POST',
            data: formData
        }).then(res => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
  },
  //AR_월말청구화주 대사 저장
  dataAr301iSave (formData) {
    return new Promise((resolve, reject) => {
      Send({
            url: '/settle/arrange',
            method: 'POST',
            data: formData
        }).then(res => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
  },
  //AR_INVOICE 발행
  dataInvoiceIssue (refNo, params) {
    return new Promise((resolve, reject) => {
      Send({
            url: `/settle/invoice/issue/${refNo}`,
            method: 'GET',
            params: params
        }).then(res => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
  },
  getArrangeList (refNo) {
    return new Promise((resolve, reject) => {
      Send({
            url: `/settle/arrange/${refNo}`,
            method: 'GET'
        }).then(res => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
  },
  getArrangeIsBlExist (data) {
    return new Promise((resolve, reject) => {
      Send({
            url: '/settle/arrange/is-bl-exist',
            method: 'POST',
            data: data
        }).then(res => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
  },
  getManagerInfo (eiCatCd) {
    return new Promise((resolve, reject) => {
      Send({
          url: '/settle/manager',
          method: 'GET',
          params: {
            eiCatCd: eiCatCd
          }
        }).then(res => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
  },
  getCompanyInfo () {
    return new Promise((resolve, reject) => {
      Send({
          url: '/settle/comp-info',
          method: 'GET',
          params: {}
        }).then(res => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
  }
}
