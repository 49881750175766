<template>
  <div class="content">
    <e-breadcrumbs />
    <h1 class="page_title">{{$t('menu.MENU05.000')}}</h1><!-- 운임 -->
    <e-tab ref="tab">
      <e-tab-item
        :title="$t('menu.MENU05.010')"
        name="inquiry"
        path="/shipping-cost"
        :selected="true"
      ><!-- 운임문의 -->
        <shipping-cost-inquiry v-if="routeName === '' || routeName === 'inquiry'"></shipping-cost-inquiry>
      </e-tab-item>
      <e-tab-item
        :title="$t('menu.MENU05.020')"
        name="my"
        path="/shipping-cost"
      ><!-- 나의 운임 -->
        <shipping-cost-my v-if="routeName === 'my'"></shipping-cost-my>
      </e-tab-item>
    </e-tab>
  </div>
</template>

<script>

import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import ETabItem from '@/components/common/ETabItem'
import ETab from '@/components/common/ETab'

import ShippingCostInquiry from '@/pages/schedule/ShippingCostInquiry'
import ShippingCostMy from '@/pages/schedule/ShippingCostMy'

export default {
  name: 'ShippingCostMain',
  components: {
    EBreadcrumbs,
    ETabItem,
    ETab,
    ShippingCostInquiry,
    ShippingCostMy
  },
  data () {
    return {
      routeName: ''
    }
  },
  watch: {
    $route (to, from) {
      this.routeName = to.name
      this.$refs.tab.selectTab({ name: this.$route.name })
    }
  },
  created () {
    this.routeName = this.$route.name || ''
  },
  mounted () {
    this.$refs.tab.selectTab({ name: this.$route.name })
  },
  methods: {
  }
}

</script>
