var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "1200px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close", { msgYn: "Y" })
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [_vm._v("거래처별 청구명세")]),
        _c("div", { staticClass: "content_box" }, [
          _c("p", [
            _vm._v(
              " Invoice No : " +
                _vm._s(_vm.info.invNo) +
                " / 기간 : " +
                _vm._s(_vm.info.strObrdDt) +
                "~" +
                _vm._s(_vm.info.endObrdDt) +
                " / 담당자 : " +
                _vm._s(_vm.managerInfo.usrNm) +
                " / 연락처 : (2)211-" +
                _vm._s(_vm.managerInfo.coTelNo)
            ),
            _c("br"),
            _vm._v(
              " 사업자등록번호 : " +
                _vm._s(_vm.companyInfo.bzrgNo) +
                " " +
                _vm._s(_vm.companyInfo.cstEnm) +
                " "
            ),
          ]),
          _c("div", {
            staticStyle: { width: "100%", height: "450px" },
            attrs: { id: `${_vm.compId}-account-bill-realgrid` },
          }),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.openReport.apply(null, arguments)
                },
              },
            },
            [_vm._v("PDF 다운로드")]
          ),
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.excelDownload.apply(null, arguments)
                },
              },
            },
            [_vm._v("엑셀 다운로드")]
          ),
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.fnAction("TAX")
                },
              },
            },
            [_vm._v("세금계산서 발행 요청")]
          ),
        ]),
      ]),
      _c(
        "div",
        { attrs: { id: `${_vm.compId}_credit_billing_popup` } },
        [
          _c(
            "win-layer-pop",
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: {
                      "ok-hidden": _vm.isHidden,
                      "is-show-pop": _vm.isShowPop,
                      "parent-info": _vm.parentInfo,
                    },
                    on: { close: _vm.commonClosePopup },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("common-unsolved", {
        ref: "commonUnsolved",
        on: { callback: _vm.searchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }