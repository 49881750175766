<template>
  <div class="popup_wrap popup_wrap_pdf" style="width:1200px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">거래처별 청구명세</h1>
      <div class="content_box">
        <div class="clearfix">
          <div class="float_left col_2">
            <table class="tbl_list font_sm gridtype">
              <colgroup>
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>Ref. No</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ refNo }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="float_left text_center col_8 pl20" style="max-height: 500px; overflow-y: auto;">
            <vue-pdf-app
              style="height: 600px;"
              :pdf="pdf.pdfUrl"
              :config="pdfConfig.config"
              :id-config="pdfConfig.idConfig"
              theme="light"
            ></vue-pdf-app>
            <div class="paging print">
              <span class="num">
                <input type="number" :id="pdfConfig.idConfig.pageNumber" class="pageNumber" maxlength="2">
                <span :id="pdfConfig.idConfig.numPages" class="pageNumber">-</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="fnDownlaod();">{{ $t('msg.REPORT_PDF.101') }}<!-- 다운 --></a>
        <a class="button blue lg" :id="pdfConfig.idConfig.print">{{ $t('msg.REPORT_PDF.102') }}<!-- 인쇄 --></a>
        <a class="button blue lg" href="#" @click.prevent="openSendEmailPopup();">{{ $t('msg.REPORT_PDF.103') }}<!-- 공유 --></a>
      </div>
    </div>

    <win-layer-pop class="pdf_send_email">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closeSendEmailPopup"
        :parent-info="sendEmailParam"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commonPdf from '@/plugins/commonPdf'
import reportInfo from '@/api/rest/trans/reportInfo'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  name: 'ProformaFreightPop',
  components: {
    vuePdfApp: () => import('vue-pdf-app'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    PdfSendEmailPop: () => import('@/pages/pdf/popup/PdfSendEmailPop')
  },
  //상위 컴포넌트의 데이터를 전달하귀 위한 용도로 사용된다.
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      customComponent: null,
      sendEmailParam: {},
      userInfo: {},
      items: [],
      refNo: '',
      pdf: {
        pdfUrl: '',
        emailUrl: '',
        emailFiles: [],
        pg: 1,
        pageCount: 0,
        currentPage: 0
      },
      pdfConfig: {
        config: {},
        idConfig: {}
      }
    }
  },
  //간단한 연산을 하는데 편리(캐싱된다.) - 복잡한 곳에 적합
  computed: {
    ...rootComputed
  },
  //실시간적으로 변하는 곳에 사용
  watch: {
    parentInfo () {
      if (this.$props.parentInfo.items !== undefined) {
        this.setItems(this.$props.parentInfo)
      }
    }
  },
  //데이터 초기화 목적으로 사용 - 가상 돔 접근 불가능
  //데이터 초기에 돔조작을 목적으로 사용 - mounted - 가상 돔 접근 가능
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.pdfConfig = commonPdf.getPdfConfig()

      // 로그인 여부 체크
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
      }

      await reportInfo.getReportInfoUser('')
        .then(async res => {
          this.userInfo = res.data
        })

      if (this.$props.parentInfo.refNo !== undefined) {
        this.setItems(this.$props.parentInfo)
      }

      commonPdf.commonInit()
    },
    async setItems (vo) {
      const cstCd = this.memberDetail.cstCd
      const ctrCd = this.memberDetail.userCtrCd
      this.refNo = vo.refNo
      const eiCatCd = vo.eiCatCd

      const logInfo = {
          logUserId: this.auth.userId,
          logCtrCd: this.memberDetail.userCtrCd,
          logPlcCd: this.memberDetail.bkgPlcCd
      }

      this.pdf = await commonPdf.setCreditPrint(this.refNo, cstCd, ctrCd, eiCatCd, logInfo)
    },
    fnDownlaod () {
      commonPdf.download(this.pdf.pdfUrl, this.refNo + '.pdf')
    },
    openSendEmailPopup () {
      this.sendEmailParam = {
        emailUrl: this.pdf.emailUrl,
        emailFiles: this.pdf.emailFiles,
        type: 'AN'
      }
      this.customComponent = 'PdfSendEmailPop'
      commonPdf.layerOpen('.pdf_send_email')
    },
    closeSendEmailPopup () {
      this.customComponent = null
      commonPdf.layerClose('.pdf_send_email')
    }
  }
}
</script>

<style>
body {
    min-width:100% !important;
}

</style>
