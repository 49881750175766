var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "1200px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close", { msgYn: "Y" })
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v("정산목록확인 (Ref. No " + _vm._s(_vm.refNo) + ")"),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("div", { staticClass: "tbl_form" }, [
            _c("div", { staticClass: "dv col_4" }, [
              _c("div", {
                staticStyle: { width: "100%", height: "450px" },
                attrs: { id: `${_vm.compId}-settle-realgrid` },
              }),
            ]),
            _vm._m(0),
            _c("div", { staticClass: "dv col_2" }, [
              _c("div", {
                staticStyle: { width: "100%", height: "450px" },
                attrs: { id: `${_vm.compId}-user-realgrid` },
              }),
            ]),
            _vm._m(1),
            _c("div", { staticClass: "dv col_3" }, [
              _c("div", {
                staticStyle: { width: "100%", height: "450px" },
                attrs: { id: `${_vm.compId}-match-realgrid` },
              }),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "mt10 text_right" },
          [
            _c(
              "b-button",
              {
                staticClass: "ml-2",
                attrs: { variant: "secondary" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close", { msgYn: "Y" })
                  },
                },
              },
              [_vm._v(" 닫기 ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "dv text_center valign_middle wid100 pr5" },
      [_c("button", { staticClass: "tbl_icon arrow" }, [_vm._v("arrow")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "dv text_center valign_middle wid100 pr5" },
      [_c("button", { staticClass: "tbl_icon arrow" }, [_vm._v("arrow")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }