<template> <!-- 특수화물 선적문의 >> RF 위험물 사전승인 -->
  <div class="content resize">
    <e-breadcrumbs />
    <div id="layerPop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          :detail="detail"
          :detail-port="detailPort"
          :ok-hidden="isHidden"
          @close="closePoup"
        />
      </win-layer-pop>
    </div>
    <h1 class="page_title">{{ $t('menu.MENU04.030') }}</h1>
    <form id="frm">
      <div class="content_box pb15">
        <!-- content_box -->
        <table class="tbl_search">
          <colgroup>
            <col width="70px">
            <col width="110px">
            <col>
            <col width="70px">
            <col width="110px">
            <col>
            <col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.SPEC030.001') }}</th>
              <td class="pr0">
                <select class="wid100" style="width:100px" v-model="param.asDtKnd" @change="optionChange()">
                  <option value="RQ">Request No.</option>
                  <option value="DT">Request Date</option>
                </select>
              </td>
              <td class="pl2" colspan="4">
                <div v-if="param.asDtKnd === 'RQ'">
                  <span class="form_tit"></span>
                  <input type="text" style="width:200px" v-model.trim="param.asReqNo1">
                  <input type="hidden" style="width:100px" v-model.trim="param.asReqNo2">
                  <input type="hidden" style="width:100px" v-model.trim="param.asReqNo3">
                </div>
                <div v-if="param.asDtKnd === 'DT'">
                  <e-date-range-picker
                    :id="'fromDtToDt'"
                    @change="changeDateRange"
                    :sdate="dateFrom"
                    :edate="dateTo"
                  />
                </div>
              </td>
              <td class="text_right valign_top" rowspan="3">
                <a class="button blue sh" @click="searchData()">{{ $t('msg.SPEC030.005') }}
                </a>
              </td>
            </tr>
            <tr style="height: 15px;" v-show="validationDt">
              <td>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.SPEC030.006') }}</th>
              <td colspan="2">
                <e-auto-complete-place
                  id="polPol"
                  @change="changePol"
                  :ctr-cd="pol.ctrCd"
                  :plc-cd="pol.plcCd"
                  :is-char="true"
                  :is-char-alert="true"
                />
              </td>
              <th><span class="ml20">{{ $t('msg.SPEC030.007') }}</span></th>
              <td colspan="2">
                <e-auto-complete-place
                  id="podPod"
                  @change="changePod"
                  :ctr-cd="pod.ctrCd"
                  :plc-cd="pod.plcCd"
                  :is-char="true"
                  :is-char-alert="true"
                />
              </td>
            </tr>
            <!-- 220407 체크박스 별도 라인으로 수정 -->
            <tr>
              <td class="pl0" colspan="6">
                <div>
                  <span class="ml10">
                    <input type="checkbox" id="chk1" name="chk1" v-model="param.asChk1" true-value="02" false-value="">
                    <label for="chk1"><span></span>{{ $t('msg.SPEC030.002') }}</label>
                    <input type="checkbox" id="chk2" name="chk2" v-model="param.asChk2" true-value="03" false-value="">
                    <label for="chk2"><span></span>{{ $t('msg.SPEC030.003') }}</label>
                    <input type="checkbox" id="chk3" name="chk3" v-model="param.asChk3" true-value="01" false-value="">
                    <label for="chk3"><span></span>{{ $t('msg.SPEC030.004') }}</label>
                  </span>
                </div>
              </td>
            </tr>
          <!--//220407 체크박스 별도 라인으로 수정 -->
          </tbody>
        </table>
      </div>
    </form>
    <div class="content_box mt10">
      <div class="flex_box">
        <span class="tot_num">Total : <span class="num">{{ total }}</span></span>
        <span class="input_box">
          <input class="wid300" type="text"
                 v-model="templateStr"
                 :placeholder="$t('msg.ONEX010T010.012')"
                 @keyup="templateEnter"
          >
        </span>
        <span class="ml_auto cal_btn"><a class="button sm" href="#" @click.prevent="exlDownload()">{{ $t('msg.MYIN020T010.016') }}</a></span> <!-- 엑셀 다운로드 -->
      </div>
      <div id="realgrid" style="width: 100%; height: 450px">
      </div>
    </div>
    <!-- content_box // -->
    <div class="flex_box mt10">
      <a class="button lg " @click="openPopup('SpecialRfDgDtlPop', 'btnClickDetail',btnReqNo)">{{ $t('msg.SPEC030.008') }}</a>
      <a class="button blue lg ml_auto" @click="openPopup('SpecialRfDgInqPop', 'btnClickInquiry')">{{ $t('msg.SPEC030.009') }}</a>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import RfDg from '@/api/rest/trans/rfDg'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import { rootComputed } from '@/store/helpers'
import JSZip from 'jszip'
import moment from 'moment'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'reqNo', dataType: 'text' },
  { fieldName: 'polPortCd', dataType: 'text' },
  { fieldName: 'podPortCd', dataType: 'text' },
  { fieldName: 'imoClasCd', dataType: 'text' },
  { fieldName: 'imdunNo', dataType: 'text' },
  { fieldName: 'rmk', dataType: 'text' },
  { fieldName: 'actShprCstEnm', dataType: 'text' },
  { fieldName: 'apvStsCd', dataType: 'text' },
  { fieldName: 'polPortNm', dataType: 'text' },
  { fieldName: 'podPortNm', dataType: 'text' },
  { fieldName: 'polPortCtrCd', dataType: 'text' },
  { fieldName: 'podPortCtrCd', dataType: 'text' }
]

export const columns = [
  {
    name: 'reqNo',
    fieldName: 'reqNo',
    type: 'text',
    header: { text: 'REQ No.' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        let str = ''
        str = '<a style="text-decoration:underline; font-weight: bold;">' + data + '</a>'
        return str
      }
    }
  },
  // { name: 'polPortCd', fieldName: 'polPortCd', type: 'text', header: { text: 'POL' }, editable: false },
  // { name: 'podPortCd', fieldName: 'podPortCd', type: 'text', header: { text: 'POD' }, editable: false },
  {
    name: 'polPortNm',
    fieldName: 'polPortNm',
    type: 'text',
    header: { text: 'POL' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        const idx = cell.index.dataRow
        const polPortCtrCd = grid.getValue(idx, 'polPortCtrCd')

        // 출발, 도착 name 수정
        let polPortNm = data
        polPortNm = data.split(',')
        polPortNm = polPortNm[0] + ',' + polPortCtrCd

        let str = ''
        str = polPortNm
        return polPortNm
      }
    }
  },
  {
    name: 'podPortNm',
    fieldName: 'podPortNm',
    type: 'text',
    header: { text: 'POD' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        const idx = cell.index.dataRow
        const podPortCtrCd = grid.getValue(idx, 'podPortCtrCd')

        // 출발, 도착 name 수정
        let podPortNm = data
        podPortNm = data.split(',')
        podPortNm = podPortNm[0] + ',' + podPortCtrCd

        let str = ''
        str = podPortNm

        return podPortNm
      }
    }
  },
  { name: 'polPortCtrCd', fieldName: 'polPortCtrCd', type: 'text', header: { text: 'TYPE' }, visible: false },
  { name: 'podPortCtrCd', fieldName: 'podPortCtrCd', type: 'text', header: { text: 'TYPE' }, visible: false },
  { name: 'imoClasCd', fieldName: 'imoClasCd', type: 'text', header: { text: 'CLASS' }, editable: false },
  { name: 'imdunNo', fieldName: 'imdunNo', type: 'text', header: { text: 'UN NO' }, editable: false },
  { name: 'rmk', fieldName: 'rmk', type: 'text', header: { text: 'Commodity' }, editable: false },
  { name: 'actShprCstEnm', fieldName: 'actShprCstEnm', type: 'text', header: { text: '제조사' }, editable: false },
  {
    name: 'apvStsCd',
    fieldName: 'apvStsCd',
    type: 'text',
    header: { text: 'STATUS' },
    editable: false,
    readonly: true,
    renderer: 'renderer_button'
  }
]

let layout = [
        'reqNo',
        'polPortNm',
        'podPortNm',
        'imoClasCd',
        'imdunNo',
        'rmk',
        'actShprCstEnm',
        'apvStsCd'
        ]

export default {
  name: 'SpecialRfDg',
  components: {
    EBreadcrumbs,
    EAutoCompletePlace,
    EDateRangePicker: () => import('@/components/common/EDateRangePicker'),
    EDatePicker: () => import('@/components/common/EDatePicker'),
    SpecialRfDgDtlPop: () => import('@/pages/trans/popup/SpecialRfDgDtlPop'),
    SpecialRfDgInqPop: () => import('@/pages/trans/popup/SpecialRfDgInqPop'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop')
  },
  data: function () {
    return {
      dateFrom: '',
      dateTo: '',
      unformattedFrom: '',
      unformattedTo: '',
      isFocus: false,
      isSearch: false,
      isPaging1: true,
      currentPage: 0,
      totalPage: 0,
      pageCount: 0,
      gridList: [],
      gridFilter: [],
      customComponent: null,
      pol: {
        ctrCd: '',
        plcCd: ''
      },
      pod: {
        ctrCd: '',
        plcCd: ''
      },
      startPlcCd: '',
      startPlcName: '',
      destPlcCd: '',
      startCtrCd: '',
      destCtrCd: '',
      destPlcName: '',
      startPolName: '',
      destPodName: '',
      param: {
        curPage: 1, // 현재 페이지
        pageSize: 15, // 한 페이지당 게시글 수
        bkgShprCstNo: 'KMT001',
        asDtKnd: 'DT',
        asFromDt: '',
        asToDt: '',
        asReqNo: '',
        asReqNo1: '',
        asReqNo2: '',
        asReqNo3: '',
        asPolPlcCd: '',
        asPodPlcCd: '',
        asChk1: '02',
        asChk2: '03',
        asChk3: '01'
      },
      // 상세 관련 DATA
      toggleValue: '',
      detail: [],
      detailPort: [],
      btnReqNo: '',
      isHidden: true,
      format: 'YYYY.MM.DD',
      formatnor: 'YYYYMMDD',
      templateStr: '',
      arrReqNo: [],
      total: 0,
      validationDt: false,
      relYn: true
    }
  },
  computed: {
    ...rootComputed,
    columns_ () {
      return [
        {
          name: 'reqNo',
          fieldName: 'reqNo',
          type: 'text',
          header: { text: 'REQ No.' },
          editable: false,
          renderer: {
            type: 'html',
            callback: function (grid, cell, w, h) {
              const data = cell.value
              let str = ''
              str = '<a style="text-decoration:underline; font-weight: bold;">' + data + '</a>'
              return str
            }
          }
        },
        {
          name: 'polPortNm',
          fieldName: 'polPortNm',
          type: 'text',
          header: { text: 'POL' },
          editable: false,
          renderer: {
            type: 'html',
            callback: function (grid, cell, w, h) {
              const data = cell.value
              const idx = cell.index.dataRow
              const polPortCtrCd = grid.getValue(idx, 'polPortCtrCd')

              // 출발, 도착 name 수정
              let polPortNm = data
              polPortNm = data.split(',')
              polPortNm = polPortNm[0] + ',' + polPortCtrCd

              let str = ''
              str = polPortNm
              return polPortNm
            }
          }
        },
        {
          name: 'podPortNm',
          fieldName: 'podPortNm',
          type: 'text',
          header: { text: 'POD' },
          editable: false,
          renderer: {
            type: 'html',
            callback: function (grid, cell, w, h) {
              const data = cell.value
              const idx = cell.index.dataRow
              const podPortCtrCd = grid.getValue(idx, 'podPortCtrCd')

              // 출발, 도착 name 수정
              let podPortNm = data
              podPortNm = data.split(',')
              podPortNm = podPortNm[0] + ',' + podPortCtrCd

              let str = ''
              str = podPortNm

              return podPortNm
            }
          }
        },
        { name: 'polPortCtrCd', fieldName: 'polPortCtrCd', type: 'text', header: { text: 'TYPE' }, visible: false },
        { name: 'podPortCtrCd', fieldName: 'podPortCtrCd', type: 'text', header: { text: 'TYPE' }, visible: false },
        { name: 'imoClasCd', fieldName: 'imoClasCd', type: 'text', header: { text: 'CLASS' }, editable: false },
        { name: 'imdunNo', fieldName: 'imdunNo', type: 'text', header: { text: 'UN NO' }, editable: false },
        { name: 'rmk', fieldName: 'rmk', type: 'text', header: { text: this.$t('msg.ONEX080P060.008') }, editable: false },
        { name: 'actShprCstEnm', fieldName: 'actShprCstEnm', type: 'text', header: { text: '제조사' }, editable: false },
        {
          name: 'apvStsCd',
          fieldName: 'apvStsCd',
          type: 'text',
          header: { text: this.$t('msg.ONEX010P090.032') },
          editable: false,
          readonly: true,
          renderer: 'renderer_button'
        }
      ]
    }
  },
  created () {
    window.functionOutsideVue(this)
    window.thiRfDg = this
    // 검색 날짜 최근 3개월 세팅
    this.optionChange()
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  mounted: function () {
    console.log('mounted')
    $('ul .grid.t3.mt10').removeClass('mt10')

    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip

    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    // 그리드 필드 생성
    provider.setFields(fields)
    // columns 명
    // gridView.setColumns(columns)
    gridView.setColumns(this.columns_)
    gridView.setColumnLayout(layout)
    gridView.setDataSource(provider)

    // 헤더 높이 조정
    gridView.header.heights = [45]
    gridView.displayOptions.rowHeight = 35
    gridView.displayOptions.fitStyle = 'even'

    gridView.setStateBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    gridView.setCheckBar({
      visible: true,
      exclusive: false
    })

    // 헤더 다국어 적용
    this.setMessageProperty()

    // 페이징
    // gridView.setPaging(true, 10)

    const $vm = this

    gridView.onCellItemClicked = function (grid, index, clickData) {
      let checkCurrent = gridView.getCurrent()

      let polPortNm = provider.getValue(checkCurrent.dataRow, 'polPortNm')
      let podPortNm = provider.getValue(checkCurrent.dataRow, 'podPortNm')
      let polPortCtrCd = provider.getValue(checkCurrent.dataRow, 'polPortCtrCd')
      let podPortCtrCd = provider.getValue(checkCurrent.dataRow, 'podPortCtrCd')

      polPortNm = polPortNm.split(',')
      podPortNm = podPortNm.split(',')

      let detailPort = {
        polPortNm: polPortNm[0],
        podPortNm: podPortNm[0],
        polPortCtrCd: polPortCtrCd,
        podPortCtrCd: podPortCtrCd,
        reqNo: clickData.value
      }

      if (checkCurrent.fieldName === 'reqNo') {
         $vm.detailPort = detailPort
         $vm.openPopup('SpecialRfDgDtlPop', 'cellClickDetail', clickData.value)
      }
    }

    gridView.onItemChecked = function (grid, itemIndex, checked) {
      var items = gridView.getCheckedItems(true)
      let getCheckedRowNum = items.length
      let checkCurrent = gridView.getCurrent()
      console.log($vm.btnReqNo)
    }
    // $vm.gridCellStyle()
    $vm.customRender()
    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })
  },
  methods: {
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter') {
          this.searchData()
        }
      }
    },
    setMessageProperty () {
    // 컬럼 헤더 텍스트 적용
      var polPortNm = gridView.getColumnProperty('polPortNm', 'header')
      var podPortNm = gridView.getColumnProperty('podPortNm', 'header')
      var actShprCstEnm = gridView.getColumnProperty('actShprCstEnm', 'header')

      polPortNm.text = this.$t('msg.FARE010T010.001')
      podPortNm.text = this.$t('msg.FARE010T010.002')
      actShprCstEnm.text = this.$t('msg.NEWB010P120.010')

      gridView.setColumnProperty('polPortNm', 'header', polPortNm)
      gridView.setColumnProperty('podPortNm', 'header', podPortNm)
      gridView.setColumnProperty('actShprCstEnm', 'header', actShprCstEnm)
    },
    //상세 페이지 활성화
    moveDetail (reqNo) {
        //rest api 호출
        const detail = RfDg.getRfDgInfo(reqNo)

        detail.then((data) => {
            const detailData = data.data
            this.detail = detailData
        })
    },
    changeDateRange (s, d) {
      this.dateFrom = s
      this.dateTo = d

      this.unformattedFrom = moment(s).format('YYYY-MM-DD')
      this.unformattedTo = moment(d).format('YYYY-MM-DD')

      this.checkSearchValidation('fromDtToDt')
    },
    openPopup (compNm, kind, reqNo) {
      console.log('openPopup')
      this.customComponent = compNm

      if (kind === 'cellClickDetail') { // 셀 클릭 상세
        this.moveDetail(reqNo)
      } else if (kind === 'btnClickDetail') { // 버튼 클릭 상세
        // 그리드 값 가져오기
        let rowCnt = provider.getRows(0).length
        // var checkedItems = gridView.getCheckedItems(true)
        let rows = gridView.getCheckedRows(true)

        let checkedRowCnt = rows.length
        this.arrReqNo = []
        //checkedItems.reqNo
        //console.log('ARR=======> :: ', checkedItems)
        if (checkedRowCnt === 0) {
          this.openAlert('Please select request no')
          return
        } else {
          //console.log('DT :: ', rows)
          for (let i = 0; i < checkedRowCnt; i++) {
            var rrr = provider.getValue(rows[i], 'reqNo')
            //console.log('=======> :: ', rrr)
            this.arrReqNo.push(rrr)
          }
          //console.log('ARR :: ', this.arrReqNo)
          this.param.asReqNo = this.arrReqNo.join(';')
          //console.log('STRING :: ', this.param.asReqNo)
          //reqNo = this.btnReqNo
          this.moveDetail(this.param.asReqNo)
        }
      }
      // 선적 가부 문의 버튼 클릭시 상세 초기화
      if (kind === 'btnClickInquiry') {
        this.detail = []
      }

      // 팝업 후처리
      $('#layerPop > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    closePoup () {
        this.customComponent = null

        $('#layerPop > .popup_dim').fadeOut()
        $('body,html').removeAttr('style')

       // 데이터 재조회
       this.searchData('closeResearch')
    },
    closeFun () {
      this.$Simplert.close()
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    optionChange () {
      if (this.param.asDtKnd === 'DT') {
          // startDate 세팅
          var sdate = new Date()
          var startDate = new Date(sdate.setMonth(sdate.getMonth() - 3))

          var edate = new Date()
          var endDate = new Date(edate.getFullYear(), edate.getMonth() + 1, edate.getDate())
          var month = ''
          var day = ''

          if (String(startDate.getMonth()) === '0') {
            month = '01'
          } else {
            if (String(startDate.getMonth() + 1).length === 1) {
              month = '0' + String(startDate.getMonth() + 1)
            } else {
              month = String(startDate.getMonth() + 1)
            }
          }

          if (String(startDate.getDate()).length === 1) {
            day = '0' + String(startDate.getDate())
          } else {
            day = String(startDate.getDate())
          }

          if (month === '01' && day < 15) {
            this.dateFrom = startDate.getFullYear() + '.' + month + '.' + day

            // datePicker 전달 value
            this.unformattedFrom = startDate.getFullYear() + month + day
          } else {
            this.dateFrom = startDate.getFullYear() + '.' + month + '.' + day

            // datePicker 전달 value
            this.unformattedFrom = startDate.getFullYear() + month + day
          }

          if (String(endDate.getMonth()) === '0') {
            month = '12'
          } else {
            if (String(endDate.getMonth()).length === 1) {
              month = '0' + String(endDate.getMonth())
            } else {
              month = String(endDate.getMonth())
            }
          }

          if (String(endDate.getDate()).length === 1) {
            day = '0' + String(endDate.getDate())
          } else {
            day = String(endDate.getDate())
          }

          this.dateTo = edate.getFullYear() + '.' + month + '.' + day

          // datePicker 전달 value
          this.unformattedTo = edate.getFullYear() + month + day
      }
    },
    optionChk: function () {
      if (this.param.asDtKnd === 'RQ') {
        if (this.param.asReqNo1 === '' && this.param.asReqNo2 === '' && this.param.asReqNo3 === '') {
            this.openAlert('Please input request no.')
            return false
        }
      } else if (this.param.asDtKnd === 'DT') {
        if (this.dateFrom === '') {
            this.openAlert('Please input start date.')
            return false
        }
        if (this.dateTo === '') {
            this.openAlert('Please input end date.')
            return false
        }
      }

      //if (this.startCtrCd === '' || this.startPlcCd === '') {
      //  this.openAlert('Please input POL Code.')
      //  return false
      //} else if (this.destCtrCd === '' || this.destPlcCd === '') {
      //  this.openAlert('Please input POD Code.')
      //  return false
      //} else {
        this.param.startCtrCd = this.startCtrCd
        this.param.startPlcCd = this.startPlcCd
        this.param.destPlcCd = this.destPlcCd
        this.param.destCtrCd = this.destCtrCd
      //}

      return true
    },
    searchAllByDt () {
      this.param.asDtKnd = 'DT'
      // this.optionChange()
      this.searchData()
    },
    searchData (msg) {
      let searchk = ''
      this.templateStr = ''
      if (msg === 'closeResearch') {
        searchk = true
      } else {
        searchk = this.optionChk()
      }
      this.isSearch = true

      if (this.dateFrom !== '' && this.dateTo !== '') {
        var from = this.dateFrom.split('.').join('')
        var to = this.dateTo.split('.').join('')

        this.param.asFromDt = from
        this.param.asToDt = to
      }

      this.param.asChk4 = ''

      if (this.param.asChk3 !== '') {
        this.param.asChk4 = '04'
      }
      console.log('SEARCH========>')
      console.log(this.param)
     if (searchk) {
      RfDg.getRfDgList(this.param)
        .then(response => {
          console.log(response)
          const resp = response.data
          //const oogList = resp.oogList
          this.total = resp.length
          //if (resp.length !== 0) {
          this.gridList = resp
          provider.setRows(this.gridList)
          //}
          this.currentPage = gridView.getPage() //현재 페이지 반환
          // this.currentPage = 0
          this.totalPage = gridView.getPageCount() //전체페이지 개수 반환
        })
        .catch(err => {
          console.log(err)
        })
        console.log('searchData')
     }
    },
    changePol (vo) {
      this.pol = vo
      this.startPolName = vo.plcEnm
      this.startCtrCd = vo.ctrCd
      this.startPlcCd = vo.plcCd
      this.startPlcName = vo.plcEnm
      this.param.asPolPlcCd = vo.plcCd
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#polPol')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod (vo) {
      this.pod = vo
      this.destPodName = vo.plcEnm
      this.destCtrCd = vo.ctrCd
      this.destPlcCd = vo.plcCd
      this.destPlcName = vo.plcEnm
      this.param.asPodPlcCd = vo.plcCd
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    checkSearchValidation (id) {
      const searchParams = this.param
      const frm = document.querySelector('#frm')
      let selector = frm.querySelector('#' + id)
      let isOk = true

      if (selector === null || selector === undefined) {
        return true
      }

      // this.$ekmtcCommon.hideErrorTooltip(selector)
      this.localHideErrorTooltip(selector)

      switch (id) {
        case 'fromDtToDt':
          // if (searchParams.asDtKnd === 'DT' && (this.$ekmtcCommon.isEmpty(searchParams.asFromDt) || this.$ekmtcCommon.isEmpty(searchParams.asToDt))) {
          //   this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          //   isOk = false
          // } else
          var fromDt = new Date(this.unformattedFrom)
          var toDt = new Date(this.unformattedTo)

          if (searchParams.asDtKnd === 'DT' && fromDt > toDt) {
            this.LocalShowErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
            // this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
            isOk = false
          }
          break
      }
      return isOk
    },
    LocalShowErrorTooltip (elem, message) {
          this.validationDt = true

          let $elem = $(elem)
          let $parent = $elem.parents('td, div').eq(0)
          let $parentTd = $parent.parents('td,div').eq(0)
          let $parentLi = $elem.parents('li').eq(0)

          $elem.addClass('input_error')
          let $sMsgBox = ''
          let $msgBox = ''

          // $parentTd.addClass('position_relative')
          $msgBox = $parent.find('.tooltip_essen_box')

          if ($sMsgBox.length === 0) {
            $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
            $msgBox.appendTo($parentLi)
          }
          $msgBox.find('.tooltip_essen').eq(0).text(message).show()
    },
    localHideErrorTooltip (elem) {
          this.validationDt = false
          const $elem = $(elem)
          const $parent = $elem.parents('td, div').eq(0)
          let $parentLi = $elem.parents('li').eq(0)

          $elem.removeClass('input_error')
          $parentLi.find('.tooltip_essen').hide()
    },
    customRender: function () {
      const asking = this.$t('msg.CSBK140.001')
      const pass = this.$t('msg.CSBK140.002')
      const nPass = this.$t('msg.CSBK140.003')
      const cheking = this.$t('msg.CSBK140.004')

      gridView.registerCustomRenderer('renderer_button', {
        initContent: function (parent) {
          var span = this._span = document.createElement('span')
          parent.appendChild(span)
        },
        render: function (grid, model, width, height, info) {
          info = info || {}
          var span = this._span
          // text설정.
          //span.className = 'button blue sm'
          span.textContent = model.value

          if (span.textContent === '01') {
              span.className = 'label green md minwid60'
              span.textContent = cheking
          } else if (span.textContent === '02') {
              span.className = 'label blue md minwid60'
              span.textContent = pass
          } else if (span.textContent === '03') {
              span.className = 'label red md minwid60'
              span.textContent = nPass
          } else {
              span.className = 'label gray md minwid60'
              span.textContent = cheking
          }
          // this._value = model.value
        }
      })
    },
    selOneWeek () {
      let now = moment()
      this.dateTo = now.format(this.format)
      this.dateFrom = now.add(-6, 'd').format(this.format)

      this.unformattedTo = now.format(this.formatnor)
      this.unformattedFrom = now.add(-6, 'd').format(this.formatnor)
    },
    selOneMonth () {
      let now = moment()
      this.dateTo = now.format(this.format)
      this.dateFrom = now.add(-1, 'M').add(1, 'd').format(this.format)

      this.unformattedTo = now.format(this.formatnor)
      this.unformattedFrom = now.add(-1, 'M').add(1, 'd').format(this.formatnor)
    },
    selMonth () {
      let now = moment()
      this.dateTo = now.format(this.format)
      this.dateFrom = now.set('date', 1).format(this.format)

      this.unformattedTo = now.format(this.formatnor)
      this.unformattedFrom = now.set('date', 1).format(this.formatnor)
    },
    templateEnter (e) {
      // if (e.keyCode === 13) {
        const gridList = this.gridList
        if (this.templateStr === '') {
          provider.setRows(this.gridList)
        } else {
          this.gridFilter = gridList.filter(vo => (this.$ekmtcCommon.isNotEmpty(vo.rmk) && vo.rmk.indexOf(this.templateStr) > -1) ||
                                                  (this.$ekmtcCommon.isNotEmpty(vo.reqNo) && vo.reqNo.indexOf(this.templateStr) > -1) ||
                                                  (this.$ekmtcCommon.isNotEmpty(vo.polPortNm) && vo.polPortNm.indexOf(this.templateStr) > -1) ||
                                                  (this.$ekmtcCommon.isNotEmpty(vo.podPortNm) && vo.podPortNm.indexOf(this.templateStr) > -1) ||
                                                  (this.$ekmtcCommon.isNotEmpty(vo.imoClasCd) && vo.imoClasCd.indexOf(this.templateStr) > -1) ||
                                                  (this.$ekmtcCommon.isNotEmpty(vo.imdunNo) && vo.imdunNo.indexOf(this.templateStr) > -1) ||
                                                  (this.$ekmtcCommon.isNotEmpty(vo.actShprCstEnm) && vo.actShprCstEnm.indexOf(this.templateStr) > -1) ||
                                                  (this.$ekmtcCommon.isNotEmpty(vo.apvStsNm) && vo.apvStsNm.indexOf(this.templateStr) > -1)
                                            )
          provider.setRows(this.gridFilter)
        }
      // }
    },
    setPrevPage: function () {
      gridView.setPage(this.currentPage - 1)
      this.currentPage = gridView.getPage()
    },
    setNextPage: function () {
      gridView.setPage(this.currentPage + 1)
      this.currentPage = gridView.getPage()
    },
    exlDownload () {
      const curDt = moment().format('YYYYMMDD')
      gridView.exportGrid({
        type: 'excel',
        target: 'local',
        textCallback: function (index, column, value) {
          if (column === 'apvStsCd') {
            if (value === '01') {
              return '확인중'
            } else if (value === '02') {
              return '선적가능'
            } else if (value === '03') {
              return '선적불가'
            } else {
              return '확인중' // SpecialRfDgDtlPop.vue 소스에 '01'과 ELSE 조건에 CSBK140.004 중복으로 사용하여 똑같이 구성함
            }
          } else {
            return value
          }
        },
        documentTitle: { //제목
          message: this.$t('menu.MENU04.000') + '_' + this.$t('menu.MENU04.030'), // 업무 대행정보 리스트
          visible: true,
          spaceTop: 1,
          spaceBottom: 1,
          height: 60,
          styleName: 'excel-export-title-style'
        },
        applyDynamicStyles: true,
        exportTemplate: true,
        //allColumns: true,
        pagingAllItems: true,
        fileName: `${this.$t('menu.MENU04.000') + '_' + this.$t('menu.MENU04.030')}_${curDt}.xlsx`,
        showProgress: false,
        progressMessage: this.$t('msg.ADD20220219.012'), // 엑셀 Export중입니다.
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          console.log('엑셀 다운로드 완료')
        }
      })
    } /*
    exlDownload () { // 그리드 엑셀 다운로드
      let title = `${this.$t('menu.MENU04.000')}_${this.$t('menu.MENU04.030')}` // 진행중인업무_수입
      this.$ekmtcCommon.gridExportExcel(gridView, title)
    } */
  }
}
</script>

 <style scoped>
/*https://docs.realgrid.com/guides/renderer/custom-renderer-img-buttons*/

a.no-uline:hover{
  text-decoration:underline;
}

.custom_none {
  display:none;
}

.custom_render_span {
  flex: 1;
  text-align: left;
  overflow: hidden;
}

.custom_renderer > div {
  display: flex;
  align-items: center;
}

.custom_renderer .rg-renderer:hover .custom-hover {
  visibility: visible;
  text-decoration:underline;
}

.custom_renderer .rg-renderer .custom-hover {
  visibility: visible;
}

.custom_renderer .rg-renderer .custom-focused {
  visibility: visible;
}

.custom_search {
  height: 26px;
  margin-left: 2px;
  background: url("https://docs.realgrid.com/public/images/btnImages/search_normal.png") center center no-repeat;
  flex: 0 0 45px;
}
.custom_search:hover {
  background: url("https://docs.realgrid.com/public/images/btnImages/search_hover.png") center center no-repeat;
}
.custom_search:active {
  background: url("https://docs.realgrid.com/public/images/btnImages/search_click.png") center center no-repeat;
}

.custom_popup {
  height: 26px;
  margin-left: 2px;
  background: url("https://docs.realgrid.com/public/images/btnImages/popup_normal.png") center center no-repeat;
  flex: 0 0 45px;
}
.custom_popup:hover {
  background: url("https://docs.realgrid.com/public/images/btnImages/popup_hover.png") center center no-repeat;
}
.custom_popup:active {
  background: url("https://docs.realgrid.com/public/images/btnImages/popup_click.png") center center no-repeat;
}

/*https://docs.realgrid.com/guides/grid-style/realgrid-2-custom-styles-3-data*/
.rg-focus {
  /* 포커스 셀의 색깔 변경 */
  border: 2px solid #00f;
}

/*https://docs.realgrid.com/guides/grid-style/realgrid-2-custom-styles-3-data*/
.rg-selection-cell {
  /* 선택 영역의 색깔 변경 */
  background: #0000ff40 !important;
  color: inherit;
}

.rg-selection {
  background: rgba(21, 139, 229, 0.2) !important;
  border: 1px solid rgba(21, 66, 229, 0.67);
}
/*https://docs.realgrid.com/guides/grid-style/grid-style*/
.orange-column {
  background: #ffff00
}

.cursor {
  cursor: crosshair;
}

/*https://docs.realgrid.com/guides/grid-style/cell-dynamic-style*/
.orange-color{
  background: #ff8b00;
}

/*focus https://docs.realgrid.com/guides/grid-style/focus-class */
.rg-focused-cell{
  background: #FF00ff;
}

.rg-focused-row{
  background: #FFFF00;
}
/*https://forum.realgrid.com/topics/pkpSNXphwB44bHPvg*/
.datepicker-inline {
  width: 200px;
}

ul.grid{
 position:absolute;
}

span.float {
  float:right;
}

.tbl_search td {
    padding: 4px 10px 0px 10px !important;
}
 </style>
