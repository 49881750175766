<template>
  <div>
    <!-- 외상매출금 조회 -->
    <form id="frm">
      <div class="content_box beforetab">
        <table class="tbl_search">
          <colgroup>
            <col width="60px"><col width="250px"><col width="150px"><col width="259px"><col width="*"><col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.ONEX010T010.001') }}</th> <!-- 조회 -->
              <td>
                <input type="radio" id="tab04-all1" name="tab04-exim" v-model="params.eiCatCd" :value="'A'">
                <label for="tab04-all1"><span></span>{{ $t('msg.SETT010T010.140') }}</label> <!-- 전체 -->

                <input type="radio" id="tab04-ex1" name="tab04-exim" v-model="params.eiCatCd" :value="'E'">
                <label for="tab04-ex1"><span></span>{{ $t('msg.SETT010T010.001') }}</label> <!-- 수입 -->

                <input type="radio" id="tab04-im1" name="tab04-exim" v-model="params.eiCatCd" :value="'I'">
                <label for="tab04-im1"><span></span>{{ $t('msg.SETT010T010.002') }}</label> <!-- 수출 -->
              </td>
              <td class="pr5">
                <select v-model="params.dtKnd" @change="optionChange()">
                  <option value="I">{{ $t('msg.SETT010T010.141') }}</option> <!-- 발행일자 -->
                  <option value="A">{{ $t('msg.SETT010T010.142') }}</option> <!-- 회계일자 -->
                </select>
              </td>
              <td colspan="2" class="pl0">
                <!-- <e-date-range-picker-for-settle
                  :term-type="termType"
                  :show-term-type="['01', '02', '03', '04']"
                  :sdate="range.sdate"
                  :edate="range.edate"
                  @change="changeDateRange"
                /> -->
                <e-date-range-picker-multi
                  :id="'creditSalesDatePicker'"
                  @change="changeDateRange"
                  :sdate="range.sdate"
                  :edate="range.edate"
                />
              </td>
              <td class="text_right">
                <a class="button blue sh" @click="searchData()">{{ $t('msg.CMBA100.00120') }}</a> <!-- 검색 -->
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- cpntent_box // -->
      <div class="content_box mt10">
        <!-- content_box  -->

        <div class="flex_box">
          <span class="tot_num">Total : <span class="num">{{ total }}</span></span>
          <span class="ml_auto cal_btn"><a class="button sm" href="#" @click="excelDownload">{{ $t('msg.MYIN020T010.016') }}</a></span>
        </div>

        <div id="credit-sales-realgrid" style="width: 100%; height: 450px">
        </div>
      <!-- <div
        v-if="rstList.length > 0"
        class="paging"
      >
        <a
          v-if="isPaging1"
          class="btn prev"
          @click.prevent="setPrevPage()"
        >Prev</a>
        <a>{{ currentPage + 1 }}/{{ totalPage }}</a>
        <a
          class="btn next"
          @click.prevent="setNextPage()"
        >Next</a>
      </div> -->
      </div><!-- content_box// -->
      <div class="mt10 text_right">
        <!-- <a class="button blue lg" href="#" @click.prevent="excelDownload()">{{ $t('msg.SETT010T010.139') }}</a> 엑셀 다운로드 -->
      </div>
    </form>
  </div>
</template>

<style>
  .modal-txt-box {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 80px;
    padding: 10px;
  }
  .modal-body {
    padding: 0 !important;
  }
  /* #fright-modal-sm .modal-content {
    min-height: 300px !important;
  } */
  #fright-modal-sm .modal-content .modal-footer1 {
    display: block !important;
  }
  .text-column {
    text-align: left;
  }
  .number-column {
    text-align: right;
  }
  span .bl-link {
    color: #075bb9;
    text-decoration: underline !important;
    cursor: pointer;
  }

  .issststus {
    /* color: black; */
    text-decoration: underline !important;
  }
</style>

<script>
import { GridView, LocalDataProvider } from 'realgrid' // realgrid Component
import JSZip from 'jszip' // 엑셀 다운로드용 JSZip Component
import moment from 'moment' // 날짜 가져오는 Component
import CalcIssue from '@/api/rest/settle/calcIssue' // 검색 api용 Component
// import EDateRangePickerForSettle from '@/components/common/EDateRangePickerForSettle' // 달력 Component
import { rootComputed } from '@/store/helpers'
import _lodash from 'lodash'
import store from '@/store/index'

// import tvm from 'vue'
// import tvm from '@/i18n'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'acshIssDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' }, // 발행일자
  { fieldName: 'accoDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' }, // 회계일자
  { fieldName: 'acshRno', dataType: 'text' }, // 계산서번호
  { fieldName: 'blNo', dataType: 'text' }, // B/L No.
  { fieldName: 'usdFrtSum', dataType: 'number' }, // 외화금액(USD)
  { fieldName: 'frtSum', dataType: 'number' }, // 원화운임
  { fieldName: 'wfgSum', dataType: 'number' }, // 입항료
  { fieldName: 'pfsSum', dataType: 'number' }, // 항만시설보안료
  { fieldName: 'othSum', dataType: 'number' }, // Others
  { fieldName: 'othTaxSum', dataType: 'number' }, // VAT
  { fieldName: 'total', dataType: 'number' } // Total
]

export const columns = [
  { name: 'acshIssDt', fieldName: 'acshIssDt', type: 'text', header: { text: app.$t('msg.SETT010T010.141') }, editable: false, datetimeFormat: 'yyyy.MM.dd', width: 80 }, // 발행일자
  { name: 'accoDt', fieldName: 'accoDt', type: 'text', header: { text: app.$t('msg.SETT010T010.142') }, editable: false, datetimeFormat: 'yyyy.MM.dd', width: 80 }, // 회계일자
  { name: 'acshRno', fieldName: 'acshRno', type: 'text', header: { text: app.$t('msg.SETT010T010.143') }, editable: false, width: 80 }, // 계산서번호
  { name: 'blNo', fieldName: 'blNo', type: 'text', header: { text: app.$t('msg.SETT010T010.137') }, footer: { text: app.$t('msg.CSCT060T070.013') + ' =>' }, editable: false, width: 80 }, // B/L No.
  { name: 'usdFrtSum', fieldName: 'usdFrtSum', type: 'data', numberFormat: '#,##0.00', styleName: 'number-column', header: { text: app.$t('msg.SETT010T010.144') }, footer: { numberFormat: '#,##0.00', expression: 'sum' }, editable: false, width: 80 }, // 외화금액(USD)
  { name: 'frtSum', fieldName: 'frtSum', type: 'data', numberFormat: '#,##0', styleName: 'number-column', header: { text: app.$t('msg.SETT010T010.145') }, footer: { numberFormat: '#,##0', expression: 'sum' }, editable: false, width: 80 }, // 원화운임
  { name: 'wfgSum', fieldName: 'wfgSum', type: 'data', numberFormat: '#,##0', styleName: 'number-column', header: { text: app.$t('msg.SETT010T010.146') }, footer: { numberFormat: '#,##0', expression: 'sum' }, editable: false, width: 80 }, // 입항료
  { name: 'pfsSum', fieldName: 'pfsSum', type: 'data', numberFormat: '#,##0', styleName: 'number-column', header: { text: app.$t('msg.SETT010T010.147') }, footer: { numberFormat: '#,##0', expression: 'sum' }, editable: false, width: 80 }, // 항만시설보안료
  { name: 'othSum', fieldName: 'othSum', type: 'data', numberFormat: '#,##0', styleName: 'number-column', header: { text: app.$t('msg.SETT010T010.148') }, footer: { numberFormat: '#,##0', expression: 'sum' }, editable: false, width: 80 }, // Others
  { name: 'othTaxSum', fieldName: 'othTaxSum', type: 'data', numberFormat: '#,##0', styleName: 'number-column', header: { text: app.$t('msg.SETT010T010.149') }, footer: { numberFormat: '#,##0', expression: 'sum' }, editable: false, width: 80 }, // VAT
  { name: 'total', fieldName: 'total', type: 'data', numberFormat: '#,##0', styleName: 'number-column', header: { text: app.$t('msg.SETT010T010.150') }, footer: { numberFormat: '#,##0', expression: 'sum' }, editable: false, width: 80 } // Total
]

const gridLayout = [
  'acshIssDt', // 발행일자
  'accoDt', // 회계일자
  'acshRno', // 계산서번호
  'blNo', // B/L No.
  'usdFrtSum', // 외화금액(USD)
  'frtSum', // 원화운임
  'wfgSum', // 입항료
  'pfsSum', // 항만시설보안료
  'othSum', // Others
  'othTaxSum', // VAT
  'total' // Total
]

export default {
  name: 'CalcIssueCreditSales',
  components: {
    CalcIssue,
 //   EDateRangePickerForSettle,
    EDateRangePickerMulti: () => import('@/components/common/EDateRangePickerMulti')
  },
  props: {
    paramData: {
      type: Object,
      default () {
        return {
          tab: '', // 탭
          dtKnd: '', // 날짜조회옵션
          eiCateCd: '' // 수출/수입 구분
        }
      }
    }
  },
  data: function () {
    return {
      params: {
        fromDt: '',
        toDt: '',
        dtKnd: 'I',
        eiCatCd: 'A'
      },
      beforeParams: {},
      rstList: [],
      searchedList: [],
      total: 0,
      range: {
        sdate: '',
        edate: ''
      },

      //그리드 변수
      isPaging1: true,
      currentPage: 0,
      totalPage: 0,
      termType: '02',
      gridLayout: [],

      isReqView: false,
      unformattedFrom: '',
      unformattedTo: '',

      validationDt: false
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    'params.eiCatCd' (nv, ov) {
      this.gridInit()
    },
    '$store.state.settle.searchParams' () {
      console.log('$store.state.settle.searchParams >>>> ', this.$store.state.settle.tabIndex)
      if (this.$store.state.settle.tabIndex === '4') {
        this.setVuexSearchParams()
      }
    },
    'paramData.dtKnd' (nv, ov) {
      this.requestView()
    },
    'paramData.eiCatCd' (nv, ov) {
      this.requestView()
    }
  },
  created () {
    window.functionOutsideVue(this)
  },
  mounted: function () {
    this.setDateRange()

    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip

    provider = new LocalDataProvider(true)
    gridView = new GridView('credit-sales-realgrid')

    // 그리드 필드 생성
    provider.setFields(fields)

    // columns 명
    gridView.setColumns(columns)
    gridView.setColumnLayout(gridLayout)
    gridView.setDataSource(provider)

    // 그리고 헤더 높이 조정
    gridView.header.heights = [40]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'evenFill'
    gridView.displayOptions.selectionStyle = 'block'

    gridView.setStateBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    // gridView.setCheckBar({
    //  visible: true,
    //  exclusive: false,
    //  mergeRule: "values['acshIssDt'] + values['blNo']"
    // })
    gridView.setCheckBar({
     visible: false
    })
    // gridView.columnByName('acshIssDt').mergeRule = { criteria: "value + values['blNo']" }
    // gridView.columnByName('accoDt').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('acshRno').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('blNo').mergeRule = { criteria: "values['acshIssDt'] + value" }
    // gridView.columnByName('usdFrtSum').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('frtSum').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('wfgSum').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('pfsSum').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('othSum').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('othTaxSum').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('total').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }

    this.gridInit()
    this.requestView()
  },
  methods: {
    setDateRange () {
      // 달력 초기 데이터 세팅(-1주일 ~ 현재)
      this.range = {
        sdate: this.getWeekDt(-7),
        edate: this.getWeekDt(7)
      }
      this.params.fromDt = this.range.sdate
      this.params.toDt = this.range.edate
    },

    gridInit () {
      // 그리드 초기화
      this.rstList = []
      provider.setRows(this.rstList)
      this.total = this.rstList.length
      this.currentPage = gridView.getPage()
      this.totalPage = gridView.getPageCount()
    },
    // 입력 데이터 기준 yyyyMMdd 반환
    getDateStr (myDate) {
      let year = myDate.getFullYear()
      let month = (myDate.getMonth() + 1)
      let day = myDate.getDate()

      month = (month < 10) ? '0' + String(month) : month
      day = (day < 10) ? '0' + String(day) : day

      return year.toString() + month.toString() + day.toString()
    },
    // n일 후의 yyyyMMdd 반환
    getWeekDt (n) {
      let d = new Date()
      let dayOfMonth = d.getDate()
      d.setDate(dayOfMonth + n)
      return this.getDateStr(d)
    },
    // 리스트 조회
    requestView () {
      console.log('---------requestView---------')
      this.isReqView = true
      if (this.paramData.tab === '4') {
        this.params.dtKnd = this.paramData.dtKnd
        this.params.eiCatCd = this.paramData.eiCatCd
        this.searchData()
      }
    },
    // vuex store 값 변경시 params 세팅
    setVuexSearchParams () {
      /* TODO
        서브개인화 B/L 미발행, D/O 미발행 링크 클릭시 vuex에 파라미터 정보가 저장되고,
        watch에서 저장되는 action을 감지하여 이 메소드가 호출되도록 하였습니다. 파라미터 세팅해서 검색하도록 구현해주세요.
        this.$store.state.settle.searchParams.*
      */
      console.log('---------setVuexSearchParams---------')
      console.log(this.$store.state.settle.searchParams)
      const pcParams = this.$store.state.settle.searchParams
      // this.termType = ''
      this.params.fromDt = pcParams.fromDt
      this.params.toDt = pcParams.toDt
      this.params.dtKnd = pcParams.dtKnd
      this.params.eiCatCd = pcParams.eiCatCd

      this.searchData()
    },
    // 달력의 날짜 변경
    changeDateRange (s, d) {
      this.params.fromDt = s
      this.params.toDt = d

      this.unformattedFrom = moment(s).format('YYYY-MM-DD')
      this.unformattedTo = moment(d).format('YYYY-MM-DD')

      this.checkSearchValidation('creditSalesDatePicker')
    },
    checkSearchValidation (id) {
      const searchParams = this.param
      const frm = document.querySelector('#frm')
      let selector = frm.querySelector('#' + id)
      let isOk = true

      if (selector === null || selector === undefined) {
        return true
      }

      // this.$ekmtcCommon.hideErrorTooltip(selector)
      this.localHideErrorTooltip(selector)

      switch (id) {
        case 'creditSalesDatePicker':
          var fromDt = new Date(this.unformattedFrom)
          var toDt = new Date(this.unformattedTo)

          if (fromDt > toDt) {
            this.LocalShowErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
            // this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
            isOk = false
          }
          break
      }
      return isOk
    },
    LocalShowErrorTooltip (elem, message) {
          this.validationDt = true

          let $elem = $(elem)
          let $parent = $elem.parents('td, div').eq(0)
          let $parentTd = $parent.parents('td,div').eq(0)
          let $parentLi = $elem.parents('li').eq(0)

          $elem.addClass('input_error')
          let $sMsgBox = ''
          let $msgBox = ''

          // $parentTd.addClass('position_relative')
          $msgBox = $parent.find('.tooltip_essen_box')

          if ($sMsgBox.length === 0) {
            $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
            $msgBox.appendTo($parentLi)
          }
          $msgBox.find('.tooltip_essen').eq(0).text(message).show()
    },
    localHideErrorTooltip (elem) {
          this.validationDt = false
          const $elem = $(elem)
          const $parent = $elem.parents('td, div').eq(0)
          let $parentLi = $elem.parents('li').eq(0)

          $elem.removeClass('input_error')
          $parentLi.find('.tooltip_essen').hide()
    },
    // 유효성 확인 후 외상매출금 api 호출
    searchData () {
      if (this.$store.state.settle.isSettleSearchData) {
        this.params = JSON.parse(JSON.stringify(this.beforeParams))
      }
      this.$store.commit('settle/updateIsSettleSearchData', false)

      if (this.optionChk()) {
        this.rstList = []

        store.commit('updateWorkingEiCatCd', this.params.eiCatCd)
        // console.log(JSON.stringify(this.params))
        CalcIssue.getCreditSalesInfo(this.params)
          .then(response => {
            // console.log(response)
            this.isReqView = false
            this.beforeParams = JSON.parse(JSON.stringify(this.params))
            let resp = []
            resp = response.data.rstList
            this.searchedList = this.$_.cloneDeep(resp)
            // console.log(resp)
            // console.log(JSON.stringify(response.data.rstList[0]))
            this.total = resp.length //this.getTotalCnt(resp)
            this.rstList = this.$_.sortBy(resp, ['acshIssDt', 'blNo'])
            provider.setRows(this.rstList)
            gridView.setPage(-1)

            // console.log(this.rstList)
            this.currentPage = gridView.getPage() //현재 페이지 반환
            this.totalPage = gridView.getPageCount() //전체페이지 개수 반환

            if (this.total === 0) {
              this.openAlert(this.$t('msg.SETT010T010.129'))
            }
          })
          .catch(err => {
            this.isReqView = false
            console.log(err)
          })
      }
    },
    // 리스트 총 카운트 반환
    getTotalCnt (arr) {
      let blList = []
      for (let obj of arr) {
        if (blList.length === 0) {
          blList.push(obj)
        } else {
          let isExist = false
          for (let bl of blList) {
            if (obj.blNo === bl.blNo) {
              isExist = true
              break
            }
          }
          if (!isExist) {
            blList.push(obj)
          }
        }
      }
      return blList.length
    },
    // 외상매출금 조회 전 파라미터 체크
    optionChk: function () {
      const reg = /^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/

      if (this.params.fromDt === '') {
        this.openAlert(this.$t('msg.SETT010T010.152'))
        return false
      }
      if (this.params.toDt === '') {
        this.openAlert(this.$t('msg.SETT010T010.153'))
        return false
      }

      if (!reg.test(this.params.fromDt)) {
        this.openAlert(this.$t('msg.SETT010T010.151'))
        return false
      }
      if (!reg.test(this.params.toDt)) {
        this.openAlert(this.$t('msg.SETT010T010.151'))
        return false
      }

      var fromDt = new Date(this.unformattedFrom)
      var toDt = new Date(this.unformattedTo)

      if (fromDt > toDt) {
        return false
      }

      return true
    },
    // radioClick (r) {
    //   this.params.eiCatCd = r
    // },
    optionChange () {
      //this.params.dtKnd =
    },
    // 레이어 팝업 호출
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    // 레이어 팝업 종료
    closeFun () {
      this.$Simplert.close()
    },
    // 이전 페이지 호출
    setPrevPage: function () {
      gridView.setPage(this.currentPage - 1)
      this.currentPage = gridView.getPage()
    },
    // 다음 페이지 호출
    setNextPage: function () {
      gridView.setPage(this.currentPage + 1)
      this.currentPage = gridView.getPage()
    },
    // 엑셀 다운로드
    excelDownload () {
      const curDt = moment().format('YYYYMMDD')
      gridView.exportGrid({
        type: 'excel',
        target: 'local',
        documentTitle: { //제목
          message: this.$t('menu.MENU06.090'), // '외상매출금 조회',
          visible: true,
          spaceTop: 1,
          spaceBottom: 1,
          height: 60,
          styleName: 'excel-export-title-style'
        },
        applyDynamicStyles: true,
        exportTemplate: true,
        allColumns: true,
        pagingAllItems: true,
        fileName: `${this.$t('menu.MENU06.090')}_${curDt}.xlsx`,
        showProgress: false,
        progressMessage: this.$t('msg.ADD20220219.012'), // 엑셀 Export중입니다.
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          console.log('엑셀 다운로드 완료')
        }
      })
    }
  }
}

</script>

<style scoped>
  .text-column {
    text-align: left;
    cursor: pointer;
  }
  .number-column {
    text-align: right;
  }
</style>
