var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "1200px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ADD20220219.035"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("p", [
            _c("span", { staticClass: "mr10" }, [
              _vm._v(_vm._s(_vm.$t("msg.ADD20220219.036"))),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.comSettleCheck,
                  expression: "comSettleCheck",
                },
              ],
              attrs: { type: "radio", id: "com-settle-check-yes", value: "Y" },
              domProps: { checked: _vm._q(_vm.comSettleCheck, "Y") },
              on: {
                change: [
                  function ($event) {
                    _vm.comSettleCheck = "Y"
                  },
                  _vm.btnDisplay,
                ],
              },
            }),
            _c(
              "label",
              { staticClass: "mr20", attrs: { for: "com-settle-check-yes" } },
              [_c("span"), _vm._v(_vm._s(_vm.$t("msg.SETT060G010.020")))]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.comSettleCheck,
                  expression: "comSettleCheck",
                },
              ],
              attrs: { type: "radio", id: "com-settle-check-no", value: "N" },
              domProps: { checked: _vm._q(_vm.comSettleCheck, "N") },
              on: {
                change: [
                  function ($event) {
                    _vm.comSettleCheck = "N"
                  },
                  _vm.btnDisplay,
                ],
              },
            }),
            _c("label", { attrs: { for: "com-settle-check-no" } }, [
              _c("span"),
              _vm._v(_vm._s(_vm.$t("msg.SETT060G010.021"))),
            ]),
          ]),
          _c("p", { staticClass: "txt_desc" }, [
            _vm._v(_vm._s(_vm.$t("msg.ADD20220219.037"))),
          ]),
        ]),
        _c("div", { staticClass: "content_box cradit-transaction-settle" }, [
          _c("div", { staticClass: "tbl_form" }, [
            _c("div", { staticClass: "dv col_4" }, [
              _c("h3", { staticClass: "mb-1" }, [
                _vm._v(
                  " KMTC (" +
                    _vm._s(_vm.selectData.length) +
                    _vm._s(_vm.$t("msg.SETT060G010.008")) +
                    ", " +
                    _vm._s(
                      _vm.$ekmtcCommon.changeNumberFormat(_vm.selectTotal, {
                        isComma: true,
                      })
                    ) +
                    ") "
                ),
              ]),
              _c("div", {
                staticStyle: { width: "100%", height: "450px" },
                attrs: { id: "settle-realgrid" },
              }),
            ]),
            _c(
              "span",
              {
                staticClass:
                  "dv span-arrow text_center valign_middle wid100 pr5",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "tbl_icon arrow",
                    on: { click: _vm.copyData },
                  },
                  [_vm._v("arrow")]
                ),
              ]
            ),
            _c("div", { staticClass: "dv col_2" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("h3", { staticClass: "mb-1" }, [
                    _vm._v(_vm._s(_vm.$t("rad.VOSD100.021"))),
                  ]),
                ]),
                _c("div", { staticClass: "col-6 text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button sm",
                      attrs: { type: "button" },
                      on: { click: _vm.userDataAdd },
                    },
                    [_vm._v(" + ")]
                  ),
                ]),
              ]),
              _c("div", {
                staticStyle: { width: "100%", height: "450px" },
                attrs: { id: "user-realgrid" },
                on: { paste: _vm.onPaste },
              }),
            ]),
            _c("span", {
              staticClass: "dv span-arrow text_center valign_middle wid100 pr5",
            }),
            _c("div", { staticClass: "dv col_3" }, [
              _c("h3", { staticClass: "mb-1" }, [
                _vm._v(_vm._s(_vm.$t("msg.CMBA100.00276"))),
              ]),
              _vm._v(" "),
              _c("div", {
                staticStyle: { width: "100%", height: "450px" },
                attrs: { id: "match-realgrid" },
              }),
            ]),
          ]),
          _c("div", { staticClass: "tbl_form" }, [
            _c("div", { staticClass: "dv col_4" }),
            _c("span", {
              staticClass: "dv text_center valign_middle wid100 pr5",
            }),
            _c("div", { staticClass: "dv col_2" }, [
              _c("div", { staticClass: "mt10 text_right" }, [
                _c(
                  "button",
                  {
                    staticClass: "button sm",
                    attrs: {
                      type: "button",
                      disabled: !(_vm.comSettleCheck === "N"),
                    },
                    on: { click: _vm.matching },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("msg.ADD20220219.039")) + " ")]
                ),
              ]),
            ]),
            _c("span", {
              staticClass: "dv text_center valign_middle wid100 pr5",
            }),
            _c("div", { staticClass: "dv col_3" }, [
              _c("span", { staticStyle: { "font-size": "0.8rem" } }, [
                _vm._v("※ " + _vm._s(_vm.$t("msg.ADD20220219.038"))),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "mt10 text_right" },
          [
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn.id1,
                    expression: "btn.id1",
                  },
                ],
                staticClass: "ml-2",
                attrs: { variant: "primary", disabled: _vm.btnDisabled.id1 },
                on: { click: _vm.submitVerificationMaterials },
              },
              [_vm._v(" " + _vm._s(_vm.$t("msg.ONEX070G090.029")) + " ")]
            ),
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn.id2,
                    expression: "btn.id2",
                  },
                ],
                staticClass: "ml-2",
                attrs: { variant: "primary", disabled: _vm.btnDisabled.id2 },
                on: { click: _vm.confirmationAndIssuance },
              },
              [_vm._v(" " + _vm._s(_vm.$t("msg.ADD20220219.040")) + " ")]
            ),
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn.id3,
                    expression: "btn.id3",
                  },
                ],
                staticClass: "ml-2",
                attrs: { variant: "secondary" },
                on: { click: _vm.confirmCancel },
              },
              [_vm._v(" " + _vm._s(_vm.$t("msg.ONEX050P190.037")) + " ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }