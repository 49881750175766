<template>
  <!-- 정산/발행 >> Non-Freight Invoice -->
  <div>
    <div class="content_box beforetab">
      <!-- content_box  -->
      <div id="container_return_pdf_pop">
        <win-layer-pop class="pdf_download_pop">
          <component
            v-if="pdfCustomComponent"
            :is="pdfCustomComponent"
            @close="closePdfPopup"
            :parent-info="pdfPopupParams"
          />
        </win-layer-pop>
      </div>
      <table class="tbl_search">
        <colgroup>
          <col width="180px"><col width="150px"><col width="259px"><col width="*"><col width="100px">
        </colgroup>
        <tbody>
          <tr>
            <td class="pr5">
              <select v-model="params.dtKnd">
                <option value="IV">{{ $t('msg.SETT010T010.096') }}</option> <!-- Invoice No -->
              </select>
            </td>
            <td colspan="2" class="pl0">
              <div v-if="params.dtKnd === 'IV'">
                <input type="text" style="width:200px" v-model.trim="params.invNo" @keyup.enter="searchData()">
              </div>
            </td>
            <td class="text_right">
              <a class="button blue sh" @click="searchData()">{{ $t('msg.CMBA100.00120') }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- cpntent_box // -->

    <div class="content_box mt10">
      <!-- content_box  -->
      <div class="flex_box">
        <span class="tot_num">Total : <span class="num">{{ total }}</span></span>
      </div>
      <div id="nonfreight-realgrid" style="width: 100%; height: 450px"></div>
    </div><!-- content_box// -->
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import JSZip from 'jszip'
import moment from 'moment'
import CalcIssue from '@/api/rest/settle/calcIssue'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import { rootComputed } from '@/store/helpers'
import _lodash from 'lodash'
import store from '@/store/index'
import commons from '@/api/services/commons'
import doapi from '@/api/rest/trans/do'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'invIssNo', dataType: 'text' },
  { fieldName: 'invKndCd', type: 'hidden' },
  { fieldName: 'invKndNm', dataType: 'text' },
  { fieldName: 'issDt', dataType: 'text' },
  { fieldName: 'ctrCd', dataType: 'text' },
  { fieldName: 'plcCd', dataType: 'text' },
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'cstNo', dataType: 'text' },
  { fieldName: 'curCd', dataType: 'text' },
  { fieldName: 'locAmt', dataType: 'number' },
  { fieldName: 'dcAmt', dataType: 'number' },
  { fieldName: 'exrt', dataType: 'number' },
  { fieldName: 'usdAmt', dataType: 'number' },
  { fieldName: 'cnclYn', dataType: 'text' },
  { fieldName: 'cnclDt', dataType: 'text' },
  { fieldName: 'cnclUno', dataType: 'text' },
  { fieldName: 'rcvCpltYn', dataType: 'text' },
  { fieldName: 'rcvDt', dataType: 'text' },
  { fieldName: 'acshRno', dataType: 'text' },
  { fieldName: 'eiCatCd', dataType: 'text' },
  { fieldName: 'invoiceBtn', dataType: 'text' },
  { fieldName: 'agtOrgNo', type: 'hidden' }
]

export const columns = [
  { name: 'invIssNo', fieldName: 'invIssNo', type: 'text', header: { text: 'Invoice No.' }, editable: false, width: 80 },
  {
    name: 'invoiceBtn',
    fieldName: 'invoiceBtn',
    type: 'text',
    header: { text: 'Invoice' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const invIssNo = provider.getValue(idx, 'invIssNo') === null ? '' : provider.getValue(idx, 'invIssNo')
        const strInvIssNo = _lodash.trim(invIssNo)

        settleAppTab7.content.invIssNo = provider.getValue(idx, 'invIssNo')
        settleAppTab7.content.cstEnm = provider.getValue(idx, 'cstNo')
        settleAppTab7.content.eicatCd = provider.getValue(idx, 'eiCatCd')
        settleAppTab7.content.demDetCatCd = provider.getValue(idx, 'invKndCd')
        settleAppTab7.content.totAmt = provider.getValue(idx, 'locAmt')
        settleAppTab7.content.blNo = provider.getValue(idx, 'blNo')
        settleAppTab7.content.agtOrgNo = provider.getValue(idx, 'agtOrgNo')

        let str = '-'
        if (strInvIssNo === '') {
          str = ''
        } else {
          str = `<span class="tbl_icon invoice" style="cursor:pointer;" title="${strInvIssNo}" onClick="settleAppTab7.openPdfPopup(settleAppTab7.content)"></span>`
        }
        return str
      }
    },
    width: 60
  },
  { name: 'invKndNm', fieldName: 'invKndNm', type: 'text', header: { text: 'Kind' }, editable: false, width: 100 },
  { name: 'issDt', fieldName: 'issDt', type: 'text', header: { text: 'Issue Date' }, editable: false },
  { name: 'ctrCd', fieldName: 'ctrCd', type: 'text', header: { text: 'CN' }, editable: false },
  { name: 'plcCd', fieldName: 'plcCd', type: 'text', header: { text: 'Place' }, editable: false },
  { name: 'blNo', fieldName: 'blNo', type: 'text', header: { text: 'BL No.' }, editable: false },
  { name: 'cstNo', fieldName: 'cstNo', type: 'text', header: { text: 'Customer' }, editable: false, width: 200 },
  { name: 'curCd', fieldName: 'curCd', type: 'text', header: { text: 'CUR' }, editable: false, width: 80 },
  { name: 'locAmt', fieldName: 'locAmt', type: 'number', numberFormat: '#,##0.00', header: { text: 'Local Amount' }, editable: false, width: 80 },
  { name: 'dcAmt', fieldName: 'dcAmt', type: 'number', numberFormat: '#,##0.00', header: { text: 'D/C Amt' }, editable: false },
  { name: 'exrt', fieldName: 'exrt', type: 'number', numberFormat: '#,##0.00', header: { text: 'Exch Rate' }, editable: false },
  { name: 'usdAmt', fieldName: 'usdAmt', type: 'number', numberFormat: '#,##0.00', header: { text: 'USD Amt' }, editable: false },
  { name: 'cnclYn', fieldName: 'cnclYn', type: 'text', header: { text: 'Cancel' }, editable: false },
  { name: 'cnclDt', fieldName: 'cnclDt', type: 'text', header: { text: 'Cancel Date' }, editable: false },
  { name: 'cnclUno', fieldName: 'cnclUno', type: 'text', header: { text: 'Cancel User' }, editable: false, width: 80 },
  { name: 'rcvCpltYn', fieldName: 'rcvCpltYn', type: 'text', header: { text: 'Pay Complete' }, editable: false, width: 80 },
  { name: 'rcvDt', fieldName: 'rcvDt', type: 'text', header: { text: 'Pay Date' }, editable: false },
  { name: 'acshRno', fieldName: 'acshRno', type: 'text', header: { text: 'Receipt No.' }, editable: false, width: 140 },
  { name: 'eiCatCd', fieldName: 'eiCatCd', type: 'text', header: { text: 'InOut' }, editable: false }
]

export default {
  name: 'CalcIssueNonFreight',
  components: {
    CalcIssue,
    EBreadcrumbs,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'container-return-pop': () => import('@/pages/pdf/popup/ContainerReturnPop')
  },
  props: {
    paramData: {
      type: Object,
      default () {
        return {
          tab: '',
          keyword: '',
          dtKnd: '',
          eiCateCd: ''
        }
      }
    }
  },
  data: function () {
    return {
      // PDF 다운로드 팝업 컴포넌트
      pdfCustomComponent: null,
      pdfPopupParams: {},
      total: 0,
      dtType: {
        options: [
          { value: 'IV', text: this.$t('msg.SETT010T010.096') }
        ]
      },
      content: {
        invIssNo: '',
        cstEnm: '',
        eicatCd: '',
        demDetCatCd: '',
        totAmt: '',
        blNo: '',
        agtOrgNo: ''
      },
      params: {
        dtKnd: 'IV'
      }
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {

  },
  created () {
    console.log('App', app.$t('tit.SETTLE.TAB01.GRID_HEADER.001'))
    window.functionOutsideVue(this)
    window.settleAppTab7 = this
    this.userDmdCatCd = this.$store.state.settle.userDmdCatCd
  },
  mounted: function () {
    // this.setDateRange()
    //
    window.vmApp = this

    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip

    provider = new LocalDataProvider(true)
    gridView = new GridView('nonfreight-realgrid')

    // 그리드 필드 생성
    provider.setFields(fields)

    // columns 명
    gridView.setColumns(columns)
    //gridView.setColumnLayout(gridLayout)
    gridView.setDataSource(provider)

    // 그리고 헤더 높이 조정
    gridView.header.heights = [40]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'none'
    gridView.displayOptions.selectionStyle = 'block'

    gridView.setFooter({ visible: false })
    gridView.setStateBar({ visible: false })
    gridView.setRowIndicator({ visible: false })
    //체크바 없애기
    gridView.setCheckBar({ visible: false })
   },
  methods: {
    openPdfPopup (content) {
      if (this.pdfCustomComponent !== null) {
        this.pdfCustomComponent = null
        this.$ekmtcCommon.layerClose()
      }

      content.title = 'Non-Freight Invoice'

      this.pdfCustomComponent = 'container-return-pop'
      this.pdfPopupParams = content

      this.$ekmtcCommon.layerOpen('#container_return_pdf_pop > .popup_dim')
    },
    // PDF 다운로드 팝업 닫기
    closePdfPopup () {
      this.pdfCustomComponent = null
      this.$ekmtcCommon.layerClose('#container_return_pdf_pop > .popup_dim')
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    async searchData () {
      if (this.params.invNo === '' || this.params.invNo === undefined) {
        this.openAlert('Invoice 번호를 입력하세요.')
        return
      }

      let param = {
        invNo: this.params.invNo
      }

      this.rstList = []
      this.rstFilter = []

      console.log(JSON.stringify(this.params))
      await CalcIssue.selectNonFreightInvoice(param).then(response => {
        if (response.data !== '') {
          const resp = response.data
          this.rstList.push(resp)
          this.rstFilter = resp
          this.total = this.rstList.length
          provider.setRows(this.rstList)
        } else {
          this.total = 0
          provider.setRows(this.rstList)
          this.openAlert(this.$t('msg.SETT010T010.129'))
        }
      }).catch(err => {
          console.log(err)
      })
    }
  }
}

</script>
