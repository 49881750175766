<template>
  <div id="frm">
    <win-layer-pop class="inquiry_surcharge_pop">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        :parent-info="surChargeParams"
      />
    </win-layer-pop>
    <div class="content_box beforetab">
      <table class="tbl_row">
        <colgroup>
          <col width="140"><col><col width="130"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.FARE010T010.001') }}</th><!-- 출발 -->
            <td>
              <e-auto-complete-place
                id="polPol"
                @change="changePol"
                :ctr-cd="params.porCtrCd"
                :plc-cd="params.porPlcCd"
                :is-char="true"
                :is-char-alert="true"
              />
            </td>
            <th>{{ $t('msg.FARE010T010.002') }}</th><!-- 도착 -->
            <td>
              <e-auto-complete-place
                id="podPod"
                @change="changePod"
                :ctr-cd="params.dlyCtrCd"
                :plc-cd="params.dlyPlcCd"
                :is-char="true"
                :is-char-alert="true"
              />
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.FARE010T010.003') }}</th><!-- 예상 출항일 -->
            <td colspan="3">
              <div class="input_calendar">
                <e-date-picker
                  v-model="params.etd"
                  @input="fnValidationEtd()"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex_box mt10">
      <a class="button blue lg ml_auto" href="#" @click.prevent="fnSearchSurCharge()">{{ $t('msg.FARE010T010.010') }}</a><!-- 확인 -->
    </div>
  </div>
</template>

<script>

import { rootComputed } from '@/store/helpers'

import schedule from '@/api/rest/schedule/schedule'

export default {
  name: 'ShippingCostInquiry',
  components: {
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'fre-surcharge-pop': () => import('@/pages/schedule/popup/FreSurchargePop'),
    'fre-surcharge-pop2': () => import('@/pages/schedule/popup/FreSurchargePop2'),
    'request-quote-pop': () => import('@/pages/schedule/popup/RequestQuotePop'),
    'e-auto-complete-place': () => import('@/components/common/EAutoCompletePlace'),
    'e-date-picker': () => import('@/components/common/EDatePicker')
  },
  data () {
    return {
      customComponent: null,
      params: {
        // [s] 검색조건 ==============================
        // 출발 국가
        porCtrCd: '',
        // 출발 지역
        porPlcCd: '',
        // 출발지 명
        polNm: '',
        // 도착 국가
        dlyCtrCd: '',
        // 도착 지역
        dlyPlcCd: '',
        // 도착지 명
        podNm: '',
        // 예상 출항일
        etd: '',
        // Freight Term(Prepaid(P)/Collect(C))
        frtTerm: 'P',
        // 실화주 코드
        bkgShprCstNo: '',
        // 수-출입 구분 코드
        eiCatCd: '',
        // [e] 검색조건 ==============================

        // 영업사원 정보
        salNm: '',
        salTelNo: '',
        salUno: '',
        salEmlAddr: '',
        freightMenuchk: 'Y'
      },
      // 운임 팝업을 띄우기 위해 필요한 데이터
      surChargeParams: {},
      kmtcSpotUserYn: 'N',
      relYn: true // JamSin
    }
  },
  watch: {
    'params.etd': function () {
      this.fnValidationEtd()
    }
  },
  created () {
    // 로그인한 경우 KMTC SPOT 대상 조회
    if (this.auth.login) {
      this.getKmtcSpotTarget()
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  computed: {
    ...rootComputed
  },
  methods: {
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter') {
          this.fnSearchSurCharge()
        }
      }
    },
    // '출발' 자동완성 change event
    changePol (vo) {
      this.params.porCtrCd = vo.ctrCd
      this.params.porPlcCd = vo.plcCd
      this.params.polNm = vo.plcEnm
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#polPol')
      this.$ekmtcCommon.hideErrorTooltip(elem)
      this.fnSalUnoSet()
    },
    // '도착' 자동완성 change event
    changePod (vo) {
      this.params.dlyCtrCd = vo.ctrCd
      this.params.dlyPlcCd = vo.plcCd
      this.params.podNm = vo.plcEnm
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
      this.fnSalUnoSet()
    },
    // 영업사원 설정
    async fnSalUnoSet () {
      const porCd = this.params.porCtrCd
      const dlyCd = this.params.dlyCtrCd

      if (this.$ekmtcCommon.isEmpty(porCd) || this.$ekmtcCommon.isEmpty(dlyCd)) {
        return
      }

      const tmpParam = {
        porCd: porCd,
        dlyCd: dlyCd
      }

      await schedule.findSalUno(tmpParam).then((response) => {
          console.log('response @@@@@ ', response)

          if (response.status === 200) {
            const respData = response.data

            if (respData.eiCatCd !== undefined) {
              this.params.eiCatCd = respData.eiCatCd

              const eiCatCd = this.params.eiCatCd
              if (eiCatCd === 'O' || eiCatCd === 'OJ' || eiCatCd === 'OJRF') {
                this.params.frtTerm = 'P'
              } else if (eiCatCd === 'I') {
                this.params.frtTerm = 'C'
              }
            }
            if (respData.salNm !== undefined) {
              this.params.salNm = respData.salNm
            }
            if (respData.salTelNo !== undefined) {
              this.params.salTelNo = respData.salTelNo
            }
            if (respData.salUno !== undefined) {
              this.params.salUno = respData.salUno
            }
            if (respData.salEmlAddr !== undefined) {
              this.params.salEmlAddr = respData.salEmlAddr
            }
          }
        })
    },
    async getCntrTypeMessage () {
      let message = this.$t('msg.CSCT060G040.010') + '<br><br>'

      message += '<div class="d_flex">'
      message += '  <div class="inp_space30">Type : </div>'
      message += '  <div class="inp_space70">'
      message += '  <select id="popCntrTypCd">'
      message += '  <option value="">' + this.$t('msg.CSBL200.075') + '</option>'
      message += '  <option value="GP">Dry</option>'
      message += '  <option value="RF">Reefer</option>'
      message += '  <option value="FR">Flat Rack</option>'
      message += '  <option value="OT">Open Top</option>'
      message += '  </select>'
      message += '  </div>'
      message += '</div>'

      let rtnVal = ''
      const isOk = await this.$ekmtcCommon.asyncAlertMessage({
        message: message,
        useConfirmBtn: true,
        onConfirmSelf: (resolve, $Simplert) => {
          const popCntrTypCd = document.querySelector('#popCntrTypCd')

          if (this.$ekmtcCommon.isEmpty(popCntrTypCd.value)) {
            this.$ekmtcCommon.showErrorTooltip(popCntrTypCd, this.$t('msg.CSBK100.147'))
          } else {
            rtnVal = popCntrTypCd.value
            resolve(true)
            $Simplert.close()
          }
        }
      })

      return {
        isOk: isOk,
        rtnVal: rtnVal
      }
    },
    async fnFrtConfirm () {
      const params = this.params
      const reqParams = {
        porCtrCd: params.porCtrCd,
        porPlcCd: params.porPlcCd,
        dlyCtrCd: params.dlyCtrCd,
        dlyPlcCd: params.dlyPlcCd,
        etd: params.etd,
        eiCatCd: params.eiCatCd
      }

      const cntrMsgInfo = await this.getCntrTypeMessage()

      if (cntrMsgInfo.isOk) {
        reqParams.cntrTypCd = cntrMsgInfo.rtnVal

        await schedule.fnPopFreReq(reqParams).then((response) => {
          const result = response.data.insertRateMngResult
          let msg = ''
          if (result !== undefined && result === 1) {
            //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
            msg = this.$t('msg.FARE010T010.025') // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.

            const confirmOpt = {
              alertType: 'simple',
              customCloseBtnText: this.$t('msg.VOSD100_M5.004'), // 닫기
              customConfirmBtnText: this.$t('msg.VOSD100_M5.003'), // 확인
              useConfirmBtn: true,
              message: `${this.$t('msg.FARE010T010.025')}\n${this.$t('msg.FARE010T010.026')}`, // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.\n영업 사원에게 문의 하시겠습니까?
              onConfirm: this.sendSalEmail
            }

            this.$ekmtcCommon.alert(confirmOpt)
          } else if (result !== undefined && result === 2) { // 등록된 운임이 있음
            msg = this.$t('msg.FARE010T010.024')
            this.$ekmtcCommon.alertDefault(msg)
          } else if (result !== undefined && result === 3) { // 요청중인 내역이 있는경우 메시지만 동일하게 출력
            //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
            msg = this.$t('msg.FARE010T010.025') // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.

            const confirmOpt = {
              alertType: 'simple',
              customCloseBtnText: this.$t('msg.VOSD100_M5.004'), // 닫기
              customConfirmBtnText: this.$t('msg.VOSD100_M5.003'), // 확인
              useConfirmBtn: true,
              message: `${this.$t('msg.FARE010T010.025')}\n${this.$t('msg.FARE010T010.026')}`, // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.\n영업 사원에게 문의 하시겠습니까?
              onConfirm: this.sendSalEmail
            }

            this.$ekmtcCommon.alert(confirmOpt)
          } else {
            msg = this.$t('msg.FARE010T010.023') // 처리 중 오류가 발생하였습니다. 시스템 관리자에게 문의해 주세요.
            this.$ekmtcCommon.alertDefault(msg)
          }
        })
      }
    },
    // 운임 관련 담당 영업사원 이메일 전송
    sendSalEmail () {
      const param = {
        polCtrCd: this.params.porCtrCd,
        podCtrCd: this.params.dlyCtrCd,
        polPortCd: this.params.porPlcCd,
        podPortCd: this.params.dlyPlcCd,
        salEmlAddr: this.params.salEmlAddr
      }

      schedule.sendSalEmail(param).then(async (response) => {
          console.log('sendSalEmail response @@@@@ ', response)

          if (response.headers.respmsg === 'SUCC') {
            this.$ekmtcCommon.alertDefault(this.$t('art.CMATK146')) // 메일이 전송되었습니다.
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('mgs.SETT060G010.049')) // 전송에 실패했습니다.
          }
        })
        .catch(err => {
          console.log(err)
          this.$ekmtcCommon.alertDefault(this.$t('mgs.SETT060G010.049')) // 전송에 실패했습니다.
        })
    },
    // 운임 검색 ('확인'버튼 이벤트)
    async fnSearchSurCharge () {
      // 검색조건 전체 validation check
      if (this.fnValidationAll()) {
        // R/A No 조회
        await schedule.popFreAppNo(this.params).then(async (response) => {
          if (response.status === 200) {
            const respData = response.data

            const frtResult = this.$ekmtcCommon.isEmpty(respData.frtResult) ? '' : respData.frtResult
            const frtAppNo = this.$ekmtcCommon.isEmpty(respData.frtAppNo) ? '' : respData.frtAppNo
            const reqRno = this.$ekmtcCommon.isEmpty(respData.reqRno) ? '' : respData.reqRno
            const strDt = this.$ekmtcCommon.isEmpty(respData.strDt) ? '' : respData.strDt
            const endDt = this.$ekmtcCommon.isEmpty(respData.endDt) ? '' : respData.endDt

            this.surChargeParams = {
              frtResult: frtResult,
              frtAppNo: frtAppNo,
              reqRno: reqRno,
              startDt: strDt,
              endDt: endDt,
              kmtcSpotUserYn: this.kmtcSpotUserYn
            }

            const oonlYn = this.memberDetail.oonlYn // 수출영업(Export) 기준으로 온라인 견적 대상 화주인 경우
            const jonlYn = this.memberDetail.jonlYn // 일본영업 기준으로 온라인 견적 대상 화주인 경우
            const ionlYn = this.memberDetail.ionlYn // 수입영업(Import) 기준으로 온라인 견적 대상 화주인 경우
            const oengOnlYn = this.memberDetail.oengOnlYn
            const userCtrCd = this.memberDetail.userCtrCd

            let rmPopOpenFlag = false

            // ****************************** [s] 온라인 견적 적용 조건 ******************************
            if (oonlYn === 'Y' && jonlYn === 'Y') {
              // 1) 수출영업(Export) 과 일본영업에 대해서 모두 온라인 견적 대상 화주인 경우
              if (this.params.porCtrCd === 'KR') {
                // POL (출발항) 에 KR 소재의 port 를 입력하면
                // 온라인 견적이 적용
                rmPopOpenFlag = true
              }
              if (this.params.porCtrCd === 'KR' && this.params.dlyCtrCd === 'JP') {
                rmPopOpenFlag = true
              }
              if (this.params.porCtrCd === 'JP' && this.params.dlyCtrCd === 'KR') {
                rmPopOpenFlag = true
              }
            } else {
              if (oonlYn === 'Y') {
                // 2) 수출영업(Export) 기준으로 온라인 견적 대상 화주인 경우
                if (this.params.porCtrCd === 'KR' && this.params.dlyCtrCd !== 'JP') {
                  // POL (출발항) 에 KR 소재의 port 를 입력하고
                  // POD (도착항) 에 JP (일본) 를 '제외한' 국가의 port 를 입력하는 경우에만
                  // 온라인 견적이 적용
                  rmPopOpenFlag = true
                }
              } else if (jonlYn === 'Y') {
                // 3) 일본영업 기준으로 온라인 견적 대상 화주인 경우
                if (this.params.porCtrCd === 'KR' && this.params.dlyCtrCd === 'JP') {
                  // 3-1)
                  // POL (출발항) 에 KR 소재의 port 를 입력하고
                  // POD (도착항) 에 JP (일본)의 port 를 입력하는 경우에만
                  // 온라인 견적이 적용
                  rmPopOpenFlag = true
                }
                if (this.params.porCtrCd === 'JP' && this.params.dlyCtrCd === 'KR') {
                  // 3-2)
                  // POL (출발항) 에 JP (일본) 의 port 를입력하고
                  // POD (도착항) 에 KR 소재의 port 를 입력하는 경우
                  // 온라인 견적이 적용
                  rmPopOpenFlag = true
                }
              }
            }

            if (ionlYn === 'Y') {
              if (this.params.porCtrCd !== 'JP' && this.params.dlyCtrCd === 'KR') {
                rmPopOpenFlag = true
              }
            }

            if (oengOnlYn === 'Y' && userCtrCd !== 'KR') {
              rmPopOpenFlag = true
            }
            // ****************************** [e] 온라인 견적 적용 조건 ******************************

            if (frtResult === 'Y') {
              // ===========================================
              // [1] R/A No(Rate Application) 정보가 있는 경우
              // ===========================================

              const rmOpenFlag = rmPopOpenFlag ? 'Y' : 'N'

              this.surChargeParams = { ...this.surChargeParams, ...this.params, ...{ rmPopOpenFlag: rmOpenFlag } }

              // 운임확인 팝업
              this.openPopup('fre-surcharge-pop')
            } else if (frtResult === 'A') {
              // =========================================================================
              // [2] R/A(Rate Application) 정보가 없고, R/M(Rate Management) 정보가 있는 경우
              // =========================================================================

              if (rmPopOpenFlag) {
                // 온라인 견적이 적용된 경우
                this.surChargeParams = { ...this.surChargeParams, ...this.params }
                this.openPopup('fre-surcharge-pop2')
              } else {
                // 온라인 견적이 적용되지 않은 경우 - 일반화주
                // "문의하신 구간에 등록된 운임이 없습니다. 견적 문의를 요청하시겠습니까?" 팝업 띄움
                // 예 - 운임문의 팝업(SCHD010P080)
                // 아니오 - Alert 닫기

                const TH = this
                // 문의하신 구간에 등록된 운임이 없습니다. 견적 문의를 요청하시겠습니까?
                if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.FARE010T010.021'), useConfirmBtn: true })) {
                  TH.surChargeParams = TH.params
                  TH.openPopup('request-quote-pop')
                }
              }
            } else if (frtResult === 'N') {
              // =================================================================
              // [3] R/A(Rate Application), R/M(Rate Management) 정보 모두 없는 경우
              // =================================================================

              if (rmPopOpenFlag) {
                // 온라인 견적이 적용된 경우
                this.fnFrtConfirm()
              } else {
                // 온라인 견적이 적용되지 않은 경우 - 일반화주
                // "문의하신 구간에 등록된 운임이 없습니다. 견적 문의를 요청하시겠습니까?" 팝업 띄움
                // 예 - 운임문의 팝업(SCHD010P080)
                // 아니오 - Alert 닫기

                const TH = this
                // 문의하신 구간에 등록된 운임이 없습니다. 견적 문의를 요청하시겠습니까?
                if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.FARE010T010.021'), useConfirmBtn: true })) {
                  TH.surChargeParams = TH.params
                  TH.openPopup('request-quote-pop')
                }
              }
            }
          }
        })
      }
    },
    // '확인'버튼 클릭시 검색조건 전체 validation check
    fnValidationAll () {
      const frm = document.querySelector('#frm')
      let isAllOk = true

      // 출발지 validation check
      if (!this.fnValidationPor(frm)) {
        isAllOk = false
      }
      // 도착지 validation check
      if (!this.fnValidationDly(frm)) {
        isAllOk = false
      }
      // 예상 출항일 validation check
      if (!this.fnValidationEtd(frm)) {
        isAllOk = false
      }

      return isAllOk
    },
    // 출발지 validation check
    fnValidationPor (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelectorAll('#polPol')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.porCtrCd) || this.$ekmtcCommon.isEmpty(this.params.porPlcCd)) {
        msg = this.$t('msg.FARE010T010.013') // 출발지를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 도착지 validation check
    fnValidationDly (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelectorAll('#podPod')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.dlyCtrCd) || this.$ekmtcCommon.isEmpty(this.params.dlyPlcCd)) {
        msg = this.$t('msg.FARE010T010.014') // 도착지를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 예상 출항일 validation check (오늘 날짜 + 60일을 초과할 수 없음.)
    fnValidationEtd (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelector('.datepicker')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.etd)) {
        msg = this.$t('msg.FARE010T010.015') // 예상 출항일을 선택해 주세요.
        isOk = false
      } else {
        const etd = this.$ekmtcCommon.getStrToDate(this.params.etd)
        const today = new Date()

        if (this.$ekmtcCommon.getDateToStrDelim(etd) < this.$ekmtcCommon.getDateToStrDelim(today)) {
          msg = this.$t('msg.FARE010T010.016') // 예상 출항일이 오늘 날짜보다 작을 수 없습니다.
          isOk = false
        } else if (today.setDate(today.getDate() + 60) < etd) {
           msg = this.$t('msg.FARE010T010.017') // 예상 출항일이 + 60일을 초과할 수 없습니다.
          isOk = false
        }
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    openPopup (compNm) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.inquiry_surcharge_pop')
      }

      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.inquiry_surcharge_pop')
    },
    closePopup (obj) {
      if (obj && obj.negoYN) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.inquiry_surcharge_pop')

        this.customComponent = 'request-quote-pop'
        this.$ekmtcCommon.layerOpen('.inquiry_surcharge_pop')
      } else {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.inquiry_surcharge_pop')
      }
    },
    getKmtcSpotTarget () {
      //menuCD 01은 로그를 남기지 않음. kmtc on 대상 여부만 조회
      schedule.getKmtcSpotTarget('01').then((response) => {
        this.kmtcSpotUserYn = response.data[0].kmtcSpotUserYn
      })
    }
  }
}

</script>
