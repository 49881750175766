<template>
  <div class="content resize">
    <!-- content  -->

    <div class="location">
      <!-- location -->
      <ul>
        <li><a class="home">home</a></li>
        <li><a @click.prevent="goHome">Home</a></li>
        <li><a @click.prevent="selectTab('1')">{{ $t('menu.MENU06.010') }}</a></li>
        <!-- <li>{{ $t('menu.MENU06.020') }}</li> -->
        <li>{{ curTabName }}</li>
      </ul>
    </div><!-- location -->

    <h1 class="page_title">{{ $t('menu.MENU06.010') }}</h1>

    <div class="tab" :class="{ 'kor-tab': auth.serviceLang === 'KOR' }">
      <!-- tab -->
      <ul>
        <li v-if="isShowTab.tab1" class="on" id="tab1" @click="selectTab('1')"><a href="javascript:void(0)">{{ $t('menu.MENU06.020') }}</a></li>
        <li v-if="memberDetail.userCtrCd === 'KR' && isShowTab.tab2" id="tab2" @click="selectTab('2')"><a href="javascript:void(0)">{{ $t('menu.MENU06.030') }}</a></li>
        <li v-if="memberDetail.userCtrCd === 'KR' && isShowTab.tab3" id="tab3" @click="selectTab('3')"><a href="javascript:void(0)">{{ $t('menu.MENU06.040') }}</a></li>
        <li v-if="memberDetail.userCtrCd === 'KR' && isShowTab.tab4" id="tab4" @click="selectTab('4')"><a href="javascript:void(0)">{{ $t('msg.ONEX070G040.007') }}</a></li>
        <li v-if="memberDetail.userCtrCd === 'KR' && isShowTab.tab5" id="tab5" @click="selectTab('5')"><a href="javascript:void(0)">{{ $t('menu.MENU06.090') }}</a></li>
        <li v-if="memberDetail.userCtrCd === 'KR' && isShowTab.tab6" id="tab5" :class="{ on: tab6 }" @click="selectTab('6')"><a href="javascript:void(0)">{{ $t('menu.MENU06.050') }}</a></li>
        <li v-if="isShowTab.tab7" id="tab7" @click="selectTab('7')"><a href="javascript:void(0)">{{ $t('menu.MENU06.070') }}</a></li>
        <li v-if="isShowTab.tab8" id="tab8" @click="selectTab('8')"><a href="javascript:void(0)">{{ $t('menu.MENU06.100') }}</a></li>
        <li v-if="memberDetail.userCtrCd === 'ID' && isShowTab.tab9" id="tab9" @click="selectTab('9')"><a href="javascript:void(0)">{{ $t('menu.MENU06.110') }}</a></li>
        <div v-if="this.curTabName === $t('menu.MENU06.070')" style="display: block; text-align: right;">
          <!-- 탭 이름이 'DEM/DET/Freetime'인 경우만 노출 -->
          <a class="button sm" href="javascript:void(0)" @click="openPopup('DemDetTariffPop', null)">DEM/DET Tariff</a><!-- Free Time Tariff -->
        </div>
      </ul>
    </div><!-- tab // -->
    <div v-if="isShowTab.tab1" id="cnt1" name="cnt">
      <!-- 발행 -->
      <calc-issue-list
        :param-data="paramData"
        @moveFreetime="moveFreetime"
      ></calc-issue-list>
    </div>
    <div v-if="memberDetail.userCtrCd === 'KR' && isShowTab.tab2" id="cnt2" name="cnt" v-show="false">
      <!-- 신용거래건정리 -->
      <credit-transaction-inq-list />
    </div>
    <div v-if="isShowTab.tab3" id="cnt3" name="cnt" v-show="false">
      <!-- 정리내역조회 -->
      <calc-issue-detail></calc-issue-detail>
    </div>
    <div v-if="memberDetail.userCtrCd === 'KR' && isShowTab.tab4" id="cnt4" name="cnt" v-show="false">
      <!-- 세금계산서 -->
      <calc-issue-detail-ex></calc-issue-detail-ex>
    </div>
    <div v-if="memberDetail.userCtrCd === 'KR' && isShowTab.tab5" id="cnt5" name="cnt" v-show="false">
      <!-- 외상매출금조회 -->
      <calc-issue-credit-sales></calc-issue-credit-sales>
    </div>
    <div v-if="isShowTab.tab6" id="cnt6" name="cnt" :style="{ display: [ tab6 ? 'block' : 'none' ] }">
      <calc-issue-limit></calc-issue-limit>
    </div>
    <div v-if="isShowTab.tab7" id="cnt7" name="cnt" v-show="false">
      <free-time-request :parent-data="paramData"></free-time-request>
    </div>
    <div v-if="isShowTab.tab8" id="cnt8" name="cnt" v-show="false">
      <calc-issue-non-freight></calc-issue-non-freight>
    </div>
    <div v-if="isShowTab.tab9" id="cnt9" name="cnt" v-show="false">
      <calc-issue-repair-restitution></calc-issue-repair-restitution>
    </div>
    <div id="freetime_fn_pop1">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
        />
      </win-layer-pop>
    </div>
  </div><!-- content // -->
</template>

<script>

import CalcIssueList from '@/pages/settle/CalcIssueList'
import CreditTransactionInqList from '@/pages/settle/CreditTransactionInqList'
import CalcIssueLimit from '@/pages/settle/CalcIssueLimit'
import CalcIssueDetail from '@/pages/settle/CalcIssueDetail'
import CalcIssueDetailEx from '@/pages/settle/CalcIssueDetailEx'
import CalcIssueCreditSales from '@/pages/settle/CalcIssueCreditSales'
import FreeTimeRequest from '@/pages/settle/FreeTimeRequest'
import DemDetTariffPop from '@/pages/settle/popup/DemDetTariffPop'
import CalcIssueNonFreight from '@/pages/settle/CalcIssueNonFreight'
import CalcIssueRepairRestitution from '@/pages/settle/CalcIssueRepairRestitution'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'CalcIssueMain',
  components: {
    CalcIssueList,
    CalcIssueLimit,
    CalcIssueDetail,
    CalcIssueDetailEx,
    CreditTransactionInqList,
    CalcIssueCreditSales,
    FreeTimeRequest,
    DemDetTariffPop,
    CalcIssueNonFreight,
    CalcIssueRepairRestitution,
    WinLayerPop
  },
  data () {
    return {
      isShowTab: {
        tab1: true,
        tab2: false,
        tab3: true,
        tab4: true,
        tab5: false,
        tab6: false,
        tab7: false,
        tab8: true,
        tab9: true
      },
      userDmdInfo: {
        imDmdCatCd: 'R',
        etDmdCatCd: 'R'
      },
      eiCatCd: 'O',
      userDmdCatCd: 'R',
      eiCates: [
        {
          code: 'M',
          value: 100
        },
        {
          code: 'C',
          value: 50
        },
        {
          code: 'R',
          value: 50
        }
      ],
      // tabNames: ['발행', '신용거래건 정리', '정리내역 조회', '세금계산서', 외상매출금 조회', '신용한도 조회', 'Non-Freight Invoice'],
      tabNames: [this.$t('msg.ONEX070G040.027'), this.$t('menu.MENU06.030'), this.$t('menu.MENU06.040'), this.$t('msg.ONEX070G040.007'), this.$t('menu.MENU06.090'), this.$t('menu.MENU06.050'), this.$t('menu.MENU06.070'), this.$t('menu.MENU06.100')],
      curTabName: this.$t('menu.MENU06.020'),
      paramData: {},
      customComponent: null,
      tab6: false
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    '$store.state.settle.userDmdCatCd' () {
      this.userDmdCatCd = this.$store.state.settle.userDmdCatCd
      this.initTab()
    },
    '$store.state.settle.tabIndex' () {
      this.selectTab(this.$store.state.settle.tabIndex)
    }
  },
  created () {
    if (this.auth.login) {
      this.$store.dispatch('settle/saveUserDmdCatCd')
      this.userDmdCatCd = this.$store.state.settle.userDmdCatCd
    } else {
      this.$router.push('/').catch(() => {})
    }
  },
  mounted () {
    this.initTab()

    let tabIndex = this.$store.state.settle.tabIndex
    if (tabIndex != null) {
        setTimeout(() => {
          const vo = this.$route.params
          if (this.$ekmtcCommon.isEmpty(vo.tab)) {
            this.selectTab(tabIndex)
          }
        }, 300)
    }
    this.$store.commit('settle/updateTabIndex', null)
    this.requestView()
  },
  methods: {
    moveFreetime () {
      console.log('calcmai moveFreetime')
      this.selectTab('7')
    },
    requestView () {
      const vo = this.$route.params
      if (vo.tab === '1') {
        this.paramData = vo
        this.selectTab('1')
      }

      if (vo.tab === '7') {
        this.paramData.isCalc = true
        this.paramData.isWorking = false
      }
    },
    initTab () {
      this.paramData.isCalc = true
      this.paramData.isWorking = false

      this.isShowTab.tab7 = true

      if (this.userDmdCatCd === 'R') { //영수화주
        this.isShowTab.tab1 = true
        this.isShowTab.tab2 = false
        this.isShowTab.tab3 = false
        this.isShowTab.tab4 = true
        this.isShowTab.tab5 = false
        this.isShowTab.tab6 = false
        this.isShowTab.tab7 = true
        this.isShowTab.tab8 = true
        this.isShowTab.tab9 = true
      } else if (this.userDmdCatCd === 'C') { //청구화주
        this.isShowTab.tab1 = true
        this.isShowTab.tab2 = false
        this.isShowTab.tab3 = false
        this.isShowTab.tab4 = true
        this.isShowTab.tab5 = true
        this.isShowTab.tab6 = false
        this.isShowTab.tab7 = true
        this.isShowTab.tab8 = true
        this.isShowTab.tab9 = true
      } else if (this.userDmdCatCd === 'M') { // 월말화주
        this.isShowTab.tab1 = true
        this.isShowTab.tab2 = true
        this.isShowTab.tab3 = true
        this.isShowTab.tab4 = true
        this.isShowTab.tab5 = true
        this.isShowTab.tab6 = false
        this.isShowTab.tab7 = true
        this.isShowTab.tab8 = true
        this.isShowTab.tab9 = true
      }

      if (this.memberDetail.userCtrCd !== 'KR') {
        // 해외는 월말청구 메뉴 사용하지 않음
        this.isShowTab.tab2 = false
      }
    },
    selectTab (tab) {
      this.$store.commit('settle/updateTabIndex', tab)

      if (tab === '6') {
        this.tab6 = true
      } else {
        this.tab6 = false
      }

      if (tab === '7') {
        this.paramData.isCalc = true
        this.paramData.isWorking = false
      }

      $('.tab li').removeClass('on')
      $('div[name=cnt]').hide()

      $('#tab' + tab).addClass('on')
      $('#cnt' + tab).show()

      if (parseInt(tab) >= 1 && parseInt(tab) <= 8) {
        this.curTabName = this.tabNames[parseInt(tab) - 1]
      }
    },
    goHome () {
      this.$router.push({ path: '/main' })
    },
    goServiceMain () {
      this.$router.push({ path: '/calcIssue/calcIssueMain' })
    },
    openPopup (compNm, idx) {
      let divId = '#freetime_fn_pop1'
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen(divId + ' > .popup_dim')
    },
    closePopup (obj) {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('#freetime_fn_pop1 > .popup_dim')
    }
  }
}

</script>
<style lang="scss" scoped>
.kor-tab.tab {
  & li a {
    padding: 0 33px;
  }
}
</style>
