<template> <!-- 정산/발행 >> 정리내역 조회 -->
  <div>
    <div class="content_box beforetab">
      <!-- content_box  -->
      <div id="layerDetailPop">
        <win-layer-pop>
          <component
            v-if="customComponent"
            :is="customComponent"
            :detail="detail"
            :ok-hidden="isHidden"
            :is-show-pop="isShowPop"
            :freight-bill-info="freightBillInfo"
            @close="closePopup"
          />
        </win-layer-pop>
      </div>
      <div>
        <!-- 팝업  -->
        <win-layer-pop class="sample_popup_list">
          <component
            v-if="customComponent"
            :is="customComponent"
            :settle-data="selectData"
            :s-date="''"
            :e-date="''"
            :po-paramiters="poParamiters"
            @close="closePopup2"
          />
        </win-layer-pop>
      </div>
      <table class="tbl_search">
        <colgroup>
          <col width="180px"><col width="150px"><col width="259px"><col width="*"><col width="100px">
        </colgroup>
        <tbody>
          <tr>
            <td class="pl0 pr0">
              <input type="radio"
                     id="calc-issue-detail-exim01"
                     name="calc-issue-detail-exim"
                     v-model="params.eiCatCd"
                     value="O"
                     @change="changeCalcEiCatCd()"
                     :disabled="calcEtDmdCatCd !== 'M'"
              >
              <label for="calc-issue-detail-exim01"><span></span>{{ $t('msg.SETT010T010.001') }}</label> <!-- 수출  -->

              <input type="radio"
                     id="calc-issue-detail-exim02"
                     name="calc-issue-detail-exim"
                     v-model="params.eiCatCd"
                     value="I"
                     @change="changeCalcEiCatCd()"
                     :disabled="calcImDmdCatCd !== 'M'"
              >
              <label for="calc-issue-detail-exim02"><span></span>{{ $t('msg.SETT010T010.002') }}</label> <!-- 수입  -->
            </td>
            <td class="pr5">
              <select v-model="params.dtKnd" @change="optionChange()">
                <template v-for="item in dtType.options">
                  <option :key="item.value" :value="item.value">{{ item.text }}</option>
                </template>
              </select>
            </td>
            <td class="pl0 pr0">
              <div v-if="params.dtKnd === 'BL'">
                <input type="text" style="width:200px" v-model.trim="params.blNo" @keyup.enter="searchData" @input="params.blNo=params.blNo.toUpperCase()">
              </div>
              <div v-if="params.dtKnd !== 'BL'">
                <e-date-range-picker-for-settle
                  :term-type="'13'"
                  :show-term-type="[]"
                  @change="changeDateRange"
                />
              </div>
            </td>
            <td class="lspac1">
              <span>
                <input type="radio" id="calc-issue-detail-ship01" name="calc-issue-detail-ship" v-model="params.asProfileYn" value="Y">
                <label for="calc-issue-detail-ship01" class="mr5"><span></span>{{ $t('msg.SETT010T010.007') }}</label>
                <span class="position_relative">
                  <button class="tbl_icon help" @click="helpClick('tip31')">help</button>
                  <div id="tip31" class="tooltip_wrap short position_absolute" style="width:180px; display:none; left:0; top:24px;">
                    <!-- tooltip_wrap   -->
                    <div class="cont"><!-- cont -->
                      <button class="close" @click="helpClose('tip31')">close</button>
                      <ul class="bul_list_sm t2">
                        <li>
                          {{ $t('msg.SETT010T010.008') }}
                        </li>
                      </ul>
                    </div><!-- cont -->
                  </div><!-- tooltip_wrap // -->
                </span>
                <input type="radio" id="calc-issue-detail-ship02" name="calc-issue-detail-ship" v-model="params.asProfileYn" value="N">
                <label for="calc-issue-detail-ship02" class="mr5"><span></span>{{ $t('msg.SETT010T010.009') }}</label>
                <span class="position_relative">
                  <button class="tbl_icon help" @click="helpClick('tip32')">help</button>
                  <div id="tip32" class="tooltip_wrap short position_absolute" style="width:200px; display:none; left:0; top:24px;"><!-- tooltip_wrap   -->
                    <div class="cont"><!-- cont -->
                      <button class="close" @click="helpClose('tip32')">close</button>
                      <ul class="bul_list_sm t2">
                        <li>
                          {{ $t('msg.SETT010T010.010') }}
                        </li>
                      </ul>
                    </div><!-- cont -->
                  </div><!-- tooltip_wrap // -->
                </span>

              </span>
            </td>
            <td class="text_right">
              <a class="button blue sh" @click="searchData">{{ $t('msg.CMBA100.00120') }}</a>
              <!-- <button class="button blue sh" @click="test">테스트</button> -->
            </td>
          </tr>
          <tr>
            <td colspan="5" class="pl0">
              <span class="tit_label valign_middle">{{ $t('msg.SETT010T010.083') }}</span>

              <span class="mr100">
                <input type="checkbox" id="appr1" name="appr" v-model="params.appr" value="01">
                <label for="appr1" class="mr20"><span></span>{{ $t('msg.SETT010T010.084') }}</label>

                <input type="checkbox" id="appr2" name="appr" v-model="params.appr" value="02">
                <label for="appr2" class="mr20"><span></span>{{ $t('msg.SETT010T010.085') }}</label>

                <input type="checkbox" id="appr3" name="appr" v-model="params.appr" value="03">
                <label for="appr3"><span></span>{{ $t('msg.SETT010T010.086') }}</label>
              </span>

              <span class="tit_label valign_middle">{{ $t('msg.SETT010T010.087') }}</span>
              <span>
                <input type="checkbox" id="depo1" name="depo" v-model="params.depo" value="N">
                <label for="depo1" class="mr20"><span></span>{{ $t('msg.SETT010T010.088') }}(N)</label>

                <input type="checkbox" id="depo2" name="depo" v-model="params.depo" value="Y">
                <label for="depo2"><span></span>{{ $t('msg.SETT010T010.089') }}(Y)</label>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- cpntent_box // -->
    <div class="content_box mt10">
      <!-- content_box  -->

      <div class="flex_box">
        <span class="tot_num">Total : <span class="num">{{ total }}</span></span>
        <span class="input_box">
          <input
            class="wid300"
            type="text"
            :placeholder="curDmdCatCd === 'M' ? this.$t('msg.SETT010T010.105') : this.$t('msg.SETT010T010.106')"
            v-model="templateStr"
            @keyup="templateEnter"
          >
        </span>
        <span class="ml_auto cal_btn"><a class="button sm" href="#" @click="excelDownload">{{ $t('msg.MYIN020T010.016') }}</a></span>
      </div>
      <div id="detail-realgrid" style="width: 100%; height: 450px"></div>
      <!-- <div
        v-if="rstList.length > 0"
        class="paging"
      >
        <a
          v-if="isPaging1"
          class="btn prev"
          @click.prevent="setPrevPage()"
        >Prev</a>
        <a>{{ currentPage + 1 }}/{{ totalPage }}</a>
        <a
          class="btn next"
          @click.prevent="setNextPage()"
        >Next</a>
      </div> -->
    </div><!-- content_box// -->
    <div v-if="curDmdCatCd === 'M'" class="flex_box mt10">
      <!-- 운임수납  -->
      <!-- <a class="button blue lg ml_auto" @click.prevent="openFreightCreditBillPop">{{ $t('msg.SETT010T010.013') }}</a>  -->
      <!-- 세금계산서 재전송  -->
      <!-- <a class="button blue lg ml5" @click.prevent="emailPop()">{{ $t('msg.SETT010T010.090') }}</a> -->
      <a class="button blue lg ml_auto" @click.prevent="emailPop()">{{ $t('msg.SETT010T010.090') }}</a>
    </div>
    <div v-else-if="curDmdCatCd === 'C'" class="flex_box mt10">
      <!-- 운임수납  -->
      <!-- <a class="button blue lg ml_auto">{{ $t('msg.SETT010T010.013') }}</a> -->
      <!-- 세금계산서 재전송  -->
      <!-- <a class="button blue lg ml5" @click.prevent="emailPop()">{{ $t('msg.SETT010T010.090') }}</a> -->
      <a class="button blue lg ml_auto" @click.prevent="emailPop()">{{ $t('msg.SETT010T010.090') }}</a>
    </div>
    <div v-else class="flex_box mt10">
      <a class="button blue lg ml_auto" @click.prevent="emailPop()">{{ $t('msg.SETT010T010.090') }}</a> <!-- 세금계산서 재전송  -->
    </div>
    <common-unsolved ref="commonUnsolved" @callback="searchData" />
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import lodash from 'lodash'
import vtlaccApi from '@/api/rest/settle/vtlAcc'
import CalcIssue from '@/api/rest/settle/calcIssue'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import EDateRangePickerForSettle from '@/components/common/EDateRangePickerForSettle'
import CreditTransactionViewPop from '@/pages/settle/popup/CreditTransactionViewPop'
import AccountBillHistPop from '@/pages/settle/popup/AccountBillHistPop'
import FreightBillPop from '@/components/common/FreightBillPop'
import { rootComputed } from '@/store/helpers'
import store from '@/store/index'

let gridView = GridView
let provider = LocalDataProvider
export const fieldsBl = [
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'porCtrCd', dataType: 'text' },
  { fieldName: 'porPlcNm', dataType: 'text' },
  { fieldName: 'dlyCtrCd', dataType: 'text' },
  { fieldName: 'dlyPlcNm', dataType: 'text' },
  { fieldName: 'actShprCstEnm', dataType: 'text' },
  { fieldName: 'usdSlprcAmt', dataType: 'number' },
  { fieldName: 'slprcAmt', dataType: 'number' },
  { fieldName: 'acshRno', dataType: 'text' },
  { fieldName: 'recptEmlAddr', dataType: 'text' },
  { fieldName: 'stsKind', dataType: 'text' },
  { fieldName: 'rcvYn', dataType: 'text' },
  { fieldName: 'rcvDt', dataType: 'text' },
  { fieldName: 'acLst', dataType: 'text' },
  { fieldName: 'rcvBigo', dataType: 'text' },
  { fieldName: 'acshIssDt', dataType: 'text' },
  { fieldName: 'taxtCatCd', dataType: 'text' }
]
export const fieldsCredit = [
  { fieldName: 'refNo', dataType: 'text' },
  { fieldName: 'invNo', dataType: 'text' },
  { fieldName: 'dclrYn', dataType: 'text' },
  { fieldName: 'dclrDtm', dataType: 'text' },
  { fieldName: 'termObrdDt', dataType: 'text' },
  { fieldName: 'usdAmt', dataType: 'number' },
  { fieldName: 'krwAmt', dataType: 'number' },
  { fieldName: 'acshRno', dataType: 'text' },
  { fieldName: 'recptEmlAddr', dataType: 'text' },
  { fieldName: 'taxCfmStsCd', dataType: 'text' },
  { fieldName: 'aprvResult', dataType: 'text' },
  { fieldName: 'rcvDt', dataType: 'text' },
  { fieldName: 'appvMeth', dataType: 'text' },
  { fieldName: 'stsCdrmk', dataType: 'text' },
  { fieldName: 'stsCd', dataType: 'text' },
  { fieldName: 'rmk', dataType: 'text' },
  { fieldName: 'acshIssDt', dataType: 'text' },
  { fieldName: 'rcvRno', dataType: 'text' },
  { fieldName: 'cstSeq', dataType: 'text' },
  { fieldName: 'cnclYn', dataType: 'text' },
  { fieldName: 'cstNo', dataType: 'text' },
  { fieldName: 'trsrEmlAddr', dataType: 'text' },
  { fieldName: 'rcvYn', dataType: 'text' },
  { fieldName: 'accoDt', dataType: 'text' },
  { fieldName: 'apvYn', dataType: 'text' },
  { fieldName: 'usrCfm', dataType: 'text' },
  { fieldName: 'rcvCargTel', dataType: 'text' },
  { fieldName: 'rcvCargNm', dataType: 'text' }
]

export const colnumsBl = [
  {
    name: 'blNo',
    fieldName: 'blNo',
    type: 'text',
    header: { text: 'B/L No.' },
    width: '100',
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const blNo = cell.value
        const idx = cell.index.dataRow
        return `<a href="#" class="link blue" onclick="vmSettleAppTab3.goBlDetail(event, ${idx})">${blNo}</a>`
      }
    }
  },
  { name: 'porCtrCd', fieldName: 'porCtrCd', type: 'text', header: { text: 'porCtrCd' }, width: '100', editable: false },
  {
    name: 'porPlcNm',
    fieldName: 'porPlcNm',
    type: 'text',
    header: { text: 'POL' },
    width: '120',
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        let html
        const idx = cell.index.dataRow
        const porCtrCd = grid.getValue(idx, 'porCtrCd')

        html = `${cell.value},${porCtrCd}`

        return html
      }
    }
  },
  { name: 'dlyCtrCd', fieldName: 'dlyCtrCd', type: 'text', header: { text: 'dlyCtrCd' }, width: '100', editable: false },
  {
    name: 'dlyPlcNm',
    fieldName: 'dlyPlcNm',
    type: 'text',
    header: { text: 'POD' },
    width: '120',
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        let html
        const idx = cell.index.dataRow
        const dlyCtrCd = grid.getValue(idx, 'dlyCtrCd')

        html = `${cell.value},${dlyCtrCd}`

        return html
      }
    }
  },
  { name: 'actShprCstEnm', fieldName: 'actShprCstEnm', type: 'text', header: { text: app.$t('msg.FARE010T010.009') }, width: '150', editable: false }, //'실화주'
  {
    name: 'usdSlprcAmt',
    fieldName: 'usdSlprcAmt',
    type: 'text',
    header: { text: 'USD' },
    width: '100',
    editable: false
  },
  {
    name: 'slprcAmt',
    fieldName: 'slprcAmt',
    type: 'text',
    header: { text: 'KRW' },
    width: '100',
    editable: false
  },
  {
    name: 'taxtCatCd',
    fieldName: 'taxtCatCd',
    type: 'text',
    header: { text: app.$t('msg.SETT010T010.189') }, // 항목
    width: '80',
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const taxtCatCd = provider.getValue(idx, 'taxtCatCd')

        let str = ''

        if (taxtCatCd === '01') {
          str = '<span>' + app.$t('msg.SETT010T010.190') + '</span>' // DEM/DET
        } else if (taxtCatCd === '02') {
          str = '<span>' + app.$t('msg.SETT010T010.191') + '</span>' // 운임
        }

        return str
      }
    }
  },
  { name: 'acshRno', fieldName: 'acshRno', type: 'text', header: { text: app.$t('msg.ONIM010T010.001') }, width: '100', editable: false }, // 세금계산서 번호
  { name: 'recptEmlAddr', fieldName: 'recptEmlAddr', type: 'text', header: { text: app.$t('msg.SETT010T010.031') + ' email' }, width: '160', editable: false },
  {
    name: 'stsKind',
    fieldName: 'stsKind',
    type: 'text',
    header: { text: app.$t('msg.CSDBL400_M2.0010') }, // '상태'
    width: '100',
    editable: false
  },
  { name: 'rcvYn', fieldName: 'rcvYn', type: 'text', header: { text: app.$t('msg.SETT010T010.087') }, width: '80', editable: false }, //'입금여부'
  { name: 'rcvDt', fieldName: 'rcvDt', type: 'text', header: { text: app.$t('msg.MYIN020T020.022') }, width: '100', editable: false }, // '입급일'
  {
    name: 'rcvBigo',
    fieldName: 'rcvBigo',
    type: 'text',
    header: { text: app.$t('msg.CMBA370.010') }, // '비고'
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, model, width, height) {
        const idx = model.index.dataRow
        let rcvBigo = ''
        if (!lodash.isNil(model.value)) {
          rcvBigo = lodash.trim(model.value)
        }
        let html = rcvBigo
        if (rcvBigo === app.$t('msg.SETT010T010.070')) { // 가상계좌
          html = `<a class="link blue" onclick="vmSettleAppTab3.paymentPopBl(event, ${idx})"> ${app.$t('menu.MENU06.060')}</a>`
        } else if (rcvBigo === app.$t('msg.SETT010T010.050')) { // 직접입력
          html = `<span> ${app.$t('msg.CSBK100.050')}</span>`
        }

        return html
      }
    }
  }
]
export const colnumsCredit = [
  {
    name: 'refNo',
    type: 'text',
    fieldName: 'refNo',
    width: '90',
    header: { text: 'Ref No.' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const refNo = cell.value
        const idx = cell.index.dataRow
        return `<a href="#" class="link blue" onclick="vmSettleAppTab3.creditProcess(event, ${idx})">${refNo}</a>`
      }
    }
  },
  { name: 'invNo', fieldName: 'invNo', type: 'text', header: { text: 'Invoice No.' }, editable: false },
  {
    name: 'dclrYn',
    fieldName: 'dclrYn',
    type: 'text',
    header: { text: app.$t('btn.CMBTK014') }, //'확정여부'
    width: '60',
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const stsCd = grid.getValue(idx, 'stsCd')
        let txt = `${cell.value}`
        if (stsCd === '04') {
          txt = 'Reject'
        }
        return txt
      }
    }
  },
  { name: 'dclrDtm', fieldName: 'dclrDtm', type: 'text', header: { text: '확정시간' }, editable: false },
  { name: 'termObrdDt', fieldName: 'termObrdDt', type: 'text', header: { text: 'On Board Date' }, width: '140', editable: false },
  {
    name: 'usdAmt',
    fieldName: 'usdAmt',
    type: 'number',
    width: '90',
    header: { text: 'USD' },
    numberFormat: '#,###,###,##0.00',
    styleName: 'rg-number-kwd-format',
    editable: false
  },
  {
    name: 'krwAmt',
    fieldName: 'krwAmt',
    type: 'number',
    width: '100',
    header: { text: 'KRW' },
    numberFormat: '#,###,###,##0',
    styleName: 'rg-number-kwd-format',
    editable: false
  },
  { name: 'acshRno', fieldName: 'acshRno', type: 'text', header: { text: app.$t('msg.ONIM010T010.001') }, width: '110', editable: false }, // 세금계산서 번호
  { name: 'recptEmlAddr', fieldName: 'recptEmlAddr', type: 'text', header: { text: app.$t('msg.SETT010T010.031') + ' email' }, width: '180', editable: false }, //'발급 email'
  {
    name: 'taxCfmStsCd',
    fieldName: 'taxCfmStsCd',
    type: 'text',
    header: { text: app.$t('msg.CSDBL400_M2.0010') },
    width: '80',
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        if (cell.value) {
          let html = `<div style="color: #000000;">${cell.value}(국세청)</div>`
          if (cell.value === '실패') {
            html = `<div style="color: red;">${cell.value}(국세청)</div>`
          }
          return html
        }

        return ''
      }
    }
  },
  { name: 'rcvYn', fieldName: 'rcvYn', type: 'text', header: { text: app.$t('msg.SETT010T010.087') }, width: '60', editable: false }, //'입금여부'
  { name: 'rcvDt', fieldName: 'rcvDt', type: 'text', header: { text: app.$t('msg.SETT010T010.082') }, width: '80', editable: false }, // '입금일'
  {
    name: 'rmk',
    fieldName: 'rmk',
    type: 'text',
    header: { text: 'rmk' },
    editable: false
  },
  { name: 'aprvResult', fieldName: 'aprvResult', type: 'text', header: { text: 'aprvResult' }, editable: false },
  {
    name: 'appvMeth',
    fieldName: 'appvMeth',
    type: 'text',
    header: { text: app.$t('msg.CMBA370.010') }, // 비고
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const val = cell.value
        let html = ''
        if (val === '01') {
          html = app.$t('msg.SETT010T010.084') // 즉시이체
        } else if (val === '02') {
          html = app.$t('msg.SETT010T010.085') // 직접입금
        } else if (val === '03') {
          html = `<a href="#" class="link blue" onclick="vmSettleAppTab3.paymentPop(event, ${idx})">${app.$t('msg.SETT010T010.086')}</a>` // 가상계좌
        }

        return html
      }
    }
  },
  { name: 'stsCdrmk', fieldName: 'stsCdrmk', type: 'text', header: { text: 'stsCdrmk' }, editable: false },
  { name: 'stsCd', fieldName: 'stsCd', type: 'text', header: { text: 'stsCd' }, editable: false },
  { name: 'acshIssDt', fieldName: 'acshIssDt', type: 'text', header: { text: 'acshIssDt' }, editable: false },
  { name: 'rcvRno', fieldName: 'rcvRno', type: 'text', header: { text: 'rcvRno' }, editable: false },
  { name: 'cstSeq', fieldName: 'cstSeq', type: 'text', header: { text: 'cstSeq' }, editable: false },
  { name: 'cnclYn', fieldName: 'cnclYn', type: 'text', header: { text: 'cnclYn' }, editable: false },
  { name: 'cstNo', fieldName: 'cstNo', type: 'text', header: { text: 'cstNo' }, editable: false },
  { name: 'trsrEmlAddr', fieldName: 'trsrEmlAddr', type: 'text', header: { text: 'trsrEmlAddr' }, editable: false },
  { name: 'accoDt', fieldName: 'accoDt', type: 'text', header: { text: 'accoDt' }, editable: false },

  { name: 'apvYn', fieldName: 'apvYn', type: 'text', header: { text: 'apvYn' }, visible: false, editable: false },
  { name: 'usrCfm', fieldName: 'usrCfm', type: 'text', header: { text: 'usrCfm' }, visible: false, editable: false },
  { name: 'rcvCargTel', fieldName: 'rcvCargTel', type: 'text', header: { text: 'rcvCargTel' }, visible: false, editable: false },
  { name: 'rcvCargNm', fieldName: 'rcvCargNm', type: 'text', header: { text: 'rcvCargNm' }, visible: false, editable: false }
]

let layoutBl = [
  'blNo',
  // 'porCtrCd',
  'porPlcNm',
  // 'dlyCtrCd',
  'dlyPlcNm',
  'actShprCstEnm',
  'usdSlprcAmt',
  'slprcAmt',
  'taxtCatCd',
  'acshRno',
  'recptEmlAddr',
  'stsKind',
  'rcvYn',
  'rcvDt',
  'rcvBigo'
]
let layoutCredit = [
  'refNo',
  'invNo',
  'dclrYn',
  //'dclrDtm',
  'termObrdDt',
  'usdAmt',
  'krwAmt',
  'acshRno',
  'recptEmlAddr',
  'taxCfmStsCd',
  //'aprvResult',
  'rcvYn',
  'rcvDt',
  'appvMeth',
  'apvYn',
  'usrCfm',
  'rcvCargTel',
  'rcvCargNm'
  //'rmk'
  // 'stsCdrmk',
  // 'stsCd',
  // 'acshIssDt',
  // 'rcvRno',
  // 'cstSeq',
  // 'cnclYn',
  // 'cstNo',
  // 'trsrEmlAddr',
  // 'accoDt'
]

export default {
  name: 'CalcIssueDetail',
  components: {
    CalcIssue,
    EBreadcrumbs,
    EDateRangePickerForSettle,
    CreditTransactionViewPop,
    AccountBillHistPop,
    FreightBillPop,
    RatePop: () => import('@/pages/settle/popup/CalcIssueRatePop'),
    // RemarkPop: () => import('@/pages/settle/popup/CalcIssueRemarkPop'),
    FreightPop: () => import('@/pages/settle/popup/CalcIssueFreightPop'),
    PaymentPop: () => import('@/pages/settle/popup/CalcIssuePaymentListPop'),
    EmailPop: () => import('@/pages/settle/popup/CalcIssueEmailTaxPop'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    CommonUnsolved: () => import('@/pages/trans/CommonUnsolved')
  },
  data: function () {
    return {
      isHidden: true,
      isShowPop: false,
      customComponent: null,
      detail: { },
      isFilter: false,
      freightBillInfo: {},
      params: {
        fromDt: '',
        toDt: '',
        dtKnd: 'RD',
        eiCatCd: 'O',
        blNo: '',
        bkgNo: '',
        shipCd: 'M',
        appr1: '01',
        appr2: '02',
        appr3: '03',
        depo1: 'N',
        depo2: 'Y',
        appr: ['01', '02', '03'],
        depo: ['Y', 'N'],
        asProfileYn: 'Y'
      },
      shipCatCd: '1',
      rstList: [],
      rstFilter: [],
      total: 0,
      templateStr: '',
      isBdialogVisible: false,
      bottomDialogFlag: false,
      htmlData: '',
      //그리드 변수
      isPaging1: true,
      currentPage: 0,
      totalPage: 1,
      mUserName: '',
      mNumber: '',
      userDmdCatCd: 'R',
      etDmdCatCd: 'R',
      imDmdCatCd: 'R',
      curDmdCatCd: 'R',
      poParamiters: undefined,
      selectData: [],
      dtType: {
        options: [
          { value: 'RD', text: this.$t('msg.SETT010T010.081') },
          { value: 'OD', text: this.$t('msg.SETT010T010.003') },
          { value: 'AD', text: this.$t('msg.SETT010T010.082') }
        ]
      }
    }
  },
  computed: {
    ...rootComputed,
    selectedProfile () {
      return this.$store.getters['auth/selectedProfile']
    },
    calcEiCatCd () {
      return this.$store.getters['auth/getCalcEiCatCd']
    },
    calcImDmdCatCd () {
      return this.$store.getters['settle/getImDmdCatCd']
    },
    calcEtDmdCatCd () {
      return this.$store.getters['settle/getEtDmdCatCd']
    }
  },
  watch: {
    'params.depo' () {
      this.depoCheck()
    },
    'params.eiCatCd' (n, o) {
      this.setGridView()
    },
    calcEiCatCd () {
      if (this.calcImDmdCatCd === 'M' && this.calcEtDmdCatCd === 'M') {
        this.params.eiCatCd = this.calcEiCatCd
      }
    }
  },
  created () {
    window.functionOutsideVue(this)
    window.vmSettleAppTab3 = this
    this.userDmdCatCd = this.$store.state.settle.userDmdCatCd

    // 프로필에서 설정한 수출/수입 값으로 세팅
    this.initEiCatCd()
},
  mounted: function () {
    provider = new LocalDataProvider(true)
    gridView = new GridView('detail-realgrid')
    this.setGridView()
  },
  methods: {
    initEiCatCd () {
      if (this.calcImDmdCatCd === 'M' && this.calcEtDmdCatCd !== 'M') {
        this.params.eiCatCd = 'I'
      } else if (this.calcEtDmdCatCd === 'M' && this.calcImDmdCatCd !== 'M') {
        this.params.eiCatCd = 'O'
      } else if (this.calcEtDmdCatCd === 'M' && this.calcImDmdCatCd === 'M') {
          if (this.calcEiCatCd !== '') {
            this.params.eiCatCd = this.calcEiCatCd
          } else {
            if (this.selectedProfile.eiCatCd === 'A') {
              // 프로필 값이 수출/수입이면 '수출'
              this.params.eiCatCd = 'O'
            } else {
              // 프로필에서 설정한 수출/수입 값으로 세팅
              this.params.eiCatCd = this.selectedProfile.eiCatCd
            }
          }
      }
    },
    setGridView () {
      // this.etDmdCatCd = this.$store.state.settle.etDmdCatCd
      // this.imDmdCatCd = this.$store.state.settle.imDmdCatCd
      if (this.params.eiCatCd === 'O') {
        // 수출
        this.curDmdCatCd = this.calcEtDmdCatCd
      } else {
        // 수입
        this.curDmdCatCd = this.calcImDmdCatCd
      }

      if (this.params.eiCatCd === 'O') {
        // 수출
        this.paintGrid(this.calcEtDmdCatCd)
      } else {
        // 수입
        this.paintGrid(this.calcImDmdCatCd)
      }
    },
    paintGrid (flag) {
      console.log('paintGrid >>', flag)
      const $vm = this
      if (flag === 'M') {
        //
        this.dtType = {
          options: [
            { value: 'RD', text: this.$t('msg.SETT010T010.081') },
            { value: 'OD', text: this.$t('msg.SETT010T010.003') },
            { value: 'AD', text: this.$t('msg.SETT010T010.082') },
            { value: 'BL', text: this.$t('msg.SETT010T010.039') }
          ]
        }
        this.params.dtKnd = 'RD'
        provider.setFields(fieldsCredit)
        gridView.setColumns(colnumsCredit)
        gridView.setColumnLayout(layoutCredit)

        gridView.setDataSource(provider)
        gridView.header.heights = [40]
        gridView.displayOptions.rowHeight = 34
        gridView.displayOptions.fitStyle = 'even'

        gridView.setStateBar({
          visible: false
        })
        gridView.setRowIndicator({
          visible: false
        })
        //gridView.setPaging(true, 10)
      } else {
        // 청구, 영수화주 처리
        this.dtType = {
          options: [
            { value: 'OD', text: this.$t('msg.SETT010T010.003') },
            { value: 'AD', text: this.$t('msg.SETT010T010.082') },
            { value: 'BL', text: this.$t('msg.SETT010T010.039') }
          ]
        }
        this.params.dtKnd = 'OD'

        provider.setFields(fieldsBl)
        gridView.setColumns(colnumsBl)
        gridView.setColumnLayout(layoutBl)

        gridView.setDataSource(provider)
        gridView.header.heights = [40]
        gridView.displayOptions.rowHeight = 34
        gridView.displayOptions.fitStyle = 'even'

        gridView.setStateBar({
          visible: false
        })
        gridView.setRowIndicator({
          visible: false
        })
        //gridView.setPaging(true, 10)
        // gridView.onCellClicked = function (grid, info) {
        //   console.log('영수 > onCellClicked: ' + JSON.stringify(info))
        //   if (info.cellType === 'data') {
        //     const tmp = provider.getJsonRows(info.dataRow, info.dataRow)
        //     console.log(JSON.stringify(tmp))
        //   }
        // }
      }
      this.rstList = []
      this.rstFilter = []
      this.total = 0
      provider.setRows(this.rstList)
      this.currentPage = gridView.getPage() //현재 페이지 반환
      this.totalPage = gridView.getPageCount() //전체페이지 개수 반환
    },
    changeDateRange (s, d) {
      this.params.fromDt = s
      this.params.toDt = d
    },
    goDetailPageAtGrid (e, gridIndex) {
      e.preventDefault()
      if (this.params.eiCatCd === 'O') {
        this.goBlDetail(gridIndex)
      }
    },
    test () {
      console.log(this.params.appr)
      console.log(this.params.depo)
    },
    depoCheck () {
      // 입금 관련 체크 로직 점검
      if (this.params.depo.length === 0) {
        this.openAlert(this.$t('msg.SETT010T010.193')) // 하나 이상 선택하셔야 합니다.
        this.params.depo = ['N', 'Y']
      }
    },
    checkedSearch () {
      let rtn = false

      return rtn
    },
    searchData () {
      if (this.optionChk()) {
        if (this.curDmdCatCd === 'M') {
          this.searchCredit()
        } else {
          this.searchBl()
        }
      }
    },
    async searchCredit () {
      this.rstList = []
      this.rstFilter = []

      let param = {
        asKndCd: this.params.dtKnd,
        asEiCatCd: this.params.eiCatCd,
        asStrDt: this.params.fromDt,
        asEndDt: this.params.toDt,
        lstAppvMeth: this.params.appr,
        asRetClsfct: this.params.dtKnd,
        asProfileYn: this.params.asProfileYn,
        lstRcvYn: this.params.depo,
        asDmdCatCd: this.curDmdCatCd,
        blNo: this.params.blNo
      }
      console.log('Credit >> ', JSON.stringify(param))
      await CalcIssue.detailCreditList(param)
        .then(response => {
          console.log(response)
          if (response.data) {
            const resp = response.data.map(vo => {
              const dt = vo.termObrdDt.split('~')
              if (dt.length === 2) {
                const sdt = moment(this.$_.trim(dt[0]), 'YYYYMMDD').format('YYYY.MM.DD')
                const edt = moment(this.$_.trim(dt[1]), 'YYYYMMDD').format('YYYY.MM.DD')
                vo.termObrdDt = `${sdt}~${edt}`
              }

              return { ...vo }
            })
            this.total = resp.length

            this.rstList = resp
            this.rstFilter = resp

            provider.setRows(this.rstList)
            this.currentPage = gridView.getPage() //현재 페이지 반환
            this.totalPage = gridView.getPageCount() //전체페이지 개수 반환
            gridView.setAllCheck(false)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async searchBl () {
      this.rstList = []
      this.rstFilter = []

      let param = {
        asKndCd: this.params.dtKnd,
        asEiCatCd: this.params.eiCatCd,
        asStrDt: this.params.fromDt,
        asEndDt: this.params.toDt,
        lstAppvMeth: this.params.appr,
        asRetClsfct: this.params.dtKnd,
        asShipClsfct: this.params.shipCd,
        asProfileYn: this.params.asProfileYn,
        lstRcvYn: this.params.depo,
        asDmdCatCd: this.curDmdCatCd,
        asRcvYn: '%',
        blNo: this.params.blNo
      }
      console.log('BL Search Params Data >> ', JSON.stringify(param))
      await CalcIssue.detailBlList(param)
        .then(response => {
          console.log(response)
          if (response.data) {
            const resp = response.data
            this.total = resp.length

            this.rstList = resp
            this.rstFilter = resp

            provider.setRows(this.rstList)
            this.currentPage = gridView.getPage() //현재 페이지 반환
            this.totalPage = gridView.getPageCount() //전체페이지 개수 반환
            gridView.setAllCheck(false)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async getArrangeDetailInList (lstRefNo) {
      // AR_월말청구화주 대사 BL상세 조회
      let param = {
        lstRefNo: lstRefNo
      }
      await CalcIssue.arrangeDetailInList(lstRefNo)
        .then(response => {
          console.log(response)
          if (response.data) {
            console.log('ref -> blNo')
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    excelDownload () {
      const curDt = moment().format('YYYYMMDD')
      gridView.exportGrid({
        type: 'excel',
        target: 'local',
        textCallback: function (index, column, value) {
          if (column === 'dclrYn') {
            const stsCd = gridView.getValue(index, 'stsCd')
            if (stsCd === '04') {
              return 'Reject'
            } else {
              return value
            }
          } else {
            return value
          }
        },
        documentTitle: { //제목
          message: this.$t('menu.MENU06.040'), //'정리내역 조회',
          visible: true,
          spaceTop: 1,
          spaceBottom: 1,
          height: 60,
          styleName: 'excel-export-title-style'
        },
        fileName: this.$t('menu.MENU06.040') + `_${curDt}.xlsx`,
        showProgress: false,
//        allColumns: true,
        allItems: true,
        progressMessage: this.$t('msg.ADD20220219.012'), // 엑셀 Export중입니다.
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          console.log('엑셀 다운로드 완료')
        }
      })
    },
    // 월말화주(전용) 프로세스
    creditProcess (e, inx) {
      e.preventDefault()

      if (inx > -1) {
        const tmp = provider.getJsonRows(inx, inx)
        if (tmp.length > 0) {
          const vo = tmp[0]
          console.log(vo)
          this.poParamiters = { refNo: vo.refNo, asEiCatCd: this.params.eiCatCd }
          if (vo.dclrYn === 'Y') {
            // 거래명세
            this.openPopup2('AccountBillHistPop')
          } else {
            this.openPopup2('CreditTransactionViewPop')
          }
        }
      }
    },
    goBlDetail (inx) {
      if (inx > -1) {
        const tmp = provider.getJsonRows(inx, inx)
        if (tmp.length > 0) {
          const vo = tmp[0]
          const bkgNo = ''
          const srRno = ''
          const blNo = vo.blNo

          console.log('BL Detail >>', vo)
          this.$router.push({ name: 'bl', params: { bkgNo: bkgNo, srRno: srRno, blNo: blNo } }).catch(() => {})
        }
      }
    },
    async openFreightCreditBillPop () {
      const checkList = gridView.getCheckedRows()
      if (checkList.length === 0) {
        this.openAlert(this.$t('msg.SETT010T010.194')) // 대상을 선택해주세요.
        return
      }
      let refList = []
      checkList.forEach(v => {
        const tmp = provider.getJsonRows(v, v)
        if (tmp.length > 0) {
          const vo = tmp[0]
          if (vo.dclrYn === 'Y') {
            refList.push(vo.refNo)
          }
        }
      })
      console.log(refList)
      if (refList.length === 0) {
        this.openAlert(this.$t('msg.ADD20220219.050')) // '확정된 인보이스 건에 한해 진행 가능합니다'
        return
      }
      let refDetailList = []
      await CalcIssue.arrangeDetailInList(refList)
        .then(async response => {
          console.log(response)
          if (response.data) {
            console.log('ref -> blNo')
            refDetailList = response.data
            this.openFreightCreditPop(refDetailList)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    openFreightCreditPop (refDetailList) {
      let blList = []
      refDetailList.forEach(v => {
        blList.push({
          blNo: v.blNo,
          krw: parseInt(v.blAmt),
          usd: 0,
          exRate: 1,
          currency: 'KRW',
          obrdDt: '', //TODO
          eiCatCd: this.params.eiCatCd,
          cntrList: []
        })
      })
      this.freightBillInfo = {
        dpId: '',
        dmdCatCd: this.curDmdCatCd, // 화주구분 : 월말화주(M), 청구화주(C), 영수화주(R)
        btnType: 'FREIGHT', // FREIGHT: 운임수납, TAX_INVOICE: 계산서발행, BL: BL 발행, DO: DO발행
        hasNotPaid: false, // 미정산 금액 존재여부
        hasNotTaxInvoice: false, // 세금계산서 미발행 건 존재 여부
        blList: blList
      }
      console.log(this.freightBillInfo)
      this.customComponent = 'FreightBillPop'
      this.isShowPop = true
      $('#layerDetailPop > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    templateEnter (e) {
        this.templateStr = this.$_.toUpper(this.$_.trim(this.templateStr))
        if (this.curDmdCatCd === 'M') {
          const gridList = this.rstList
          if (this.templateStr === '') {
            this.isFilter = false
            provider.setRows(this.rstList)
            this.total = this.rstList.length
          } else {
            this.isFilter = true
            this.rstFilter = gridList.filter(vo => {
                return ('' + vo.refNo).indexOf(this.templateStr) > -1 ||
                ('' + vo.invNo).indexOf(this.templateStr) > -1 ||
                ('' + vo.acshRno).indexOf(this.templateStr) > -1
              })
            provider.setRows(this.rstFilter)
            this.total = this.rstFilter.length
          }
        } else {
          const gridList = this.rstList
          if (this.templateStr === '') {
            this.isFilter = false
            provider.setRows(this.rstList)
            this.total = this.rstList.length
          } else {
            this.isFilter = true
            this.rstFilter = gridList.filter(vo => ('' + vo.blNo).indexOf(this.templateStr) > -1 ||
                              ('' + vo.acshRno).indexOf(this.templateStr) > -1)
            provider.setRows(this.rstFilter)
            this.total = this.rstFilter.length
          }
        }
        this.currentPage = gridView.getPage() //현재 페이지 반환
        this.totalPage = gridView.getPageCount()
    },
    paymentPop (e, inx) {
      e.preventDefault()

      if (inx > -1) {
        const tmp = provider.getJsonRows(inx, inx)
        if (tmp.length > 0) {
          const vo = tmp[0]
          console.log(vo)
          if (vo.appvMeth === '03') {
            this.detail = {
              blNo: vo.blNo,
              acNo: 'KMT00121120108' //가상계좌정
            }
            this.openPopup('PaymentPop', 'PaymentPop')
          }
        }
      }
    },
    paymentPopBl (e, inx) {
      // 가상계좌 정보 보기
      e.preventDefault()

      if (inx > -1) {
        const tmp = provider.getJsonRows(inx, inx)
        if (tmp.length > 0) {
          const vo = tmp[0]
          console.log(vo)
          if (vo.rcvBigo === this.$t('msg.SETT010T010.086')) { // 가상계좌
            if (this.$_.trim(vo.acLst) !== '') {
              const acNoList = this.$_.words(vo.acLst, /[^,]+/g)
              if (acNoList.length > 0) {
                this.detail = {
                  blNo: vo.blNo,
                  acNo: vo.acLst // 결재번호
                }
                console.log(JSON.stringify(this.detail))

                this.customComponent = 'PaymentPop'
                this.isShowPop = true
                $('#layerDetailPop > .popup_dim').fadeIn()
                $('body,html').css('overflow', 'hidden')
              }
            }
          }
        }
      }
    },
    emailPop () {
      const checkedItems = gridView.getCheckedRows()
      if (checkedItems.length === 0) {
        this.openAlert(this.$t('msg.ONGO080G010.024')) // 대상을 선택해 주세요.
        return
      } else if (checkedItems.length > 1) {
        this.openAlert(this.$t('msg.ONGO080G010.024')) // 대상을 1개만 선택해 주세요.
        return
      }
      const idx = checkedItems[0]
      const tmp = provider.getJsonRows(idx, idx)
      if (tmp.length > 0) {
        const vo = tmp[0]
        this.detail = {
          acshIssDt: '',
          acshRno: '',
          rcvRno: '',
          cstSeq: '',
          cnclYn: '',
          cstNo: ''
        }
        this.openPopup('EmailPop', 'EmailPop')
      }
    },
    requestPop () {
      var checkedItems = gridView.getCheckedRows()
      let checkedRowCnt = checkedItems.length
      console.log('requestPop')
      console.log(checkedItems)

      if (checkedRowCnt === 1) {
        if (this.isFilter) {
          this.detail = this.rstFilter[checkedItems[0]]
        } else {
          this.detail = this.rstList[checkedItems[0]]
        }
        this.openPopup('FreightPop', 'FreightPop')
      } else {
        alert('Please select item')
      }
    },
    optionChk: function () {
      if (this.params.dtKnd === 'BL') {
        if (this.params.blNo === '') {
            this.openAlert(this.$t('msg.CSCT060G060.025')) // B/L NO를 입력하여 주세요.
            return false
        }
      } else {
        if (this.params.fromDt === '') {
            this.openAlert('Please input start date.')
            return false
        }
        if (this.params.toDt === '') {
            this.openAlert('Please input end date.')
            return false
        }
      }

      return true
    },
    radioClick (r) {
      this.params.eiCatCd = r
    },
    optionChange () {
      //this.params.dtKnd =
    },
    helpClick (tp) {
      $('#' + tp).show()
    },
    helpClose (tp) {
      $('#' + tp).hide()
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    closeFun () {
      this.$Simplert.close()
    },
    openPopup (compNm, kind) {
      console.log('openPopup')
      this.customComponent = compNm

      if (kind === 'EmailPop') { // 셀 클릭 상세
        var checkedItems = gridView.getCheckedRows()
        let checkedRowCnt = checkedItems.length
        if (checkedRowCnt === 1) {
          if (this.isFilter) {
            this.detail = this.rstFilter[checkedItems[0]]
            console.log(this.detail)
          } else {
            this.detail = this.rstList[checkedItems[0]]
            console.log(this.detail)
          }
        } else {
          alert('Please select one item')
          return
        }
      } else if (kind === 'FreightPop') {
        // 예외처리
      }
      // 팝업 후처리
      if (kind !== 'FreightBillPop' && kind !== 'TaxInvoicePop' && kind !== 'BL' && kind !== 'DO') {
        this.isShowPop = true
        $('#layerDetailPop > .popup_dim').fadeIn()
        $('body,html').css('overflow', 'hidden')
      }
    },
    closePopup () {
      this.isShowPop = false
      $('#layerDetailPop > .popup_dim').fadeOut()
      $('body,html').removeAttr('style')

      // 데이터 재조회
      //this.searchData('closeResearch')
    },
    openPopup2 (compNm) {
      this.isShowPop = true
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.sample_popup_list')
    },
    closePopup2 () {
      this.isShowPop = false
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.sample_popup_list')
    },
    setPrevPage: function () {
      gridView.setPage(this.currentPage - 1)
      this.currentPage = gridView.getPage()
    },
    setNextPage: function () {
      gridView.setPage(this.currentPage + 1)
      this.currentPage = gridView.getPage()
    },

    async getRefBlList (refList) {
      let refDetailList = []
      await CalcIssue.arrangeDetailInList(refList)
        .then(response => {
          console.log(response)
          if (response.data) {
            console.log('ref -> blNo')
            refDetailList = response.data
          }
        })
        .catch(err => {
          console.log(err)
        })
      return refDetailList
    },
    async fnAction (type) {
      const checkedRows = gridView.getCheckedRows()
      if (checkedRows.length === 0) {
        this.alertType(this.$t('msg.ONGO080G010.024')) // 대상을 선택해 주세요.
        return
      }
      console.log('fnAction >>', checkedRows)

      if (checkedRows.length === 0) {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONGO080G010.024') }) // 대상을 선택해 주세요.
        return
      } else if (checkedRows.length > 1 && type === 'FRT') {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONGO080G010.031') }) // 대상을 1개만 선택해 주세요.
        return
      }

      let arrBlNo = []
      if (this.curDmdCatCd === 'M') {
        let arrRefNo = []
        checkedRows.forEach(idx => {
          const vo = provider.getJsonRows(idx, idx)
          if (vo.length > 0) {
            arrRefNo.push(vo[0].refNo)
          }
        })
        console.log('arrRefNo >>', arrRefNo)

        let temp = await this.getRefBlList(arrRefNo)
        temp.forEach(v => {
          arrBlNo.push(v.blNo)
        })
        console.log('arrBlNo >>', arrBlNo)
      } else {
        checkedRows.forEach(idx => {
          const vo = provider.getJsonRows(idx, idx)
          if (vo.length > 0) {
            arrBlNo.push(vo[0].blNo)
          }
        })
      }
      console.log('BL List >> ', arrBlNo)
      if (arrBlNo.length === 0) {
        return
      }

      if (type === 'SETTLE') { // 운임수납
        this.$refs.commonUnsolved.openFreightPop(arrBlNo, 'FREIGHT', 'O') // 수출: O, 수입: I
      } else if (type === 'TAX') { // 계산서 발행
        this.$refs.commonUnsolved.openFreightPop(arrBlNo, 'TAX_INVOICE', 'O') // 수출: O, 수입: I
      } else if (type === 'FRT') { // 운임 수정 요청
        this.$refs.commonUnsolved.freightPopCall(arrBlNo[0])
      } else if (type === 'BL_ISSUE') {
        this.$refs.commonUnsolved.openBlPaymentPop(arrBlNo, 'O')
      } else if (type === 'DO_ISSUE') {
        this.$refs.commonUnsolved.openDoPaymentPop(arrBlNo, 'I')
      } else {
        alert(type)
      }
    },
    changeCalcEiCatCd () {
      if (this.calcImDmdCatCd === 'M' && this.calcEtDmdCatCd === 'M') {
        this.$store.commit('auth/updateCalcEiCatCd', this.params.eiCatCd)
      }
    }
  }
}

</script>

<style scoped>
  .text-column {
    text-align: left;
    cursor: pointer;
  }
  .number-column {
    text-align: right;
  }
</style>
