<template>
  <div class="popup_wrap" style="width:1200px;">
    <!-- popup_wrap -->
    <button class="layer_close" @click="$emit('close', { msgYn: 'Y' })">close</button>
    <div class="popup_cont">
      <h1 class="page_title">정산목록확인 (Ref. No {{ refNo }})</h1>
      <div class="content_box">
        <div class="tbl_form">
          <div class="dv col_4">
            <div :id="`${compId}-settle-realgrid`" style="width: 100%; height: 450px">
            </div>
          </div>
          <span class="dv text_center valign_middle wid100 pr5">
            <button class="tbl_icon arrow">arrow</button>
          </span>
          <div class="dv col_2">
            <div :id="`${compId}-user-realgrid`" style="width: 100%; height: 450px">
            </div>
            <!-- <div class="mt10 text_right">
              <a class="button sm" href="#">매칭하기</a>
            </div> -->
          </div>
          <span class="dv text_center valign_middle wid100 pr5">
            <button class="tbl_icon arrow">arrow</button>
          </span>
          <div class="dv col_3">
            <div :id="`${compId}-match-realgrid`" style="width: 100%; height: 450px">
            </div>
          </div>
        </div>
      </div>
      <div class="mt10 text_right">
        <b-button
          variant="secondary"
          class="ml-2"
          @click="$emit('close', { msgYn: 'Y' })"
        >
          닫기
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import CreditApi from '@/api/rest/settle/credit'

let gridView = GridView
let provider = LocalDataProvider

let userGridView = GridView
let userProvider = LocalDataProvider

let matchGridView = GridView
let matchProvider = LocalDataProvider

export const fields = [
  { fieldName: 'bascDt', dataType: 'text' },
  { fieldName: 'vslNm', dataType: 'text' },
  { fieldName: 'voyNo', dataType: 'text' },
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'bizNo', dataType: 'text' },
  { fieldName: 'cstNm', dataType: 'text' },
  { fieldName: 'cstNml', dataType: 'text' },
  { fieldName: 'porPlcCd', dataType: 'text' },
  { fieldName: 'dlyPlcCd', dataType: 'text' },
  { fieldName: 'ofUsdAmt', dataType: 'number' },
  { fieldName: 'exrt', dataType: 'number' },
  { fieldName: 'ofLocAmt', dataType: 'number' },
  { fieldName: 'thcLocAmt', dataType: 'number' },
  { fieldName: 'dcfLocAmt', dataType: 'number' },
  { fieldName: 'wfgLocAmt', dataType: 'number' },
  { fieldName: 'pfsLocAmt', dataType: 'number' },
  { fieldName: 'afsLocAmt', dataType: 'number' },
  { fieldName: 'othLocAmt', dataType: 'number' },
  { fieldName: 'totalAmt', dataType: 'number' }
]
export const userFields = [
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'totalAmt', dataType: 'number' }
]
export const matchFields = [
  { fieldName: 'diffAmt', dataType: 'number' },
  { fieldName: 'remark', dataType: 'text' },
  { fieldName: 'blNo', dataType: 'text' }
]

export const columns = [
  {
    name: 'bascDt',
    fieldName: 'bascDt',
    width: '60',
    header: { text: 'Date', styleName: 'header-title' },
    footer: { text: '합계금액', styleName: 'footer-title' },
    editable: false
  },
  {
    name: 'vslNm',
    fieldName: 'vslNm',
    width: '140',
    header: { text: 'VSL/VOY' },
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const vslNm = grid.getValue(idx, 'vslNm')
        const voyNo = grid.getValue(idx, 'voyNo')
        console.log('## voyNo >>', voyNo)

        const txt = `${vslNm}<br/>${voyNo}`
        return txt
      }
    },
    editable: false
  },
  { name: 'voyNo', fieldName: 'voyNo', header: { text: 'voyNo' }, editable: false },
  {
    name: 'blNo',
    fieldName: 'blNo',
    header: { text: 'B/L No.', styleName: 'header-title' },
    footer: { text: 'B/L Count', styleName: 'footer-title' },
    editable: false
  },
  { name: 'bizNo', fieldName: 'bizNo', header: { text: 'bizNo' }, editable: false },
  { name: 'cstNm', fieldName: 'cstNm', width: '200', header: { text: 'Shipper\n/Consignee', styleName: 'multi-line-css' }, editable: false },
  { name: 'cstNml', fieldName: 'cstNml', width: '150', header: { text: 'Shipper\n/Consignee', styleName: 'multi-line-css' }, editable: false },
  {
    name: 'porPlcCd',
    fieldName: 'porPlcCd',
    width: '80',
    header: { text: 'POR' },
    footer: { expression: 'count', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'dlyPlcCd',
    fieldName: 'dlyPlcCd',
    width: '80',
    header: { text: 'DLY' },
    editable: false
  },
  {
    name: 'ofUsdAmt',
    fieldName: 'ofUsdAmt',
    width: '80',
    numberFormat: '#,##0.00',
    styleName: 'right-column',
    header: { text: 'O/F\n(USD)', styleName: 'multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0.00', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'exrt',
    fieldName: 'exrt',
    width: '80',
    numberFormat: '#,##0.0000',
    styleName: 'right-column',
    header: { text: 'EX.\nRATE', styleName: 'multi-line-css' },
    editable: false
  },
  {
    name: 'ofLocAmt',
    fieldName: 'ofLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'O/F\n(KRW)', styleName: 'multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'thcLocAmt',
    fieldName: 'thcLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'THC' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'dcfLocAmt',
    fieldName: 'dcfLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'DCF' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'wfgLocAmt',
    fieldName: 'wfgLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'WFG' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'pfsLocAmt',
    fieldName: 'pfsLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'PFS' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'afsLocAmt',
    fieldName: 'afsLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'AFA' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'othLocAmt',
    fieldName: 'othLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'right-column',
    header: { text: 'OTH' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'totalAmt',
    fieldName: 'totalAmt',
    width: '100',
    numberFormat: '#,##0',
    styleName: 'total-amt',
    header: { text: 'Amount (a)' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  }
]
export const userColumns = [
  {
    name: 'blNo',
    fieldName: 'blNo',
    header: { text: 'B/L No.' },
    footer: { text: 'B/L Count', styleName: 'footer-title' },
    editable: true
  },
  {
    name: 'totalAmt',
    fieldName: 'totalAmt',
    width: '100',
    numberFormat: '#,##0',
    styleName: 'total-amt',
    header: { text: 'Amount (b)', styleName: 'multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: true
  }
]
export const matchColumns = [
  {
    name: 'diffAmt',
    fieldName: 'diffAmt',
    width: '100',
    numberFormat: '#,##0',
    styleName: 'red-total-amt',
    header: { text: 'Amount (a-b)' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'red-total-amt' },
    editable: false
  },
  {
    name: 'remark',
    fieldName: 'remark',
    width: '230',
    header: { text: 'Remark' },
    editable: false
  },
  {
    name: 'blNo',
    fieldName: 'blNo',
    header: { text: 'B/L No.' },
    editable: false
  }
]

let layout = [
  'bascDt',
  'vslNm',
  'blNo',
  'cstNm',
  'porPlcCd',
  'dlyPlcCd',
  'ofUsdAmt',
  'exrt',
  'ofLocAmt',
  'thcLocAmt',
  'dcfLocAmt',
  'wfgLocAmt',
  'pfsLocAmt',
  'afsLocAmt',
  'othLocAmt',
  'totalAmt'

]
let layoutHGroup = [
  {
    name: 'blInfo',
    direction: 'horizontal',
    items: [
      {
        column: 'blNo',
        height: 30
      },
      'cstNm',
      'porPlcCd',
      'dlyPlcCd',
      'ofUsdAmt',
      'exrt',
      'ofLocAmt',
      'thcLocAmt',
      'dcfLocAmt',
      'wfgLocAmt',
      'pfsLocAmt',
      'afsLocAmt',
      'othLocAmt'
    ],
    header: {
      text: '회사명',
      styleName: '',
      height: 50
    }
  },
  'totalAmt'
]
let layoutFooter = [
  {
    name: 'group1',
    header: { visible: false },
    direction: 'horizontal',
    items: [
      { column: 'blNo', footerUserSpans: [{ rowspan: 1, colspan: 2 }] },
      'cstNm'
    ]
  },
  'porPlcCd',
  'dlyPlcCd',
  'ofUsdAmt',
  'exrt',
  'ofLocAmt',
  'thcLocAmt',
  'dcfLocAmt',
  'wfgLocAmt',
  'pfsLocAmt',
  'afsLocAmt',
  'othLocAmt',
  'totalAmt'
]

let userLayout = [
  'blNo',
  'totalAmt'
]

let userLayoutFooter = [
  'blNo',
  'totalAmt'
]
let matchLayout = [
  'diffAmt',
  'remark'
]

export default {
  name: 'CreditTransactionViewPop',
  props: {
    poParamiters: {
      type: Object,
      default: () => {
        return undefined
      }
    }
  },
  data () {
    return {
      compId: this.$uuid.v1(),
      refNo: '',
      detailList: []
    }
  },
  watch: {
    'poParamiters.refNo' (nV, oV) {
      this.refNo = nV
    }
  },
  created () {
  },
  mounted () {
    if (this.$_.has(this.poParamiters, 'refNo')) {
      this.refNo = this.poParamiters.refNo
    }
    provider = new LocalDataProvider(true)
    gridView = new GridView(`${this.compId}-settle-realgrid`)

    provider.setFields(fields)

    // 그리고 헤더 높이 조정
    gridView.header.heights = 60
    gridView.displayOptions.rowHeight = 60
    gridView.defaultColumnWidth = 60
    gridView.displayOptions.fitStyle = 'even'

    gridView.setStateBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    gridView.setCheckBar({
      visible: false,
      exclusive: false
    })
    gridView.displayOptions.selectionStyle = 'singleRow'
    gridView.setPaging(false, 10)

    gridView.setColumns(columns)
    gridView.setColumnLayout(layoutFooter)
    gridView.setDataSource(provider)
    // gridView.setColumnLayout(layoutFooter)

    gridView.setFixedOptions({
      // colCount: 4,
      rightCount: 1
    })

    // 사용자 데이타영역
    userProvider = new LocalDataProvider(true)
    userGridView = new GridView(`${this.compId}-user-realgrid`)
    userProvider.setFields(userFields)

    // 그리고 헤더 높이 조정
    userGridView.header.heights = 60
    userGridView.displayOptions.rowHeight = 60
    userGridView.defaultColumnWidth = 60
    userGridView.displayOptions.fitStyle = 'even'

    userGridView.setStateBar({
      visible: false
    })
    userGridView.setRowIndicator({
      visible: false
    })
    userGridView.setCheckBar({
      visible: false,
      exclusive: false
    })
    userGridView.displayOptions.selectionStyle = 'singleRow'
    userGridView.setPaging(false, 10)

    userGridView.setColumns(userColumns)
    userGridView.setColumnLayout(userLayout)
    userGridView.setDataSource(userProvider)
    userGridView.setColumnLayout(userLayoutFooter)

    userGridView.onPasted = function (grid, index, event) {
      userGridView.cancelEditor()
      grid.commit()
      console.log('# 붙여넣기')
      let cpData = grid.getSelectionData()
      console.log(JSON.stringify(cpData))

      this.userData = []
      this.userData = cpData

      userProvider.setRows(this.userData)
    }

    // 사용자 데이타영역
    matchProvider = new LocalDataProvider(true)
    matchGridView = new GridView(`${this.compId}-match-realgrid`)
    matchProvider.setFields(matchFields)

    // 그리고 헤더 높이 조정
    matchGridView.header.heights = 60
    matchGridView.displayOptions.rowHeight = 60
    matchGridView.displayOptions.fitStyle = 'none'

    matchGridView.setStateBar({
      visible: false
    })
    matchGridView.setRowIndicator({
      visible: false
    })
    matchGridView.setCheckBar({
      visible: false,
      exclusive: false
    })
    matchGridView.displayOptions.selectionStyle = 'singleRow'
    matchGridView.setPaging(false, 10)

    matchGridView.setColumns(matchColumns)
    matchGridView.setColumnLayout(matchLayout)
    matchGridView.setDataSource(matchProvider)

    this.getData()
  },
  methods: {
    async getData () {
      if (this.refNo.trim().length === 0) {
        return
      }
      await CreditApi.getArrangeList(this.refNo)
      .then(response => {
        console.log(response)
        if (response.data) {
          provider.setRows(response.data.creditList)
          this.detailList = response.data.detailList
          this.referData()
        }
      })
    },
    referData () {
      /*
  { fieldName: 'diffAmt', dataType: 'number' },
  { fieldName: 'remark', dataType: 'text' },
  { fieldName: 'blNo', dataType: 'text' }      */
      let userData = []
      let matchData = []

      this.detailList.forEach(v => {
        userData.push({
          blNo: v.blNo,
          totalAmt: v.shprBlAmt
        })
        matchData.push({
          blNo: v.blNo,
          diffAmt: v.gapAmt,
          remark: v.rmk
        })
      })
      userProvider.setRows(userData)
      matchProvider.setRows(matchData)
    }
  }
}
</script>
