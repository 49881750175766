<template>
  <div class="popup_wrap" style="width: 1200px;">
    <button class="layer_close" @click="$emit('close', { msgYn: 'Y' })">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">거래처별 청구명세</h1>
      <div class="content_box">
        <!--content_box  -->
        <p>
          Invoice No : {{ info.invNo }}  / 기간 : {{ info.strObrdDt }}~{{ info.endObrdDt }} / 담당자 : {{ managerInfo.usrNm }} / 연락처 : (2)211-{{ managerInfo.coTelNo }}<br />
          사업자등록번호 : {{ companyInfo.bzrgNo }} {{ companyInfo.cstEnm }}
        </p>
        <div :id="`${compId}-account-bill-realgrid`" style="width: 100%; height: 450px"></div>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" @click.prevent="openReport">PDF 다운로드</a>
        <a class="button blue lg" @click.prevent="excelDownload">엑셀 다운로드</a>
        <a class="button blue lg" @click.prevent="fnAction('TAX')">세금계산서 발행 요청</a>
      </div>
    </div>
    <div :id="`${compId}_credit_billing_popup`">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          :ok-hidden="isHidden"
          :is-show-pop="isShowPop"
          :parent-info="parentInfo"
          @close="commonClosePopup"
        />
      </win-layer-pop>
    </div>
    <common-unsolved ref="commonUnsolved" @callback="searchData" />
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import { rootComputed } from '@/store/helpers'
import CreditApi from '@/api/rest/settle/credit'
import moment from 'moment'
import CommonUnsolved from '@/pages/trans/CommonUnsolved'
import CreditBillingPop from '@/pages/pdf/popup/CreditBillingPop'

let billGridView = GridView
let billProvider = LocalDataProvider

export const billFields = [
  { fieldName: 'bascDt', dataType: 'text' },
  { fieldName: 'obrdDt', dataType: 'text' },
  { fieldName: 'vslNm', dataType: 'text' },
  { fieldName: 'voyNo', dataType: 'text' },
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'bizNo', dataType: 'text' },
  { fieldName: 'cstNm', dataType: 'text' },
  { fieldName: 'cstNml', dataType: 'text' },
  { fieldName: 'porPlcCd', dataType: 'text' },
  { fieldName: 'porPlcNm', dataType: 'text' },
  { fieldName: 'dlyPlcCd', dataType: 'text' },
  { fieldName: 'dlyPlcNm', dataType: 'text' },
  { fieldName: 'ofUsdAmt', dataType: 'number' },
  { fieldName: 'exrt', dataType: 'number' },
  { fieldName: 'ofLocAmt', dataType: 'number' },
  { fieldName: 'thcLocAmt', dataType: 'number' },
  { fieldName: 'dcfLocAmt', dataType: 'number' },
  { fieldName: 'wfgLocAmt', dataType: 'number' },
  { fieldName: 'pfsLocAmt', dataType: 'number' },
  { fieldName: 'afsLocAmt', dataType: 'number' },
  { fieldName: 'othLocAmt', dataType: 'number' },
  { fieldName: 'totalAmt', dataType: 'number' }
]
export const billColumns = [
  {
    name: 'bascDt',
    fieldName: 'bascDt',
    width: '60',
    header: { text: 'On Board Date' },
    footer: { text: '합계금액', styleName: 'footer-title' },
    editable: false
  },
  {
    name: 'obrdDt',
    fieldName: 'obrdDt',
    width: '70',
    header: { text: 'On Board Date' },
    footer: { text: '합계금액', styleName: 'footer-title' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
          let data = cell.value
          let str = ''
          data = data.substring(0, 4) + '.' + data.substring(4, 6) + '.' + data.substring(6, 8)
          str = '<span>' + data + '</span>'
          return str
      }
    }
  },
  {
    name: 'vslNm',
    fieldName: 'vslNm',
    width: '140',
    header: { text: 'VSL/VOY' },
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const vslNm = grid.getValue(idx, 'vslNm')
        const voyNo = grid.getValue(idx, 'voyNo')

        const txt = `${vslNm}<br/>${voyNo}`
        return txt
      }
    },
    editable: false
  },
  { name: 'voyNo', fieldName: 'voyNo', header: { text: 'voyNo' }, editable: false },
  {
    name: 'blNo',
    fieldName: 'blNo',
    header: { text: 'B/L No.' },
    footer: { text: 'B/L Count', styleName: 'footer-title' },
    editable: false
  },
  { name: 'bizNo', fieldName: 'bizNo', header: { text: 'bizNo' }, editable: false },
  { name: 'cstNm', fieldName: 'cstNm', width: '200', header: { text: 'Shipper\n/Consignee', styleName: 'multi-line-css' }, editable: false },
  { name: 'cstNml', fieldName: 'cstNml', width: '150', header: { text: 'Shipper\n/Consignee', styleName: 'multi-line-css' }, editable: false },
  {
    name: 'porPlcCd',
    fieldName: 'porPlcCd',
    width: '80',
    header: { text: 'POR' },
    footer: { expression: 'count', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'porPlcNm',
    fieldName: 'porPlcNm',
    width: '120',
    header: { text: 'POR' },
    footer: { expression: 'count', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'dlyPlcCd',
    fieldName: 'dlyPlcCd',
    width: '80',
    header: { text: 'DLY' },
    editable: false
  },
  {
    name: 'dlyPlcNm',
    fieldName: 'dlyPlcNm',
    width: '120',
    header: { text: 'DLY' },
    editable: false
  },
  {
    name: 'ofUsdAmt',
    fieldName: 'ofUsdAmt',
    width: '80',
    numberFormat: '#,##0.00',
    styleName: 'num-column',
    header: { text: 'O/F\n(USD)', styleName: 'multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0.00', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'exrt',
    fieldName: 'exrt',
    width: '80',
    numberFormat: '#,##0.0000',
    styleName: 'num-column',
    header: { text: 'EX.\nRATE', styleName: 'multi-line-css' },
    editable: false
  },
  {
    name: 'ofLocAmt',
    fieldName: 'ofLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'O/F\n(KRW)', styleName: 'multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'thcLocAmt',
    fieldName: 'thcLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'THC' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'dcfLocAmt',
    fieldName: 'dcfLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'DCF' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'wfgLocAmt',
    fieldName: 'wfgLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'WFG' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'pfsLocAmt',
    fieldName: 'pfsLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'PFS' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'afsLocAmt',
    fieldName: 'afsLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'AFA' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'othLocAmt',
    fieldName: 'othLocAmt',
    width: '80',
    numberFormat: '#,##0',
    styleName: 'num-column',
    header: { text: 'OTH' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  },
  {
    name: 'totalAmt',
    fieldName: 'totalAmt',
    width: '100',
    numberFormat: '#,##0',
    styleName: 'total-amt',
    header: { text: 'TOTAL\nAmount', styleName: 'multi-line-css' },
    footer: { expression: 'sum', numberFormat: '#,##0', styleName: 'total-amt' },
    editable: false
  }
]
let billLayoutFooter = [
  {
    name: 'group0',
    header: { visible: false },
    direction: 'horizontal',
    items: [
      { column: 'obrdDt', footerUserSpans: [{ rowspan: 1, colspan: 2 }] },
      'vslNm'
    ]
  },
  {
    name: 'group1',
    header: { visible: false },
    direction: 'horizontal',
    items: [
      { column: 'blNo', footerUserSpans: [{ rowspan: 1, colspan: 2 }] },
      'cstNm'
    ]
  },
  'porPlcNm',
  'dlyPlcNm',
  'ofUsdAmt',
  'exrt',
  'ofLocAmt',
  'thcLocAmt',
  'dcfLocAmt',
  'wfgLocAmt',
  'pfsLocAmt',
  'afsLocAmt',
  'othLocAmt',
  'totalAmt'

]
export default {
  name: 'AccountBillHistPop',
  components: {
    CommonUnsolved,
    CreditBillingPop,
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop')
  },
  props: {
    poParamiters: {
      type: Object,
      default: () => {
        return undefined
      }
    }
  },
  data () {
    return {
      compId: this.$uuid.v1(),
      refNo: '',
      eiCatCd: 'O',
      invNo: '',
      customComponent: null,
      isHidden: false,
      isShowPop: false,
      parentInfo: {},
      detailList: [],
      info: {},
      managerInfo: {
        ctrCd: '',
        cstNo: '',
        eiCatCd: '',
        usrNm: '',
        coTelNo: ''
      },
      companyInfo: {
        cstNo: '',
        ctrCd: '',
        cstEnm: '',
        cstNm: '',
        bzrgNo: '',
        bsnNm: '',
        repvNm: ''
      }
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    poParamiters: {
      handler () {
        this.propsWatch()
      },
      deep: true
    }
  },
  created () {},
  mounted () {
    this.propsWatch()
    billProvider = new LocalDataProvider(true)
    billGridView = new GridView(`${this.compId}-account-bill-realgrid`)

    billProvider.setFields(billFields)

    // 그리고 헤더 높이 조정
    billGridView.header.heights = 60
    billGridView.displayOptions.rowHeight = 60
    billGridView.defaultColumnWidth = 60
    billGridView.displayOptions.fitStyle = 'even'

    billGridView.setStateBar({
      visible: false
    })
    billGridView.setRowIndicator({
      visible: false
    })
    billGridView.setCheckBar({
      visible: false,
      exclusive: false
    })
    billGridView.displayOptions.selectionStyle = 'singleRow'
    billGridView.setPaging(false, 10)

    billGridView.setColumns(billColumns)
    billGridView.setColumnLayout(billLayoutFooter)
    billGridView.setDataSource(billProvider)

    billGridView.setFixedOptions({
      // colCount: 4,
      rightCount: 1
    })

    this.componentInit()
  },
  methods: {
    propsWatch () {
      if (this.$_.has(this.poParamiters, 'refNo')) {
        this.refNo = this.poParamiters.refNo
      }
      if (this.$_.has(this.poParamiters, 'asEiCatCd')) {
        this.eiCatCd = this.poParamiters.asEiCatCd
      }
    },
    // 초기화
    async componentInit () {
      await this.getData()
      await this.getManagerInfo()
      await this.getCompanyInfo()
    },
    excelDownload () {
      // console.log(this.auth)
      const compName = this.auth.userName
      const curDt = moment().format('YYYYMMDD')
      // const $vm = this
      billGridView.exportGrid({
        type: 'excel',
        target: 'local',
        documentTitle: { //제목
          message: '거래처별 청구명세',
          visible: true,
          spaceTop: 1,
          spaceBottom: 1,
          height: 60,
          styleName: 'excel-export-title-style'
        },
        fileName: `${compName}_거래처별청구명세_${curDt}.xlsx`,
        showProgress: false,
        progressMessage: '엑셀 Export중입니다.',
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          //내보내기 완료 후 실행되는 함수
          // $vm.openAlert('done excel export')
        }
      })
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },

    async getData () {
      if (this.refNo.trim().length === 0) {
        return
      }
      await CreditApi.getArrangeList(this.refNo)
        .then(response => {
          console.log(response)
          if (response.data) {
            billProvider.setRows(response.data.creditList)
            this.info = response.data.info
            this.info.strObrdDt = moment(this.info.strObrdDt, 'YYYYMMDD').format('YYYY.MM.DD')
            this.info.endObrdDt = moment(this.info.endObrdDt, 'YYYYMMDD').format('YYYY.MM.DD')
          }
        })
    },

    async getManagerInfo () {
      const eiCatCd = this.eiCatCd
      await CreditApi.getManagerInfo(eiCatCd)
        .then(response => {
          console.log(response)
          if (response.data && response.data.managerInfo) {
            this.managerInfo = response.data.managerInfo
            console.log('data > response > managerInfo', response.data.managerInfo)
          }
        })
    },

    async getCompanyInfo () {
      await CreditApi.getCompanyInfo()
        .then(response => {
          console.log(response)
          if (response.data && response.data.companyInfo) {
            this.companyInfo = response.data.companyInfo
            console.log('data > response > companyInfo', response.data.companyInfo)
          }
        })
    },
    openReport () {
      console.log('popup open ------>')
      this.isShowPop = true
      this.parentInfo = {
        refNo: this.refNo,
        eiCatCd: this.eiCatCd
      }
      this.customComponent = 'CreditBillingPop'
      this.$ekmtcCommon.layerOpen(`#${this.compId}_credit_billing_popup > .popup_dim`)
    },
    commonClosePopup () {
      console.log('popup close ------>')
      this.isShowPop = false
      this.customComponent = null
      this.$ekmtcCommon.layerClose(`#${this.compId}_credit_billing_popup > .popup_dim`)
    },
    searchData () {
      console.log('ok')
    },
    async fnAction (type) {
      const blData = billProvider.getJsonRows(0, -1)

      let arrBlNo = []

      blData.forEach(v => {
        arrBlNo.push(v.blNo)
      })
      if (arrBlNo.length === 0) {
        return
      }
      console.log('BL List >> ', arrBlNo)

      if (type === 'SETTLE') { // 운임수납
        this.$refs.commonUnsolved.openFreightPop(arrBlNo, 'FREIGHT', 'O') // 수출: O, 수입: I
      } else if (type === 'TAX') { // 계산서 발행
        if (this.isEmpty(this.info.invNo)) {
          this.openAlert(this.$t('msg.SETT010T010.201')) // 인보이스 생성이 필요합니다.
          return
        }
        this.$refs.commonUnsolved.openFreightPop(arrBlNo, 'TAX_INVOICE', this.eiCatCd, 'CREDIT', this.info.invNo) // 수출: O, 수입: I
      } else if (type === 'FRT') { // 운임 수정 요청
        this.$refs.commonUnsolved.freightPopCall(arrBlNo[0])
      } else if (type === 'BL_ISSUE') {
        this.$refs.commonUnsolved.openBlPaymentPop(arrBlNo, 'O')
      } else if (type === 'DO_ISSUE') {
        this.$refs.commonUnsolved.openDoPaymentPop(arrBlNo, 'I')
      } else {
        alert(type)
      }
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    }

  }
}
</script>
