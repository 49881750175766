var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      { attrs: { id: "frm2" } },
      [
        _c(
          "win-layer-pop",
          { staticClass: "my_surcharge_pop" },
          [
            _vm.customComponent
              ? _c(_vm.customComponent, {
                  tag: "component",
                  attrs: { "parent-info": _vm.surChargeParams },
                  on: { close: _vm.closePopup },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "content_box beforetab" }, [
          _c("table", { staticClass: "tbl_search" }, [
            _c("colgroup", [
              _c("col", {
                attrs: { width: [_vm.serviceCtrCd === "KR" ? "60px" : "80px"] },
              }),
              _c("col", { attrs: { width: "150px" } }),
              _c("col", { attrs: { width: "324px" } }),
              _c("col", { attrs: { width: "50px" } }),
              _c("col", { attrs: { width: "150px" } }),
              _c("col", { attrs: { width: "150px" } }),
              _c("col", { attrs: { width: "80px" } }),
              _c("col", { attrs: { width: "120px" } }),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.001")))]),
                _c(
                  "td",
                  { attrs: { colspan: "2" } },
                  [
                    _c("e-auto-complete-place", {
                      attrs: {
                        id: "polPol",
                        "ctr-cd": _vm.params.porCtrCd,
                        "plc-cd": _vm.params.porPlcCd,
                        "is-char": true,
                        "is-char-alert": true,
                      },
                      on: { change: _vm.changePol },
                    }),
                  ],
                  1
                ),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.002")))]),
                _c(
                  "td",
                  { attrs: { colspan: "4" } },
                  [
                    _c("e-auto-complete-place", {
                      attrs: {
                        id: "podPod",
                        "ctr-cd": _vm.params.dlyCtrCd,
                        "plc-cd": _vm.params.dlyPlcCd,
                        "is-char": true,
                        "is-char-alert": true,
                      },
                      on: { change: _vm.changePod },
                    }),
                  ],
                  1
                ),
                _c("td", { staticClass: "text_right" }),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.043")))]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.kind,
                          expression: "params.kind",
                        },
                      ],
                      staticClass: "wid150",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.params,
                              "kind",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.changeSearchGb,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "E" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.FARE020T010.004"))),
                      ]),
                      _c("option", { attrs: { value: "R" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.FARE020T010.005"))),
                      ]),
                      _c("option", { attrs: { value: "S" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.FARE020T010.006"))),
                      ]),
                      _c("option", { attrs: { value: "N" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.FARE020T010.031"))),
                      ]),
                    ]
                  ),
                ]),
                _vm.params.kind !== "N"
                  ? _c(
                      "td",
                      { attrs: { colspan: "4" } },
                      [
                        _c("e-date-range-picker", {
                          attrs: {
                            id: "date_picker",
                            sdate: _vm.params.fromDt,
                            edate: _vm.params.toDt,
                            "is-debug": true,
                          },
                          on: { change: _vm.changeDateRange },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.params.kind === "N"
                  ? _c(
                      "td",
                      {
                        staticStyle: {
                          "padding-left": "20px",
                          "padding-right": "500px",
                        },
                        attrs: { colspan: "4" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.scNo,
                              expression: "params.scNo",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.params.scNo },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.params, "scNo", $event.target.value)
                            },
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.035")))]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.hotDealYn,
                          expression: "params.hotDealYn",
                        },
                      ],
                      staticClass: "wid100",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.params,
                            "hotDealYn",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "A" } }, [
                        _vm._v(_vm._s(_vm.$t("ALL"))),
                      ]),
                      _c("option", { attrs: { value: "Y" } }, [
                        _vm._v(_vm._s(_vm.$t("Y"))),
                      ]),
                      _c("option", { attrs: { value: "N" } }, [
                        _vm._v(_vm._s(_vm.$t("N"))),
                      ]),
                    ]
                  ),
                ]),
                _c("td", { staticClass: "text_right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button blue sh",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.searchMyOceanFreight(true)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.013")))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("div", { staticClass: "flex_box" }, [
            _c("span", { staticClass: "tot_num" }, [
              _vm._v(_vm._s(_vm.$t("msg.FARE020T010.014")) + " : "),
              _c("span", { staticClass: "num" }, [
                _vm._v(_vm._s(_vm.myOceanFreightList.length)),
              ]),
            ]),
            _c("span", { staticClass: "ml_auto cal_btn" }, [
              _c(
                "a",
                {
                  staticClass: "button sm",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.exlDownload()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.MYIN020T010.016")))]
              ),
            ]),
          ]),
          _c("div", {
            style: `width: 100%; height: ${_vm.gridHeight}px;`,
            attrs: { id: "realgrid" },
          }),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }